// assets
import { ProfileOutlined, QuestionOutlined, UserOutlined, BellOutlined, UnorderedListOutlined } from '@ant-design/icons';

// icons
const icons = {
    ProfileOutlined,
    QuestionOutlined,
    UserOutlined,
    BellOutlined
};

// ==============================|| MENU ITEMS - PROFILE PAGES ||============================== //
// Get the role from local storage or set a default value of "guest"
if (localStorage.getItem('profile') == 'undefined') {
    window.location = '/login';
}
const role = (localStorage.getItem('profile') && JSON.parse(localStorage.getItem('profile'))['role']) || 'guest';

const smartprofile =
    role === '1'
        ? {
              id: 'smartprofile',
              // title: 'Pages',
              type: 'group',
              children: [
                  {
                      id: 'smart_profile_1',
                      title: 'Smart Profile',
                      type: 'item',
                      url: '/smart-profile',
                      icon: icons.ProfileOutlined,
                      breadcrumbs: false
                  },
                  {
                      id: 'smart_profile_3',
                      title: 'Admin Board',
                      type: 'item',
                      url: '/all-users',
                      icon: icons.ProfileOutlined,
                      breadcrumbs: false
                      // external: true,
                      // target: true
                  },
                  {
                      id: 'smart_profile_4',
                      title: 'Team Building History',
                      type: 'item',
                      url: '/team-building-history',
                      icon: icons.ProfileOutlined,
                      breadcrumbs: false
                      // external: true,
                      // target: true
                  },
                  {
                      id: 'smart_profile_5',
                      title: 'Email Template',
                      type: 'item',
                      url: '/email_template',
                      icon: icons.ProfileOutlined,
                      breadcrumbs: false
                      // external: true,
                      // target: true
                  },
                  {
                      id: 'smart_profile_8',
                      title: 'Company List',
                      type: 'item',
                      url: '/companies',
                      icon: icons.ProfileOutlined,
                      breadcrumbs: false
                  },
                  {
                      id: 'smart_profile_9',
                      title: 'School List',
                      type: 'item',
                      url: '/schools',
                      icon: icons.ProfileOutlined,
                      breadcrumbs: false
                  },
                  //   {
                  //       id: 'smart_profile_6',
                  //       title: 'Email Sending Log',
                  //       type: 'item',
                  //       url: '/email-logs/',
                  //       icon: icons.ProfileOutlined,
                  //       breadcrumbs: false
                  //       // external: true,
                  //       // target: true
                  //   },
                  //   {
                  //       id: 'smart_profile_7',
                  //       title: 'AWS Email Sending Log',
                  //       type: 'item',
                  //       url: '/ses/event-webhook/',
                  //       icon: icons.ProfileOutlined,
                  //       breadcrumbs: false
                  //       // external: true,
                  //       // target: true
                  //   },
                  {
                      id: 'smart_profile_2',
                      title: 'Manual Team Building',
                      type: 'item',
                      url: '/group-matching',
                      icon: icons.ProfileOutlined,
                      breadcrumbs: false
                  }
                  //   {
                  //       id: 'smart_profile_5',
                  //       title: 'Feedback Form',
                  //       type: 'item',
                  //       url: '/feedback',
                  //       icon: icons.ProfileOutlined
                  //       // external: true,
                  //       // target: true
                  //   }
              ]
          }
        : role === '2'
        ? {
              id: 'smartprofile',
              type: 'group',
              children: [
                  {
                      id: 'smart_profile_5',
                      title: 'Notifications',
                      type: 'item',
                      url: '/notification',
                      icon: icons.BellOutlined,
                      breadcrumbs: false
                  },
                  {
                      id: 'smart_profile_1',
                      title: 'Smart Profile',
                      type: 'item',
                      url: '/smart-profile',
                      icon: icons.ProfileOutlined,
                      breadcrumbs: false
                  },
                  {
                      id: 'smart_profile_3',
                      title: 'Admin Board',
                      type: 'item',
                      url: '/all-users',
                      icon: icons.ProfileOutlined,
                      breadcrumbs: false
                  },
                  {
                      id: 'smart_profile_4',
                      title: 'Team Building History',
                      type: 'item',
                      url: '/team-building-history',
                      icon: icons.ProfileOutlined,
                      breadcrumbs: false
                  }
              ]
          }
        : role === '3' || role === '4'
        ? {
              id: 'smartprofile',
              // title: 'Pages',
              type: 'group',
              children: [
                  {
                      id: 'smart_profile_5',
                      title: 'Notifications',
                      type: 'item',
                      url: '/notification',
                      icon: icons.BellOutlined,
                      breadcrumbs: false
                      // external: true,
                      // target: true
                  },
                  {
                      id: 'smart_profile_1',
                      title: 'Smart Profile',
                      type: 'item',
                      url: '/smart-profile',
                      icon: icons.ProfileOutlined,
                      breadcrumbs: false
                  },
                  {
                      id: 'smart_profile_6',
                      title: 'Event History',
                      type: 'item',
                      url: '/event-history',
                      icon: icons.ProfileOutlined,
                      breadcrumbs: false
                  }
              ]
          }
        : {
              // default routes or routes for unknown roles
          };
// const smartprofile = {
//     id: 'smartprofile',
//     // title: 'Pages',
//     type: 'group',
//     children: [
//         {
//             id: 'smart_profile_1',
//             title: 'Smart Profile',
//             type: 'item',
//             url: '/smart-profile',
//             icon: icons.ProfileOutlined
//         },
//         {
//             id: 'smart_profile_2',
//             title: 'PUMAS Team Building',
//             type: 'item',
//             url: '/group-matching',
//             icon: icons.ProfileOutlined
//         },
//         {
//             id: 'smart_profile_3',
//             title: 'Admin Board',
//             type: 'item',
//             url: '/all-users',
//             icon: icons.ProfileOutlined
//             // external: true,
//             // target: true
//         },
//         {
//             id: 'smart_profile_4',
//             title: 'Team Building History',
//             type: 'item',
//             url: '/team-building-history',
//             icon: icons.ProfileOutlined
//             // external: true,
//             // target: true
//         },
//         {
//             id: 'smart_profile_5',
//             title: 'Feedback Form',
//             type: 'item',
//             url: '/feedback',
//             icon: icons.ProfileOutlined
//             // external: true,
//             // target: true
//         }
//     ]
// };

export default smartprofile;
