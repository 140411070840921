/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UserService from 'services/user.service';
import { Email } from 'email/meetingDetails';
import { Email_mentor } from 'email/meetingDetails_mentor';
import { StakeholderNoti } from 'email/stakeholderNotification';
import { render } from '@react-email/render';
import Axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
    Box,
    Button,
    ButtonGroup,
    Chip,
    Collapse,
    Input,
    Select,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    InputLabel,
    Grid,
    List,
    Link,
    Divider,
    ListItem,
    ListItemText,
    MenuItem,
    Modal,
    Stack,
    TextField,
    Typography,
    Tooltip,
    Container,
    Checkbox,
    FormGroup,
    FormControlLabel
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import AnimateButton from 'components/@extended/AnimateButton';

import IsoIcon from '@mui/icons-material/Iso';
import SaveIcon from '@mui/icons-material/Save';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SendIcon from '@mui/icons-material/Send';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { styled } from '@mui/system';

if (window.location.href.includes('pumas.activepeers.ai/')) {
    var backend_base_url = 'https://pumas.activepeers.ai/api';
} else {
    var backend_base_url = window.location.href.split(':')[0] + ':' + window.location.href.split(':')[1] + ':8001/api';
}
var base_url = window.location.href.split('/').slice(0, 3).join('/');
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3
};
const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
}));
const MyTable = ({ data }) => {
    return (
        // <Table sx={{ fontSize: 8 }}>
        <Table sx={{ fontSize: 8, maxHeight: 300, overflow: 'auto' }} stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Gender</TableCell>
                    <TableCell>Current Position</TableCell>
                    <TableCell>Department or Class</TableCell>
                    <TableCell>Company or School</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell >Skills</TableCell>
                    <TableCell>Date Joined</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((row) => (
                    <TableRow key={row.id}>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.gender}</TableCell>
                        <TableCell>{row['current position']}</TableCell>
                        <TableCell>{row['department or class']}</TableCell>
                        <TableCell>{row['company or school']}</TableCell>
                        <TableCell>{row.role}</TableCell>
                        <TableCell sx={{ width: 100 }}>{row.skills.join(', ')}</TableCell>
                        <TableCell>{new Date(row.dateJoined).toLocaleDateString()}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

function TeamBuildingPage() {
    const { user: currentUser } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    if (!currentUser) {
        navigate('/login');
    }
    const getListStyle = (isDraggingOver) => ({
        background: isDraggingOver ? '#16a085' : 'darkgrey',
        margin: 'auto'
    });
    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: 'none',
        background: isDragging ? 'darkgrey' : 'white',
        color: isDragging ? 'white' : 'black',
        padding: isDragging ? '0%' : '2%',
        margin: '0%',
        ...draggableStyle
    });
    //expand/unexpand table data
    const [expanded, setExpanded] = useState(false);

    // set constraints and their orders
    const data = [
        { id: 'activity-history', name: 'Activity History' },
        { id: 'gender', name: 'Gender' },
        { id: 'current-position', name: 'Current Position' },
        { id: 'school-company', name: 'School/Company' },
        { id: 'class-department-group', name: 'Class/Department/Group' },
        { id: 'skills', name: 'Skills' }
    ];
    const [users, setUsers] = useState(data);
    const [error, setError] = useState(null);
    const [loading1, setLoading1] = React.useState(false);
    const [orders, setOrders] = useState(Object.values(data).map((a) => a.id));

    const onDragEnd = (e) => {
        if (!e.destination) return; // No valid drop destination

        const updatedRows = [...users];
        const [movedRow] = updatedRows.splice(e.source.index, 1);
        updatedRows.splice(e.destination.index, 0, movedRow);
        setUsers(updatedRows);
        let items = [];
        updatedRows.forEach((data, index) =>
            items.push(
                data.id
                /////
            )
        );
        setOrders(items);

    };
    const defaultData = {
        'activity-history': '3',
        gender: '0',
        'current-position': '0',
        'school-company': '0',
        'class-department-group': '0',
        skills: '0'
    };
    const [state, setState] = useState(defaultData);
    const onChange = (e) => {
        const { name, value, checked, type } = e.target;
        const newVal = type === 'checkbox' ? checked : value;
        setState({
            ...state,
            [name]: newVal
        });
    };
    const [text, setTextState] = useState({ projectTitle: '', projectDes: '' });
    const onTextChange = (e) => {
        const { name, value, checked, type } = e.target;
        const newVal = type === 'checkbox' ? checked : value;
        setTextState({
            ...text,
            [name]: newVal
        });
    };
    // State to store parsed data
    const parsedData = JSON.parse(localStorage.getItem('selectedData'));
    const tableRows = [
        'ID',
        'Email',
        'Name',
        'Gender',
        'Current Position',
        'Department or Class',
        'Company or School',
        'Role',
        'Skills',
        'Date Joined'
    ];

    // handle Team Building
    //State to store the response
    const [responseData, setResponse] = useState([]);
    //State to store the response-message
    const [tablesArray, setTables] = useState([]);

    //State to store table quotas and constrainst
    const [tableQuotas, setTableQuotas] = useState([]);
    const [constraintSet, setConstraints] = useState([]);
    //handleSubmit
    const handleSubmit = (event) => {
        var constraints_dict_value = [
            state[orders[0]],
            state[orders[1]],
            state[orders[2]],
            state[orders[3]],
            state[orders[4]],
            state[orders[5]]
        ];
        var table = state['table'];
        var mentor = state['mentor'] ? state['mentor'] : 1;
        var constraint_set = {
            mentor: mentor,
            orders: orders,
            constraints_dict_value: constraints_dict_value
        };
        if (parsedData.length > 0) {
            if (state['table']) {
                Axios.post(
                    `${backend_base_url}/group_matching/`,
                    {
                        data: {
                            csvData: parsedData,
                            // value 0- Ignore, 1- Common Only, 2 - Diversity Only, 3- Don't overlap at all
                            constraints_dict_name: orders,
                            constraints_dict_value: constraints_dict_value,
                            table: table,
                            mentor: mentor,
                            activity_hist: tablesArray
                        }
                    },
                    { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }
                ).then((response) => {
                    setResponse((prevArray) => [...prevArray, response.data]);
                    if (response.data['response'] == 'Success') {
                        setTables((prevArray) => [...prevArray, JSON.parse(response.data['message'])]);
                        setTableQuotas((prevNumber) => [...prevNumber, table]);
                        setConstraints((prevArray) => [...prevArray, constraint_set]);
                    } else if (response.data['response'] == 'Error') {
                        setError(response.data['message']);
                    }
                });
            } else {
                alert('Please enter the desired number of tables!');
            }
        } else {
            alert('Please upload your data!');
        }
    };
    //handleDuplicatePreviousRound
    const handleDuplicatePreviousRound = (event) => {
        var constraints_dict_value = [
            state[orders[0]],
            state[orders[1]],
            state[orders[2]],
            state[orders[3]],
            state[orders[4]],
            state[orders[5]]
        ];
        var table = state['table'];
        var mentor = state['mentor'] ? state['mentor'] : 1;
        var constraint_set = {
            mentor: mentor,
            orders: orders,
            constraints_dict_value: constraints_dict_value
        };
        // Deep copy previous round data
        const lastResponse = JSON.parse(JSON.stringify(responseData[responseData.length - 1]));
        const lastTable = JSON.parse(JSON.stringify(tablesArray[tablesArray.length - 1]));
        setResponse((prevArray) => [...prevArray, lastResponse]);
        setTables((prevArray) => [...prevArray, lastTable]);
        setTableQuotas((prevNumber) => [...prevNumber, table]);
        setConstraints((prevArray) => [...prevArray, constraint_set]);
    };
    //handle Drop and Drag participants between tables in the last round
    const handleDragEnd = (result) => {
        if (!result.destination) return;
        const { source, destination } = result;
        const sourceListIndex = parseInt(source.droppableId);
        const destinationListIndex = parseInt(destination.droppableId);
        const lastListIndex = tablesArray.length - 1;
        //const lastList = tablesArray[lastListIndex];
        const lastList = JSON.parse(JSON.stringify(tablesArray[lastListIndex])); // Deep copy
        const updatedLastList = [...lastList];
        const [removed] = updatedLastList[sourceListIndex].splice(source.index, 1);
        updatedLastList[destinationListIndex].splice(destination.index, 0, removed);

        const updatedTablesArray = [...tablesArray];
        updatedTablesArray[lastListIndex] = updatedLastList;

        setTables(updatedTablesArray);
    };

    //handle buttongroup
    const handleDiscard = (event) => {
        event.preventDefault();
        navigate('/all-users');
    };
    const handleReset = (event) => {
        setResponse([]);
        setTables([]);
    };

    const handleSaveResult = (event) => {
        const selectedParticipant_Ids = parsedData.map((item) => (item != null ? item.id : null));
        const participantId_list = Array.from({ length: tablesArray.length }, () => [...selectedParticipant_Ids])
        // Create a function to map indexes to IDs
        const mapIndexesToIDs = (indexes) => indexes.map((index) => selectedParticipant_Ids[index]);
        // Convert groupingResults to an array of IDs
        const arrayOfIDs = tablesArray.map((group) => group.map(mapIndexesToIDs));
        if (text['projectTitle']) {
            setLoading1(true);
            Axios.post(`${backend_base_url}/project/create`, {
                project: {
                    title: text['projectTitle'],
                    description: text['projectDes'],
                    event_id: 0, // 0 - undefined
                    participants: parsedData,
                    table_quotas: tableQuotas,
                    constraints: constraintSet,
                    grouping_results: tablesArray,
                    participants_userId: participantId_list,
                    grouping_results_userId: arrayOfIDs,
                    created_by: JSON.parse(localStorage.getItem('user'))['id'],
                    last_updated_by: JSON.parse(localStorage.getItem('user'))['id']
                },
                event: '0 - undefined'
            }).then((response) => {
                if (response['data']['response'] == 'error') {
                    alert('An error occurred (' + JSON.stringify(response['data']['message']) + ') and we could not save the results.');
                    setLoading1(false);
                } else {
                    alert('The data have been saved in database successfully!');
                }
                navigate('/team-building-history');
            });
        } else {
            alert('Project title is empty. Please check!');
        }
    };
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const [createdProjectId, setCreatedProjectId] = useState(0);
    const handleSaveAndSendEmail = (event) => {
        const selectedParticipant_Ids = parsedData.map((item) => (item != null ? item.id : null));
        const participantId_list = Array.from({ length: tablesArray.length }, () => [...selectedParticipant_Ids])
        // Create a function to map indexes to IDs
        const mapIndexesToIDs = (indexes) => indexes.map((index) => selectedParticipant_Ids[index]);
        // Convert groupingResults to an array of IDs
        const arrayOfIDs = tablesArray.map((group) => group.map(mapIndexesToIDs));
        if (text['projectTitle']) {
            setLoading1(true);
            Axios.post(`${backend_base_url}/project/create`, {
                project: {
                    title: text['projectTitle'],
                    description: text['projectDes'],
                    event_id: 0, // 0-undefined
                    participants: parsedData,
                    table_quotas: tableQuotas,
                    constraints: constraintSet,
                    grouping_results: tablesArray,
                    participants_userId: participantId_list,
                    grouping_results_userId: arrayOfIDs,
                    created_by: JSON.parse(localStorage.getItem('user'))['id'],
                    last_updated_by: JSON.parse(localStorage.getItem('user'))['id']
                },
                event: '0 - undefined'
            }).then((response) => {
                if (response['data']['response'] == 'error') {
                    alert('An error occurred (' + JSON.stringify(response['data']['message']) + ') and we could not save the results.');
                    setLoading1(false);
                } else {
                    setCreatedProjectId(response['data']['project_id']);
                    alert('The data have been saved in database successfully!');
                    setOpen(true);
                }
            });
        } else {
            alert('Project title is empty. Please check!');
        }
    };
    //handle activities on Modal
    const [checkedRound, setCheckedRound] = useState([Array(tablesArray.length).fill(false)]);

    const handleChange = (event, index) => {
        const isChecked = event.target.checked;
        const updatedCheckedRound = [...checkedRound];
        updatedCheckedRound[index] = isChecked;
        setCheckedRound(updatedCheckedRound);
    };
    const checkedLabels = checkedRound.reduce((acc, value, index) => {
        if (value) {
            return [...acc, index + 1];
        }
        return acc;
    }, []);
    localStorage.setItem('selectedRound', JSON.stringify(checkedLabels));
    const handleReselect = () => {
        navigate('/all-users');
    };
    return (
        <Container>
            <h4>PUMAS Team Building Form</h4>
            <br />
            <br />
            <InputLabel mt={10}>1. Review your selected data</InputLabel>
            <br />
            <TableContainer sx={{ maxHeight: 440, border: '2px dashed grey', borderColor: 'primary.main' }}>
                <MyTable data={parsedData ? parsedData : []} />
            </TableContainer>
            <Typography variant="body2" sx={{ mt: 2 }}>
                Number of Rows: {parsedData ? parsedData.length : 0}
            </Typography>
            <br />
            {/* <Button>
                <Link
                    onClick={() => handleReselect()}
                    variant="body1"
                    sx={{ textDecoration: 'underline' }}
                    color="primary"
                >
                    <IsoIcon />
                    Back to Admin Board and Re-select participants
                </Link>
            </Button> */}
            <br />
            <br />
            <InputLabel mt={10}>2. Add details</InputLabel>
            <br />
            <Table className="table" sx={{ margin: 'auto' }}>
                <TableBody className="text-capitalize">

                    <TableRow style={{ color: 'white' }}>
                        <TableCell>Project Title (*)</TableCell>
                        <TableCell>
                            <Input name="projectTitle" onChange={onTextChange} />
                        </TableCell>
                    </TableRow>
                    <TableRow style={{ color: 'white' }}>
                        <TableCell>Project Description</TableCell>
                        <TableCell>
                            <TextField name="projectDes" onChange={onTextChange} />
                        </TableCell>
                    </TableRow>
                    <TableRow style={{ color: 'white' }}>
                        <TableCell>Mentor</TableCell>
                        <TableCell>
                            <Select name="mentor" onChange={onChange} defaultValue={1}>
                                <MenuItem value="1">At least one mentor per group</MenuItem>
                                <MenuItem value="0">Doesn't matter</MenuItem>
                            </Select>
                        </TableCell>
                    </TableRow>
                    <TableRow></TableRow>
                    <TableRow style={{ color: 'white' }}>
                        <TableCell>Desired number of tables (*):</TableCell>
                        <TableCell>
                            <Input
                                name="table"
                                type="number"
                                onChange={onChange}
                                onWheel={(e) => e.target.blur()}
                                inputProps={{
                                    min: 1 // Set your minimum value here
                                }}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <InputLabel mt={10}>3. Prioritize and assign a value to each constraint in accordance with the order</InputLabel>
            <br />
            <TableContainer sx={{ border: '2px dashed grey', borderColor: 'primary.main' }}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Table className="table" sx={{ margin: 'auto' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Constraint Name</TableCell>
                                <TableCell>Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <Droppable droppableId="droppable-1" key="droppable-1">
                            {(provider, snapshot) => (
                                <TableBody
                                    className="text-capitalize"
                                    ref={provider.innerRef}
                                    {...provider.droppableProps}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                    /////////////////////////////
                                >
                                    {users?.map((user, index) => (
                                        <Draggable
                                            draggableId={'' + user.id}
                                            index={index}
                                            key={'' + user.id}
                                            //////////////////////
                                        >
                                            {(provider, snapshot) => (
                                                <TableRow
                                                    {...provider.draggableProps}
                                                    {...provider.dragHandleProps}
                                                    ref={provider.innerRef}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provider.draggableProps.style
                                                        ///////////////////////
                                                    )}
                                                >
                                                    <TableCell>{user.name}</TableCell>
                                                    <TableCell>
                                                        {user.id == 'activity-history' ? (
                                                            <Select name={user.id} onChange={onChange} defaultValue={3}>
                                                                <MenuItem value="0">Ignore</MenuItem>
                                                                <MenuItem value="3">Don't overlap at all</MenuItem>
                                                            </Select>
                                                        ) : (
                                                            <Select name={user.id} onChange={onChange} defaultValue={0}>
                                                                <MenuItem value="0">Ignore</MenuItem>
                                                                <MenuItem value="1">Common Only</MenuItem>
                                                                <MenuItem value="2">Diversity Only</MenuItem>
                                                            </Select>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provider.placeholder}
                                </TableBody>
                            )}
                        </Droppable>
                    </Table>
                </DragDropContext>
            </TableContainer>
            <br />
            <br />
            <InputLabel>4. Build team</InputLabel>
            {error ? <Typography color="error">{error}</Typography> : null}
            <br />
            {tablesArray.length == 0 && (
                <AnimateButton>
                    <Button
                        disableElevation
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        ////
                    >
                        <Diversity2Icon />
                        Build Team - Round 1
                    </Button>
                </AnimateButton>
            )}

            {tablesArray.length > 0 && (
                <div>
                    {tablesArray.map((tables, i) => {
                        if (i == tablesArray.length - 1) {
                            return (
                                <div>
                                    <h5>Round {i + 1}:</h5>
                                    <Stack mt={2}>
                                        Building Status: {responseData[i].response}
                                        {responseData[i].response != 'Success' && <div>{responseData[i].message}</div>}
                                    </Stack>
                                    <Stack direction="row" mt={2} mb={5}>
                                        Stakeholders: {parsedData.map((value, index) => {
                                                                                        if (value.role === 'Stakeholder') {
                                                                                            return (<Chip sx={{ ml:2, mr:2 }} size="small" label={value.id + ' - ' + value.name} />)}})}
                                    </Stack>
                                    <Container sx={{ bgcolor: '' }}>
                                        <Grid container spacing={2}>
                                            <DragDropContext onDragEnd={handleDragEnd}>
                                                {tables?.map((table, tableIndex) => (
                                                    <Droppable droppableId={`${tableIndex}`} key={tableIndex}>
                                                        {(provider, snapshot) => (
                                                            <Grid
                                                                item
                                                                xs={3}
                                                                className="table borderd"
                                                                ref={provider.innerRef}
                                                                {...provider.droppableProps}
                                                            >
                                                                <ListItemText
                                                                    primary={`Group ${tableIndex + 1}`}
                                                                    primaryTypographyProps={{
                                                                        color: 'black',
                                                                        fontWeight: 600,
                                                                        variant: 'body1'
                                                                    }}
                                                                />
                                                                {table.map((user, index) => (
                                                                    <List
                                                                        sx={{
                                                                            width: '100%',
                                                                            maxWidth: 360,
                                                                            height: 50,
                                                                            bgcolor: 'background.paper'
                                                                        }}
                                                                    >
                                                                        <Draggable
                                                                            draggableId={`${tableIndex}-${index}`}
                                                                            index={index}
                                                                            key={`${tableIndex}-${index}`}
                                                                            //////////////////////
                                                                        >
                                                                            {(provider, snapshot) => (
                                                                                <ListItem
                                                                                    {...provider.draggableProps}
                                                                                    {...provider.dragHandleProps}
                                                                                    ref={provider.innerRef}
                                                                                >
                                                                                    {parsedData.map((value, index) => {
                                                                                        if (index == user) {
                                                                                            return (
                                                                                                <ListItemText
                                                                                                    primaryTypographyProps={{
                                                                                                        style:
                                                                                                            value.role === 'Mentor'
                                                                                                                ? {
                                                                                                                      backgroundColor:
                                                                                                                          'yellow'
                                                                                                                  }
                                                                                                                : null
                                                                                                    }}
                                                                                                    primary={value.id + ' - ' + value.name}
                                                                                                />
                                                                                            );
                                                                                        }
                                                                                    })}
                                                                                </ListItem>
                                                                            )}
                                                                        </Draggable>
                                                                        {provider.placeholder}
                                                                    </List>
                                                                ))}
                                                            </Grid>
                                                        )}
                                                    </Droppable>
                                                ))}
                                            </DragDropContext>
                                        </Grid>
                                        <br />
                                        <Typography variant="h5" onClick={() => setExpanded(!expanded)}>
                                            <Tooltip title="Click/Unclick to Show/Hide tables in details.">
                                                <Button>Show/Hide Group Details</Button>
                                            </Tooltip>
                                        </Typography>
                                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                                            <Grid>
                                                {tables.map((table, index) => (
                                                    <TableContainer
                                                        sx={{
                                                            maxHeight: 440,
                                                            border: '2px dashed grey',
                                                            borderColor: 'primary.main'
                                                        }}
                                                    >
                                                        <Typography variant="h5" component="h6">
                                                            Group {index + 1}:
                                                        </Typography>
                                                        <Table key={index} className="table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    {tableRows.map((rows, index) => {
                                                                        return <TableCell key={index}>{rows}</TableCell>;
                                                                    })}
                                                                </TableRow>
                                                            </TableHead>
                                                            {table.map((user) => (
                                                                <TableBody>
                                                                    {parsedData.map((row, index) => {
                                                                        if (index == user) {
                                                                            return (
                                                                                <TableRow
                                                                                    key={index}
                                                                                    style={{ color: 'red' }}
                                                                                    sx={{
                                                                                        color: 'red'
                                                                                    }}
                                                                                >
                                                                                    <TableCell
                                                                                        style={{
                                                                                            backgroundColor:
                                                                                                row.role == 'Mentor' ? 'yellow' : null
                                                                                        }}
                                                                                    >
                                                                                        {row.id}
                                                                                    </TableCell>
                                                                                    <TableCell>{row.email}</TableCell>
                                                                                    <TableCell>{row.name}</TableCell>
                                                                                    <TableCell>{row.gender}</TableCell>
                                                                                    <TableCell>{row['current position']}</TableCell>
                                                                                    <TableCell>{row['department or class']}</TableCell>
                                                                                    <TableCell>{row['company or school']}</TableCell>
                                                                                    <TableCell>{row.role}</TableCell>
                                                                                    <TableCell width="30%">{row.skills.join(', ')}</TableCell>
                                                                                    <TableCell>{row.dateJoined}</TableCell>
                                                                                </TableRow>
                                                                            );
                                                                        }
                                                                    })}
                                                                </TableBody>
                                                            ))}
                                                        </Table>
                                                    </TableContainer>
                                                ))}
                                            </Grid>
                                        </Collapse>
                                    </Container>
                                    <br />
                                    <br />
                                    <ButtonGroup fullWidth size="large" variant="outlined" aria-label="large outlined primary button group">
                                        <Button onClick={handleSaveResult}>
                                            <SaveIcon />
                                            Save Only
                                        </Button>
                                        <Button onClick={handleSaveAndSendEmail}>
                                            <SaveIcon />
                                            Save & 
                                            <SendIcon />
                                            Send Emails
                                        </Button>
                                        <Modal
                                            open={open}
                                            onClose={handleClose}
                                            aria-labelledby="parent-modal-title"
                                            aria-describedby="parent-modal-description"
                                            ///
                                        >
                                            <Box sx={{ ...style, width: 400 }}>
                                                <h4 id="parent-modal-title">You are sending emails ...</h4>
                                                <p id="parent-modal-description">Choose round(s) below where you want to send email.</p>
                                                <FormGroup>
                                                    {tablesArray.map((object, i) => (
                                                        <FormControlLabel
                                                            key={i}
                                                            control={
                                                                <Checkbox
                                                                    checked={checkedRound[i]}
                                                                    onChange={(event) => handleChange(event, i)}
                                                                    name={i + 1}
                                                                />
                                                            }
                                                            label={'Round ' + (i + 1)}
                                                        />
                                                    ))}
                                                </FormGroup>
                                                <ChildModal
                                                    grouping_results={tablesArray}
                                                    participants={parsedData}
                                                    project_id={createdProjectId}
                                                    projectTitle={text['projectTitle']}
                                                />
                                            </Box>
                                        </Modal>
                                        <br />
                                        <Button color="warning" onClick={handleReset}>
                                            <RestartAltIcon />
                                            Reset
                                        </Button>
                                        <Button color="error" onClick={handleDiscard}>
                                            <ClearIcon />
                                            Discard
                                        </Button>
                                    </ButtonGroup>
                                    <br />
                                    <br />
                                    <Divider>
                                        <Chip label="Or" />
                                    </Divider>
                                    <br />
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '5vh' }}>
                                        <ButtonGroup size="large" aria-label="large outlined primary button group">
                                            <AnimateButton>
                                                <Button
                                                    disableElevation
                                                    //fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleSubmit}
                                                    ////
                                                >
                                                    <Diversity2Icon />
                                                    Build Team - Next Round
                                                </Button>
                                            </AnimateButton>
                                            |
                                            <AnimateButton>
                                                <Button
                                                    disableElevation
                                                    //fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleDuplicatePreviousRound}
                                                    ////
                                                >
                                                    <ContentCopyIcon />
                                                    Duplicate Previous Round
                                                </Button>
                                            </AnimateButton>
                                        </ButtonGroup>
                                    </div>
                                </div>
                            );
                        } else {
                            return (
                                <div>
                                    <h5>Round {i + 1}:</h5>
                                    <Container sx={{ bgcolor: '' }}>
                                        <Grid container spacing={2}>
                                            {tables?.map((table, tableIndex) => (
                                                <Grid
                                                    item
                                                    xs={3}
                                                    className="table borderd"
                                                >
                                                    <ListItemText
                                                        primary={`Group ${tableIndex + 1}`}
                                                        primaryTypographyProps={{
                                                            color: 'black',
                                                            fontWeight: 600,
                                                            variant: 'body1'
                                                        }}
                                                    />
                                                    {table.map((user, index) => (
                                                        <List
                                                            sx={{
                                                                width: '100%',
                                                                maxWidth: 360,
                                                                height: 50,
                                                                bgcolor: 'background.paper'
                                                            }}
                                                        >
                                                            <ListItem>
                                                                {parsedData.map((value, index) => {
                                                                    if (index == user) {
                                                                        return (
                                                                            <ListItemText
                                                                                primaryTypographyProps={{
                                                                                    style:
                                                                                        value.role === 'Mentor'
                                                                                            ? {
                                                                                                  backgroundColor: 'yellow'
                                                                                              }
                                                                                            : null
                                                                                }}
                                                                                primary={value.id + ' - ' + value.name}
                                                                            />
                                                                        );
                                                                    }
                                                                })}
                                                            </ListItem>
                                                        </List>
                                                    ))}
                                                </Grid>
                                            ))}
                                        </Grid>
                                        <br />
                                        <Typography variant="h5" onClick={() => setExpanded(!expanded)}>
                                            <Tooltip title="Click/Unclick to Show/Hide tables in details.">
                                                <Button>Show/Hide Group Details</Button>
                                            </Tooltip>
                                        </Typography>
                                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                                            <Grid>
                                                {tables.map((table, index) => (
                                                    <TableContainer
                                                        sx={{
                                                            maxHeight: 440,
                                                            border: '2px dashed grey',
                                                            borderColor: 'primary.main'
                                                        }}
                                                    >
                                                        <Typography variant="h5" component="h6">
                                                            Group {index + 1}:
                                                        </Typography>
                                                        <Table key={index} className="table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    {tableRows.map((rows, index) => {
                                                                        return <TableCell key={index}>{rows}</TableCell>;
                                                                    })}
                                                                </TableRow>
                                                            </TableHead>
                                                            {table.map((user) => (
                                                                <TableBody>
                                                                    {parsedData.map((row, index) => {
                                                                        if (index == user) {
                                                                            return (
                                                                                <TableRow key={index}>
                                                                                    <TableCell>{row.id}</TableCell>
                                                                                    <TableCell>{row.email}</TableCell>
                                                                                    <TableCell>{row.name}</TableCell>
                                                                                    <TableCell>{row.gender}</TableCell>
                                                                                    <TableCell>{row['current position']}</TableCell>
                                                                                    <TableCell>{row['department or class']}</TableCell>
                                                                                    <TableCell>{row['company or school']}</TableCell>
                                                                                    <TableCell>{row.role}</TableCell>
                                                                                    <TableCell>{row.skills.join(', ')}</TableCell>
                                                                                    <TableCell>{row.dateJoined}</TableCell>
                                                                                </TableRow>
                                                                            );
                                                                        }
                                                                    })}
                                                                </TableBody>
                                                            ))}
                                                        </Table>
                                                    </TableContainer>
                                                ))}
                                            </Grid>
                                        </Collapse>
                                    </Container>
                                    <br />
                                    <br />
                                </div>
                            );
                        }
                    })}
                </div>
            )}
            <StyledBackdrop open={loading1}>
                <CircularProgress color="inherit" />
            </StyledBackdrop>
        </Container>
    );
}

export default TeamBuildingPage;
function ChildModal({ grouping_results, participants, project_id, projectTitle }) {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setLoading(false);
    };

    const selectedRound = JSON.parse(localStorage.getItem('selectedRound'));
    const navigate = useNavigate();
    //render html
    const [templateFetched1, setTemplateFetched1] = useState(false);
    const [template1, setTemplate1] = React.useState(null);
    useEffect(() => {
        UserService.get_email_template(11)
            .then((response) => {
                setTemplate1(response.data['template']);
                setTemplateFetched1(true); // Mark the template as fetched
            })
            .catch((error) => {
                const _error = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            });
    }, []);
    const [templateFetched, setTemplateFetched] = useState(false);
    const [template, setTemplate] = React.useState('');
    useEffect(() => {
        UserService.get_email_template(12)
            .then((response) => {
                setTemplate(response.data['template']);
                setTemplateFetched(true); // Mark the template as fetched
            })
            .catch((error) => {
                const _error = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                // console.log('_error', _error);
            });
    }, []);
    //render html
    const [emailHtml, setEmailHtml] = React.useState(render(<Email />));
    useEffect(() => {
        if (templateFetched1) {
            if (template1 != '') {
                setEmailHtml(template1);
            }
        }
    }, [templateFetched1, template1]);
    const [emailHtml_mentor, setEmailHtml_mentor] = React.useState(render(<Email_mentor />));
    useEffect(() => {
        if (templateFetched) {
            if (template != '') {
                setEmailHtml_mentor(template);
            }
        }
    }, [templateFetched, template]);

    const emailHtml_stakeholder = render(<StakeholderNoti />);
    //handleSubmit - Send Emails to participants
    const handleEmailSubmit = (event) => {
        setLoading(true);
        Axios.post(
            `${backend_base_url}/send_email/`,
            {
                data: {
                    subject: 'PUMAS Team Building',
                    txt_: 'PUMAS Team Building',
                    html_: emailHtml,
                    mentor_html_: emailHtml_mentor,
                    stakeholder_html_: emailHtml_stakeholder,
                    round: selectedRound,
                    project: project_id, //change from a dump project id (0) to project_id variable
                    base_url: base_url, //add base url
                    grouping_results: grouping_results,
                    participants: participants,
                    event_id: projectTitle, // change to projectTitle
                    message_type: 1,
                    recipients: participants.map((row) => row.id)
                }
            },
            { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }
        ).then((response) => {
            alert(response.data['msg']);
            setOpen(false);
            setLoading(false);
            navigate('/team-building-history');
        });
    };

    return (
        <React.Fragment>
            <AnimateButton>
                <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={handleOpen}
                    ///
                >
                    Send Email
                </Button>
            </AnimateButton>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                // fullWidth
                maxWidth="sm" // Adjust the maxWidth as needed
            >
                <DialogTitle id="scroll-dialog-title">Email Template Before Sending</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Divider />
                <DialogContent dividers={scroll === 'paper'}>
                    {!loading
                        ? (<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                *** FOR PARTICIPANTS
                                {emailHtml ? <div dangerouslySetInnerHTML={{ __html: emailHtml }} /> : <Email />}
                                *** FOR MENTORS
                                {emailHtml_mentor ? <div dangerouslySetInnerHTML={{ __html: emailHtml_mentor }} /> : <Email_mentor />}
                          </Box>)                                  

                        : (<Box sx={{ width: '100%', typography: 'body1' }}>
                                <CircularProgress />
                                <Typography>Please wait, this may take a few seconds...</Typography>
                          </Box>
                        )
                    }
                </DialogContent>
                <DialogActions>
                    {loading ? (
                        'Sending'
                    ) : (
                        <AnimateButton>
                            <Button
                                disableElevation
                                fullWidth
                                size="medium"
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={handleEmailSubmit}
                                ///
                            >
                                Send Email
                            </Button>
                        </AnimateButton>
                    )}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

