/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import UserService from 'services/user.service';
import ReselectionTable from 'pages/admin/ReSelectUsers';
import Axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
    Box,
    Button,
    ButtonGroup,
    Collapse,
    Input,
    NativeSelect,
    Select,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    InputLabel,
    Grid,
    List,
    Link,
    Divider,
    ListItem,
    ListItemText,
    MenuItem,
    TextField,
    Typography,
    Tooltip,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    Chip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import IsoIcon from '@mui/icons-material/Iso';
import ClearIcon from '@mui/icons-material/Clear';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SaveIcon from '@mui/icons-material/Save';
import AnimateButton from 'components/@extended/AnimateButton';

if (window.location.href.includes('pumas.activepeers.ai/')) {
    var backend_base_url = 'https://pumas.activepeers.ai/api';
} else {
    var backend_base_url = window.location.href.split(':')[0] + ':' + window.location.href.split(':')[1] + ':8001/api';
}
const role = (localStorage.getItem('profile') && JSON.parse(localStorage.getItem('profile'))['role']) || 'guest';

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? '#16a085' : 'darkgrey',
    margin: 'auto'
});
const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    background: isDragging ? 'darkgrey' : 'white',
    color: isDragging ? 'white' : 'black',
    padding: isDragging ? '0%' : '2%',
    margin: '0%',
    // styles we need to apply on draggables
    ...draggableStyle
});
const MyTable = ({ data }) => {
    return (
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                    <TableCell sx={{ fontSize: 12 }}>No</TableCell>
                    <TableCell sx={{ fontSize: 12 }}>ID</TableCell>
                    <TableCell sx={{ fontSize: 12, whiteSpace: 'nowrap' }}>Name</TableCell>
                    <TableCell sx={{ fontSize: 12 }}>Email</TableCell>
                    <TableCell sx={{ fontSize: 12 }}>Gender</TableCell>
                    <TableCell sx={{ fontSize: 12 }}>Current Position</TableCell>
                    <TableCell sx={{ fontSize: 12 }}>Department or Class</TableCell>
                    <TableCell sx={{ fontSize: 12 }}>Company or School</TableCell>
                    <TableCell sx={{ fontSize: 12 }}>Role</TableCell>
                    <TableCell sx={{ fontSize: 12 }}>Skills</TableCell>
                    <TableCell sx={{ fontSize: 12, whiteSpace: 'nowrap' }}>Date Joined</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(
                    (row, index) =>
                        row != null && (
                            <TableRow key={row.id}>
                                <TableCell sx={{ fontSize: 12 }}>{index + 1}</TableCell>
                                <TableCell sx={{ fontSize: 12 }}>{row.id}</TableCell>
                                <TableCell sx={{ fontSize: 12, whiteSpace: 'nowrap' }}>{row.name}</TableCell>
                                <TableCell sx={{ fontSize: 12 }}>{row.email}</TableCell>
                                <TableCell sx={{ fontSize: 12, whiteSpace: 'nowrap' }}>{row.gender}</TableCell>
                                <TableCell sx={{ fontSize: 12, whiteSpace: 'nowrap' }}>{row['current position']}</TableCell>
                                <TableCell sx={{ fontSize: 12, whiteSpace: 'nowrap' }}>{row['department or class']}</TableCell>
                                <TableCell sx={{ fontSize: 12, whiteSpace: 'nowrap' }}>{row['company or school']}</TableCell>
                                <TableCell sx={{ fontSize: 12 }}>{row.role}</TableCell>
                                <TableCell
                                    sx={{ fontSize: 12, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 200 }}
                                >
                                    {row.skills.join(', ')}
                                </TableCell>
                                <TableCell sx={{ fontSize: 12, whiteSpace: 'nowrap' }}>{row.dateJoined.split('T')[0]}</TableCell>
                            </TableRow>
                        )
                )}
            </TableBody>
        </Table>
    );
};
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

function SkillList(strongSkills) {
    if (!strongSkills) {
        return []; // Return an empty array if strongSkills is falsy (e.g., null, undefined)
    }
    return strongSkills.map((item) => ' ' + item.skill);
}
function mapIdsToIndexes(userIds, tableArray) {
    const idToIndexMap = new Map();
    userIds.forEach((userId, index) => {
        idToIndexMap.set(userId, index);
    });
    const indexedTableArray = tableArray.map((row) => row.map((id) => (idToIndexMap.has(id) ? idToIndexMap.get(id) : -1)));
    // Filter out the -1 values from each row
    const filteredTableArray = indexedTableArray.map((row) => row.filter((index) => index !== -1));
    // return indexedTableArray;
    return filteredTableArray;
}
function cleanParticipants(participants, allUsers) {
    const allUserIds = allUsers.map((user) => user.id);
    return participants?.filter((participant) => participant != null && allUserIds.includes(participant.id));
}
function cleanParticipants_withIds(participants, allUsers) {
    const allUserIds = allUsers.map((user) => user.id);
    return participants.filter((participant) => participant != null && allUserIds.includes(participant));
}

function updateTables(tables, indexMapping) {
    const mappedTables = tables.map((table) => table.map((row) => row.map((originalIndex) => indexMapping[originalIndex])));
    const filteredTables = mappedTables.map((round) => round.map((table) => table.filter((participant) => participant != null)));
    return filteredTables;
}

// set constraints and their orders
const data = [
    { id: 'activity-history', name: 'Activity History', value: 3 },
    { id: 'gender', name: 'Gender', value: 0 },
    { id: 'current-position', name: 'Current Position', value: 0 },
    { id: 'school-company', name: 'School/Company', value: 0 },
    { id: 'class-department-group', name: 'Class/Department/Group', value: 0 },
    { id: 'skills', name: 'Skills', value: 0 }
];
// Create a mapping between id and name
const idToNameMap = {};
data.forEach((item) => {
    idToNameMap[item.id] = item.name;
});
function TeamBuildingPage_Edit() {
    const { user: currentUser } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    if (!currentUser) {
        navigate('/login');
    }
    const query = useQuery();
    // Get query parameters
    const projectId = parseInt(query.get('id'));
    const newRound = parseInt(query.get('newRound'));
    if (isNaN(projectId)) {
        navigate('/team-building-history');
    }
    //expand/unexpand table data
    const [expanded, setExpanded] = useState(false);
    //Expand to add new round
    const [expandedCurrentRound, setExpandedCurrentRound] = useState(true);
    //Expand to add new round
    const [expandedNewRound, setExpandedNewRound] = useState(newRound == 1 ? true : false);

    // start Get project data
    const [content, setContent] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [tablesArray, setTables] = useState([]);
    const [tablesArray_withIds, setTables_withIds] = useState([]);
    const [participantsArray, setParticipantsArray] = useState([]);
    const [selectedParticipantsArray, setSelectedParticipantsArray] = useState([]);
    const [selectedParticipants, setSelectedParticipants] = useState([]);
    const [selectedParticipantIds, setSelectedParticipantIds] = useState([]);
    const [tempBox, setTempBox] = useState([]);
    const [tempBox_withIds, setTempBox_withIds] = useState([]);
    const [stakeholder_list, setStakeholder_list] = useState([]);
    const [users, setUsers] = useState([]);

    const [orders, setOrders] = useState([]);
    //for dialog
    const [openMap, setOpenMap] = React.useState(false);
    const handleClickOpenConfirmDialog = () => {
        // Open the dialog for the specific row (identified by id)
        setOpenMap(true);
    };
    const handleDialogClose = () => {
        // Close the dialog for the specific row (identified by id)
        setOpenMap(false);
        localStorage.removeItem('selectedUserIds_project' + projectId); // Remove stored selection
    };

    useEffect(() => {
        UserService.getAdminBoard().then(
            (response) => {
                const userData = JSON.parse(response.data['user_data']);
                const profileData = JSON.parse(response.data['profiles_data']);
                const usersDictionary = userData.reduce((acc, value) => {
                    if (value.pk != '1') {
                        const profile = profileData.find((p) => p.fields.user === value.pk);
                        acc[value.pk] = {
                            email: value.fields.username,
                            name: value.fields.first_name + ' ' + value.fields.last_name,
                            gender: profile ? profile.fields.gender : '-',
                            'current position': profile ? profile.fields.current_position : '-',
                            'department or class': profile ? profile.fields.group : '-',
                            'company or school': profile ? profile.fields.company_or_school : '-',
                            role: profile ? profile.fields.role : '-',
                            skills: profile ? SkillList(profile.fields.strong_skills) : '-',
                            dateJoined: value.fields.date_joined,
                            lastLogin: value.fields.last_login
                        };
                    }
                    return acc;
                }, {});
                const usersArray = Object.entries(usersDictionary).map(([key, value]) => ({
                    id: key,
                    ...value
                }));
                const updatedUsersArray = usersArray.map((user) => {
                    if (user.role == '4') {
                        return { ...user, role: 'Mentor' };
                    } else if (user.role == '3') {
                        return { ...user, role: 'Participant' };
                    } else if (user.role == '2') {
                        return { ...user, role: 'Stakeholder' };
                    } else if (user.role == '1') {
                        return { ...user, role: 'Admin' };
                    }
                    return user;
                });
                setAllUsers(updatedUsersArray);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log('error: ', _content);
            }
        );
    }, []);
    const reselectionToDataTable = (childdata) => {
        localStorage.setItem('selectedUserIds_project' + projectId, childdata);
        const selected_ids = JSON.parse(childdata);
        setSelectedParticipantIds(selected_ids);
        const filledSelectedParticipants = selected_ids.map((id) => {
            const user = allUsers.find((user) => user.id === id);
            return user ? user : null;
        });
        setSelectedParticipants(filledSelectedParticipants);
        //Indexes of participants in the result will be changed in case some participants removed So need to update the presented result.
        if (!expandedNewRound && tablesArray_withIds.length > 0) {
            const indexedTableArray = mapIdsToIndexes(selected_ids, tablesArray_withIds[tablesArray_withIds.length - 1]);
            setTables((prevArray) => {
                // Create a copy of the previous array
                const newArray = [...prevArray];
                // Update the last element in the copied array
                if (newArray.length > 0) {
                    newArray[newArray.length - 1] = indexedTableArray;
                }
                return newArray; // Set the updated array as the new state
            });
            const tablesArray_withIds_updated = tablesArray_withIds;
            tablesArray_withIds_updated[tablesArray_withIds_updated.length - 1] = tablesArray_withIds[tablesArray_withIds.length - 1].map(
                (table) => cleanParticipants_withIds(table, filledSelectedParticipants)
            );
            setTables_withIds(tablesArray_withIds_updated);
            setParticipantsArray((prevArray) => {
                // Create a copy of the previous array
                const newArray = [...prevArray];
                // Update the last element in the copied array
                if (newArray.length > 0) {
                    newArray[newArray.length - 1] = selected_ids;
                }
                return newArray; // Set the updated array as the new state
            });
            setSelectedParticipantsArray((prevArray) => {
                // Create a copy of the previous array
                const newArray = [...prevArray];
                // Update the last element in the copied array
                if (newArray.length > 0) {
                    newArray[newArray.length - 1] = filledSelectedParticipants;
                }
                return newArray; // Set the updated array as the new state
            });
            // Use the filter function to find participantIds not in tables
            const participantsNotInTables = selected_ids.filter((participantId) => {
                // Use the some function to check if the participantId is in any of the tables
                return !tablesArray_withIds[tablesArray_withIds.length - 1].some((table) => table.includes(participantId));
            });
            setTempBox_withIds(participantsNotInTables);
            const tempData = participantsNotInTables.map((id) => selected_ids.indexOf(id));
            setTempBox(tempData);
        }
        setOpenMap(false);
    };
    useEffect(() => {
        UserService.getProjects().then(
            (response) => {
                const projectData = JSON.parse(response.data['project_data']).find((p) => p.pk === parseInt(projectId));
                setContent(projectData);
                //-----clean data ----
                // Tables based on indexes
                const grouping_results_original = projectData?.fields.grouping_results;
                const participants_userId_original = projectData?.fields.participants_userId;
                const grouping_results_userId_original = projectData?.fields.grouping_results_userId;
                // Original list of participants
                const participants_original = projectData?.fields.participants;
                // Remove null elements from the participants
                const participants = cleanParticipants(participants_original, allUsers);

                // Create a mapping between original indices and new indices
                const indexMapping = {};
                participants?.forEach((participant, newIndex) => {
                    indexMapping[participants_original.indexOf(participant)] = newIndex;
                });
                // Update the tables with the new indices
                const grouping_results = updateTables(grouping_results_original, indexMapping);
                // Update participants & results with Ids
                const participants_userId =
                    participants_userId_original.length > 0
                        ? participants_userId_original.map((roundParticipants) => cleanParticipants_withIds(roundParticipants, allUsers))
                        : [];
                const participants_userId_stakeholder = participants_userId.map((participants) =>
                    participants.map((participantId) => {
                        return allUsers.find((user) => user.id === participantId && user.role === 'Stakeholder');
                    })
                    .filter((user) => user !== undefined) // Filter out null values where no matching user was found
                );
                setStakeholder_list(participants_userId_stakeholder);
                const grouping_results_userId =
                    grouping_results_userId_original.length > 0
                        ? grouping_results_userId_original.map((round) => round.map((table) => cleanParticipants_withIds(table, allUsers)))
                        : [];
                //-----clean data ----
                setTextState({
                    ...text,
                    ['projectTitle']: projectData.fields.title,
                    ['projectDes']: projectData.fields.description
                });
                setConstraints(projectData.fields.constraints);
                const constraints = projectData.fields.constraints[projectData.fields.constraints.length - 1];
                const combinedData = constraints.orders.map((id, index) => ({
                    id,
                    name: idToNameMap[id],
                    value: constraints.constraints_dict_value[index]
                }));
                setUsers(combinedData);
                setOrders(Object.values(combinedData).map((a) => a.id));
                setTableQuotas(projectData.fields.table_quotas);
                setState({
                    ...state,
                    ['table']: parseInt(projectData.fields.table_quotas[projectData.fields.table_quotas.length - 1])
                });
                constraints.orders.forEach((order, index) => {
                    setState((prevState) => ({
                        ...prevState,
                        [order]: constraints.constraints_dict_value[index]
                    }));
                });
                setTables(grouping_results);
                const selectedParticipant_Ids =
                    participants_userId.length > 0
                        ? participants_userId[participants_userId.length - 1]
                        : participants.map((item) => (item != null ? item.id : null));
                // Create a function to map indexes to IDs
                const mapIndexesToIDs = (indexes) => indexes.map((index) => selectedParticipant_Ids[index]);
                // Convert groupingResults to an array of IDs
                const arrayOfIDs = grouping_results.map((group) => group.map(mapIndexesToIDs));
                const tablesOfuserIds_list = grouping_results_userId.length > 0 ? grouping_results_userId : arrayOfIDs;
                setTables_withIds(tablesOfuserIds_list);

                const participantId_list =
                    participants_userId.length > 0
                        ? participants_userId
                        : Array.from({ length: grouping_results.length }, () => [...selectedParticipant_Ids]);
                setParticipantsArray(participantId_list);

                const selectedParticipant_Ids_updated = selectedParticipant_Ids;
                setSelectedParticipantIds(selectedParticipant_Ids_updated);

                const participantId_list_updated = participantId_list;
                participantId_list_updated[participantId_list_updated.length - 1] = selectedParticipant_Ids_updated;
                const filledSelectedParticipants = participantId_list_updated.map((participants) =>
                    participants.map((participantId) => {
                        const user = allUsers.find((user) => user.id === participantId);
                        return user ? user : null;
                    })
                );
                setSelectedParticipantsArray(filledSelectedParticipants);
                setSelectedParticipants(filledSelectedParticipants[filledSelectedParticipants.length - 1]);
                // Use the filter function to find participantIds not in tables
                // const participantsNotInTables = selectedParticipant_Ids_updated.filter((participantId) => {
                //     // Use the some function to check if the participantId is in any of the tables
                //     return !tablesOfuserIds_list[tablesOfuserIds_list.length - 1].some((table) => table.includes(participantId));
                // });
                const participantsNotInTables = selectedParticipant_Ids_updated.filter((participantId) => {
                    // Check if the participantId is not in any table in tablesOfuserIds_list
                    const notInTables = !tablesOfuserIds_list[tablesOfuserIds_list.length - 1].some((table) => table.includes(participantId));
                
                    // Check if the participantId is not in any participant in participants_userId_stakeholder
                    const notInStakeholders = !participants_userId_stakeholder.some((stakeholderGroup) =>
                        stakeholderGroup && stakeholderGroup.some((stakeholder) => stakeholder && stakeholder.id === participantId)
                    );
                
                    // Return true if participantId is not in both tables and stakeholders
                    return notInTables && notInStakeholders;
                });
                setTempBox_withIds(participantsNotInTables);
                const tempData = participantsNotInTables.map((id) => selectedParticipant_Ids.indexOf(id));
                setTempBox(tempData);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

                console.log('error: ', _content);
            }
        );
    }, [projectId, allUsers]);

    const onDragEnd = (e) => {
        if (!e.destination) return; // No valid drop destination
        const updatedRows = [...users];
        const [movedRow] = updatedRows.splice(e.source.index, 1);
        updatedRows.splice(e.destination.index, 0, movedRow);
        setUsers(updatedRows);
        let items = [];
        updatedRows.forEach((data, index) =>
            items.push(
                data.id
                /////
            )
        );
        setOrders(items);
    };
    const defaultData = {
        'activity-history': '3',
        gender: '0',
        'current-position': '0',
        'school-company': '0',
        'class-department-group': '0',
        skills: '0'
    };
    const [state, setState] = useState(defaultData);
    const onChange = (e) => {
        const { name, value, checked, type } = e.target;
        const newVal = type === 'checkbox' ? checked : value;
        setState({
            ...state,
            [name]: newVal
        });
    };
    const [text, setTextState] = useState({ projectTitle: '', projectDes: '' });
    const onTextChange = (e) => {
        const { name, value, checked, type } = e.target;
        const newVal = type === 'checkbox' ? checked : value;
        setTextState({
            ...text,
            [name]: newVal
        });
    };

    const tableRows = [
        'ID',
        'Email',
        'Name',
        'Gender',
        'Current Position',
        'Department or Class',
        'Company or School',
        'Role',
        'Skills',
        'Date Joined'
    ];

    // handle Team Building
    //State to store the response
    const [responseData, setResponse] = useState([]);
    //State to store the response-message

    //State to store table quotas and constrainst
    const [tableQuotas, setTableQuotas] = useState([]);
    const [constraintSet, setConstraints] = useState([]);
    //handleSubmit
    const handleSubmit = (event) => {
        var constraints_dict_value = [
            state[orders[0]],
            state[orders[1]],
            state[orders[2]],
            state[orders[3]],
            state[orders[4]],
            state[orders[5]]
        ];
        var table = state['table'];
        var mentor = state['mentor'] ? state['mentor'] : 1;
        var constraint_set = {
            mentor: mentor,
            orders: orders,
            constraints_dict_value: constraints_dict_value
        };
        if (selectedParticipants.length > 0) {
            if (state['table']) {
                Axios.post(
                    `${backend_base_url}/group_matching/`,
                    {
                        data: {
                            csvData: selectedParticipants,
                            // value 0- Ignore, 1- Common Only, 2 - Diversity Only, 3- Don't overlap at all
                            constraints_dict_name: orders,
                            constraints_dict_value: constraints_dict_value,
                            table: table,
                            mentor: mentor,
                            activity_hist: tablesArray
                        }
                    },
                    { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }
                ).then((response) => {
                    setResponse((prevArray) => [...prevArray, response.data]);
                    if (response.data['response'] == 'Success') {
                        setTables((prevArray) => [...prevArray, JSON.parse(response.data['message'])]);
                        setTableQuotas((prevNumber) => [...prevNumber, table]);
                        setConstraints((prevArray) => [...prevArray, constraint_set]);
                        // Convert groupingResults to an array of IDs
                        const arrayOfIDs = JSON.parse(response.data['message']).map((p) =>
                            p.map((index) => selectedParticipants[index].id)
                        );
                        setTables_withIds((prevArray) => [...prevArray, arrayOfIDs]);
                        setParticipantsArray((prevArray) => [...prevArray, selectedParticipantIds]);
                        setSelectedParticipantsArray((prevArray) => [...prevArray, selectedParticipants]);
                        setExpandedNewRound(!expandedNewRound);
                        setExpandedCurrentRound(!expandedCurrentRound);
                    }
                });
            } else {
                alert('Please enter the desired number of tables!');
            }
        } else {
            alert('Please upload your data!');
        }
    };
    //handleDuplicatePreviousRound
    const handleDuplicatePreviousRound = (event) => {
        var constraints_dict_value = [
            state[orders[0]],
            state[orders[1]],
            state[orders[2]],
            state[orders[3]],
            state[orders[4]],
            state[orders[5]]
        ];
        var table = state['table'];
        var mentor = state['mentor'] ? state['mentor'] : 1;
        var constraint_set = {
            mentor: mentor,
            orders: orders,
            constraints_dict_value: constraints_dict_value
        };
        setResponse((prevArray) => [...prevArray, { response: 'Success' }]);
        setTables((prevArray) => [...prevArray, prevArray[prevArray.length - 1]]);
        setTableQuotas((prevNumber) => [...prevNumber, table]);
        setConstraints((prevArray) => [...prevArray, constraint_set]);
        setTables_withIds((prevArray) => [...prevArray, prevArray[prevArray.length - 1]]);
        setParticipantsArray((prevArray) => [...prevArray, prevArray[prevArray.length - 1]]);
        setSelectedParticipantsArray((prevArray) => [...prevArray, prevArray[prevArray.length - 1]]);
        setExpandedNewRound(!expandedNewRound);
        setExpandedCurrentRound(!expandedCurrentRound);
    };

    //handleSubmit
    const handleReSubmit = (event) => {
        var constraints_dict_value = [
            state[orders[0]],
            state[orders[1]],
            state[orders[2]],
            state[orders[3]],
            state[orders[4]],
            state[orders[5]]
        ];
        var table = state['table'];
        var mentor = state['mentor'] ? state['mentor'] : 1;
        var constraint_set = {
            mentor: mentor,
            orders: orders,
            constraints_dict_value: constraints_dict_value
        };

        if (selectedParticipants.length > 0) {
            if (state['table']) {
                Axios.post(
                    `${backend_base_url}/group_matching/`,
                    {
                        data: {
                            csvData: selectedParticipants,
                            // value 0- Ignore, 1- Common Only, 2 - Diversity Only, 3- Don't overlap at all
                            constraints_dict_name: orders,
                            constraints_dict_value: constraints_dict_value,
                            table: table,
                            mentor: mentor,
                            activity_hist: tablesArray.slice(0, -1)
                        }
                    },
                    { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }
                ).then((response) => {
                    setResponse((prevArray) => [...prevArray, response.data]);
                    if (response.data['response'] == 'Success') {
                        setTables((prevArray) => {
                            // Create a copy of the previous array
                            const newArray = [...prevArray];
                            // Update the last element in the copied array
                            if (newArray.length > 0) {
                                newArray[newArray.length - 1] = JSON.parse(response.data['message']);
                            }
                            return newArray; // Set the updated array as the new state
                        });
                        // Convert groupingResults to an array of IDs
                        const arrayOfIDs = JSON.parse(response.data['message']).map((p) =>
                            p.map((index) => selectedParticipants[index].id)
                        );
                        setTempBox([]);
                        setTempBox_withIds([]);
                        setTables_withIds((prevArray) => {
                            // Create a copy of the previous array
                            const newArray = [...prevArray];
                            // Update the last element in the copied array
                            if (newArray.length > 0) {
                                newArray[newArray.length - 1] = arrayOfIDs;
                            }
                            return newArray; // Set the updated array as the new state
                        });

                        setConstraints((prevArray) => {
                            // Create a copy of the previous array
                            const newArray = [...prevArray];
                            // Update the last element in the copied array
                            if (newArray.length > 0) {
                                newArray[newArray.length - 1] = constraint_set;
                            }
                            return newArray; // Set the updated array as the new state
                        });
                        setParticipantsArray((prevArray) => {
                            // Create a copy of the previous array
                            const newArray = [...prevArray];
                            // Update the last element in the copied array
                            if (newArray.length > 0) {
                                newArray[newArray.length - 1] = selectedParticipantIds;
                            }
                            return newArray; // Set the updated array as the new state
                        });
                        setSelectedParticipantsArray((prevArray) => {
                            // Create a copy of the previous array
                            const newArray = [...prevArray];
                            // Update the last element in the copied array
                            if (newArray.length > 0) {
                                newArray[newArray.length - 1] = selectedParticipants;
                            }
                            return newArray; // Set the updated array as the new state
                        });
                    }
                });
            } else {
                alert('Please enter the desired number of tables!');
            }
        } else {
            alert('Please upload your data!');
        }
    };

    //handle Drop and Drag participants between tables in the last round
    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const { source, destination } = result;
        const sourceListIndex = parseInt(source.droppableId);
        const destinationListIndex = parseInt(destination.droppableId);

        const lastListIndex = tablesArray.length - 1;
        const lastList = tablesArray[lastListIndex];
        const combinedList = [...lastList, tempBox];

        const updatedLastList = [...combinedList];
        const [removed] = updatedLastList[sourceListIndex].splice(source.index, 1);
        updatedLastList[destinationListIndex].splice(destination.index, 0, removed);

        const updatedTablesArray = [...tablesArray];
        updatedTablesArray[lastListIndex] = updatedLastList.slice(0, -1);
        const updatedTempBox = updatedLastList[updatedLastList.length - 1];

        setTables(updatedTablesArray);
        setTempBox(updatedTempBox);
        // Create a function to map indexes to IDs
        const mapIndexesToIDs = (indexes) => indexes.map((index) => selectedParticipantIds[index]);
        // Convert groupingResults[lastListIndex] to an array of IDs
        const updatedTablesArray_withIds = [...tablesArray_withIds];
        updatedTablesArray_withIds[lastListIndex] = updatedTablesArray[lastListIndex].map(mapIndexesToIDs);
        setTables_withIds(updatedTablesArray_withIds);
        setTempBox_withIds(updatedTempBox.map((index) => selectedParticipantIds[index]));
    };

    //handle buttongroup
    const handleDiscard = (event) => {
        event.preventDefault();
        localStorage.removeItem('selectedUserIds_project' + projectId);
        navigate('/team-building-history');
    };

    const handleSaveResult = (event) => {
        Axios.post(
            `${backend_base_url}/update_project/${projectId}/`,
            {
                project: {
                    title: text['projectTitle'],
                    description: text['projectDes'],
                    event_id: 0, // 0 - undefined
                    // event_id: selectedEvent,
                    participants: selectedParticipants,
                    participants_userId: participantsArray.map((row) => row.filter((id) => id != null)),
                    table_quotas: tableQuotas,
                    constraints: constraintSet,
                    grouping_results: tablesArray,
                    grouping_results_userId: tablesArray_withIds.map((innerArray) =>
                        innerArray.map((row) => row.filter((id) => id !== null))
                    ),
                    last_updated_by_id: JSON.parse(localStorage.getItem('user'))['id']
                },
                // event: selectedEvent + ' - ' + getEventNameById(selectedEvent)
                event: '0 - undefined'
            },
            {
                headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
            }
        ).then((response) => {
            if (response['data']['response'] == 'error') {
                alert('An error occurred (' + response['data']['message'] + ') and we could not save the data.');
            } else {
                alert('The project have been updated successfully!');
                localStorage.removeItem('selectedUserIds_project' + projectId);
                navigate('/team-building-history');
            }
        });
    };
    const handleSaveAndAddNewRound = (event) => {
        Axios.post(
            `${backend_base_url}/update_project/${projectId}/`,
            {
                project: {
                    title: text['projectTitle'],
                    description: text['projectDes'],
                    // event_id: selectedEvent,
                    event_id: 0, // 0 - undefined
                    participants: selectedParticipants,
                    participants_userId: participantsArray.map((row) => row.filter((id) => id != null)),
                    table_quotas: tableQuotas,
                    constraints: constraintSet,
                    grouping_results: tablesArray,
                    grouping_results_userId: tablesArray_withIds.map((innerArray) =>
                        innerArray.map((row) => row.filter((id) => id !== null))
                    ),
                    last_updated_by_id: JSON.parse(localStorage.getItem('user'))['id']
                },
                // event: selectedEvent + ' - ' + getEventNameById(selectedEvent)
                event: '0 - undefined'
            },
            {
                headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
            }
        ).then((response) => {
            if (response['data']['response'] == 'error') {
                alert('An error occurred (' + response['data']['message'] + ') and we could not save the data.');
            } else {
                // alert('The project have been updated successfully!');
                setTempBox([]);
                setTempBox_withIds([]);
                localStorage.removeItem('selectedUserIds_project' + projectId);
                setExpandedNewRound(!expandedNewRound);
            }
        });
    };

    //handle activities on Modal
    const [checkedRound, setCheckedRound] = useState([Array(tablesArray.length).fill(false)]);

    const checkedLabels = checkedRound.reduce((acc, value, index) => {
        if (value) {
            return [...acc, index + 1];
        }
        return acc;
    }, []);
    localStorage.setItem('selectedRound', JSON.stringify(checkedLabels));

    const handleDeleteRound = (roundIndex) => {
        if (roundIndex == participantsArray.length - 1) {
            localStorage.removeItem('selectedUserIds_project' + projectId);
            if (participantsArray.length > 1) {
                setSelectedParticipantIds(participantsArray[participantsArray.length - 2]);
                setSelectedParticipants(selectedParticipantsArray[selectedParticipantsArray.length - 2]);
                const participantsNotInTables = participantsArray[participantsArray.length - 2].filter((participantId) => {
                    // Use the some function to check if the participantId is in any of the tables
                    return !tablesArray_withIds[tablesArray_withIds.length - 2].some((table) => table.includes(participantId));
                });
                setTempBox_withIds(participantsNotInTables);
                const tempData = participantsNotInTables.map((id) => participantsArray[participantsArray.length - 2].indexOf(id));
                setTempBox(tempData);
                // setExpandedNewRound(expandedNewRound);
            }
        }
        setTables((prevArray) => {
            const newArray = [...prevArray];
            newArray.splice(roundIndex, 1); // Remove one element at the specified index
            return newArray;
        });
        setTables_withIds((prevArray) => {
            const newArray = [...prevArray];
            newArray.splice(roundIndex, 1); // Remove one element at the specified index
            return newArray;
        });
        setSelectedParticipantsArray((prevArray) => {
            const newArray = [...prevArray];
            newArray.splice(roundIndex, 1); // Remove one element at the specified index
            return newArray;
        });
        setParticipantsArray((prevArray) => {
            const newArray = [...prevArray];
            newArray.splice(roundIndex, 1); // Remove one element at the specified index
            return newArray;
        });
        setConstraints((prevArray) => {
            const newArray = [...prevArray];
            newArray.splice(roundIndex, 1); // Remove one element at the specified index
            return newArray;
        });
    };
    const handleResetParticipantData = (event) => {
        localStorage.removeItem('selectedUserIds_project' + projectId);
        location.reload();
    };
    const handleAddNewRound = (event) => {
        setExpandedNewRound(!expandedNewRound);
    };

    const handleCancelNewRound = (event) => {
        localStorage.removeItem('selectedUserIds_project' + projectId);
        setSelectedParticipantIds(participantsArray[participantsArray.length - 1]);
        setSelectedParticipants(selectedParticipantsArray[selectedParticipantsArray.length - 1]);
        setExpandedNewRound(!expandedNewRound);
        //update tempbox
        const participantsNotInTables = participantsArray[participantsArray.length - 1].filter((participantId) => {
            // Use the some function to check if the participantId is in any of the tables
            return !tablesArray_withIds[tablesArray_withIds.length - 1].some((table) => table.includes(participantId));
        });
        setTempBox_withIds(participantsNotInTables);
        const tempData = participantsNotInTables.map((id) => participantsArray[participantsArray.length - 1].indexOf(id));
        setTempBox(tempData);
    };
    if (selectedParticipantIds && selectedParticipantsArray && content && content.fields && content.fields.constraints) {
        const constraints = content.fields.constraints[content.fields.constraints.length - 1];
        return (
            <Container>
                <h4>PUMAS Team Building Form</h4>
                <br />
                <br />
                <Dialog
                    fullWidth
                    maxWidth='xl'
                    open={openMap || false}
                    onClose={handleDialogClose}
                    aria-labelledby={`alert-dialog-title`}
                    aria-describedby={`alert-dialog-description`}
                    BackdropProps={{
                        style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' }
                    }}
                >
                    <DialogTitle id={`alert-dialog-title`}>{'Re-select participants?'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id={`alert-dialog-description`}>
                            <ReselectionTable project_id={projectId} reselectionToDataTable={reselectionToDataTable} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <AnimateButton>
                            <ButtonGroup>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    onClick={() => handleDialogClose()}
                                    //
                                >
                                    <ClearIcon sx={{ mr: 1 }} />
                                    Cancel
                                </Button>
                            </ButtonGroup>
                        </AnimateButton>
                    </DialogActions>
                </Dialog>
                <Grid>
                    <InputLabel mt={12}>
                        <strong>Project Details</strong>
                    </InputLabel>
                    <TableContainer sx={{ border: '2px solid grey', borderColor: 'primary.main' }}>
                        <Table className="table" sx={{ margin: 'auto' }}>
                            <TableBody className="text-capitalize">
                                <TableRow style={{ color: 'white' }} key={'e2'}>
                                    <TableCell>Project Title (*)</TableCell>
                                    <TableCell>
                                        <Input
                                            name="projectTitle"
                                            onChange={onTextChange}
                                            defaultValue={text['projectTitle']}
                                            disabled={role == '2'}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow style={{ color: 'white' }} key={'e3'}>
                                    <TableCell>Project Description</TableCell>
                                    <TableCell>
                                        <TextField
                                            name="projectDes"
                                            onChange={onTextChange}
                                            defaultValue={text['projectDes']}
                                            disabled={role == '2'}
                                            ///
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <br />
                <br />
                {tablesArray.length == 0 && role == '1' && (
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <InputLabel mt={10}>List of Selected Participants:</InputLabel>
                            <TableContainer sx={{ maxHeight: 650, border: '2px dashed grey', borderColor: 'primary.main' }}>
                                <MyTable
                                    data={
                                        selectedParticipantsArray.length > 0
                                            ? selectedParticipantsArray[selectedParticipantsArray.length - 1]
                                            : selectedParticipants
                                    }
                                />
                            </TableContainer>
                            <br />
                            <Button>
                                <Link
                                    onClick={() => handleClickOpenConfirmDialog()}
                                    variant="body1"
                                    sx={{ textDecoration: 'underline' }}
                                    color="primary"
                                    // target="_blank"
                                    // rel="noopener"
                                >
                                    <IsoIcon />
                                    Re-select participants?
                                </Link>
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel mt={10}>Constraints' Details:</InputLabel>
                            <TableContainer sx={{ border: '2px dashed grey', borderColor: 'primary.main' }}>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Table className="table" sx={{ margin: 'auto' }}>
                                        <TableHead>
                                            <TableRow key={'c0'}>
                                                <TableCell>Constraint Name</TableCell>
                                                <TableCell>Value</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <Droppable droppableId="droppable-1" key="droppable-1">
                                            {(provider, snapshot) => (
                                                <TableBody
                                                    className="text-capitalize"
                                                    ref={provider.innerRef}
                                                    {...provider.droppableProps}
                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                    /////////////////////////////
                                                >
                                                    {users?.map((user, index) => (
                                                        <Draggable
                                                            draggableId={'' + user.id}
                                                            index={index}
                                                            key={'' + user.id}
                                                            //////////////////////
                                                        >
                                                            {(provider, snapshot) => (
                                                                <TableRow
                                                                    {...provider.draggableProps}
                                                                    {...provider.dragHandleProps}
                                                                    ref={provider.innerRef}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provider.draggableProps.style
                                                                        ///////////////////////
                                                                    )}
                                                                >
                                                                    <TableCell>{user.name}</TableCell>
                                                                    <TableCell>
                                                                        {user.id == 'activity-history' ? (
                                                                            <Select
                                                                                name={user.id}
                                                                                onChange={onChange}
                                                                                defaultValue={user.value}
                                                                            >
                                                                                <MenuItem value="0">Ignore</MenuItem>
                                                                                <MenuItem value="3">Don't overlap at all</MenuItem>
                                                                            </Select>
                                                                        ) : (
                                                                            <Select
                                                                                name={user.id}
                                                                                onChange={onChange}
                                                                                defaultValue={user.value}
                                                                            >
                                                                                <MenuItem value="0">Ignore</MenuItem>
                                                                                <MenuItem value="1">Common Only</MenuItem>
                                                                                <MenuItem value="2">Diversity Only</MenuItem>
                                                                            </Select>
                                                                        )}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provider.placeholder}
                                                </TableBody>
                                            )}
                                        </Droppable>
                                    </Table>
                                </DragDropContext>
                                <Table>
                                    <TableBody>
                                        <TableRow style={{ color: 'white' }}>
                                            <TableCell>Mentor</TableCell>
                                            <TableCell>
                                                <NativeSelect
                                                    name="mentor"
                                                    onChange={onChange}
                                                    defaultValue={constraints.mentor ? constraints.mentor : 1}
                                                >
                                                    <option value="1">At least one mentor per group</option>
                                                    <option value="0">Doesn't matter</option>
                                                </NativeSelect>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow style={{ color: 'white' }} key={'e4'}>
                                            <TableCell>Desired number of tables (*):</TableCell>
                                            <TableCell>
                                                <Input
                                                    name="table"
                                                    type="number"
                                                    onChange={onChange}
                                                    defaultValue={state['table']}
                                                    //
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <AnimateButton>
                            <Button
                                disableElevation
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                ////
                            >
                                Build Team - Round 1
                            </Button>
                        </AnimateButton>
                    </Grid>
                )}
                {tablesArray.length > 0 && (
                    <Button
                        disableElevation
                        fullWidth
                        size="large"
                        variant="contained"
                        color="primary"
                        // disabled
                        ////
                    >
                        Team Generated
                    </Button>
                )}

                {tablesArray_withIds.length > 0 && (
                    <div>
                        {tablesArray_withIds.map((tables, i) => {
                            if (i == tablesArray_withIds.length - 1) {
                                if (!expandedNewRound) {
                                    return (
                                        <div>
                                            <Box
                                                sx={{ border: '1px solid grey', borderColor: 'primary.main' }}
                                                alignItems="center"
                                                margin="auto"
                                                padding="10px"
                                                //
                                            >
                                                <h5>Round {i + 1}:</h5>
                                                {role == '1' && (
                                                    <Button
                                                        variant="contained"
                                                        startIcon={<DeleteIcon />}
                                                        color="error"
                                                        sx={{ textAlign: 'center', display: 'block', marginLeft: 'auto' }}
                                                        onClick={() => handleDeleteRound(i)}
                                                    >
                                                        Delete Round
                                                    </Button>
                                                )}
                                                {role == '1' && (
                                                    <Typography variant="h5" onClick={() => setExpandedCurrentRound(!expandedCurrentRound)}>
                                                        <Tooltip title="Click/Unclick to Show/Hide tables in details.">
                                                            <Button>Show/Hide Participant and Constraint List</Button>
                                                        </Tooltip>
                                                    </Typography>
                                                )}
                                                {role == '1' && (
                                                    <Collapse in={expandedCurrentRound} timeout="auto" unmountOnExit>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={6}>
                                                                <InputLabel mt={10}>List of Selected Participants:</InputLabel>
                                                                <TableContainer
                                                                    sx={{
                                                                        maxHeight: 650,
                                                                        border: '2px dashed grey',
                                                                        borderColor: 'primary.main'
                                                                    }}
                                                                >
                                                                    <MyTable data={selectedParticipants ? selectedParticipants : []} />
                                                                </TableContainer>
                                                                <br />
                                                                <Button>
                                                                    <Link
                                                                        onClick={() => handleClickOpenConfirmDialog()}
                                                                        variant="body1"
                                                                        sx={{ textDecoration: 'underline' }}
                                                                        color="primary"
                                                                        // target="_blank"
                                                                        // rel="noopener"
                                                                    >
                                                                        <IsoIcon />
                                                                        Re-select participants?
                                                                    </Link>
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <InputLabel mt={10}>Constraints' Details:</InputLabel>
                                                                <TableContainer sx={{ border: '2px dashed grey', borderColor: 'primary.main' }}>
                                                                    <DragDropContext onDragEnd={onDragEnd}>
                                                                        <Table className="table" sx={{ margin: 'auto' }}>
                                                                            <TableHead>
                                                                                <TableRow key={'c0'}>
                                                                                    <TableCell>Constraint Name</TableCell>
                                                                                    <TableCell>Value</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <Droppable droppableId="droppable-1" key="droppable-1">
                                                                                {(provider, snapshot) => (
                                                                                    <TableBody
                                                                                        className="text-capitalize"
                                                                                        ref={provider.innerRef}
                                                                                        {...provider.droppableProps}
                                                                                        style={getListStyle(snapshot.isDraggingOver)}
                                                                                        /////////////////////////////
                                                                                    >
                                                                                        {users?.map((user, index) => (
                                                                                            <Draggable
                                                                                                draggableId={'' + user.id}
                                                                                                index={index}
                                                                                                key={'' + user.id}
                                                                                                //////////////////////
                                                                                            >
                                                                                                {(provider, snapshot) => (
                                                                                                    <TableRow
                                                                                                        {...provider.draggableProps}
                                                                                                        {...provider.dragHandleProps}
                                                                                                        ref={provider.innerRef}
                                                                                                        style={getItemStyle(
                                                                                                            snapshot.isDragging,
                                                                                                            provider.draggableProps.style
                                                                                                            ///////////////////////
                                                                                                        )}
                                                                                                    >
                                                                                                        <TableCell>{user.name}</TableCell>
                                                                                                        <TableCell>
                                                                                                            {user.id == 'activity-history' ? (
                                                                                                                <Select
                                                                                                                    name={user.id}
                                                                                                                    onChange={onChange}
                                                                                                                    defaultValue={user.value}
                                                                                                                >
                                                                                                                    <MenuItem value="0">
                                                                                                                        Ignore
                                                                                                                    </MenuItem>
                                                                                                                    <MenuItem value="3">
                                                                                                                        Don't overlap at all
                                                                                                                    </MenuItem>
                                                                                                                </Select>
                                                                                                            ) : (
                                                                                                                <Select
                                                                                                                    name={user.id}
                                                                                                                    onChange={onChange}
                                                                                                                    defaultValue={user.value}
                                                                                                                >
                                                                                                                    <MenuItem value="0">
                                                                                                                        Ignore
                                                                                                                    </MenuItem>
                                                                                                                    <MenuItem value="1">
                                                                                                                        Common Only
                                                                                                                    </MenuItem>
                                                                                                                    <MenuItem value="2">
                                                                                                                        Diversity Only
                                                                                                                    </MenuItem>
                                                                                                                </Select>
                                                                                                            )}
                                                                                                        </TableCell>
                                                                                                    </TableRow>
                                                                                                )}
                                                                                            </Draggable>
                                                                                        ))}
                                                                                        {provider.placeholder}
                                                                                    </TableBody>
                                                                                )}
                                                                            </Droppable>
                                                                        </Table>
                                                                    </DragDropContext>
                                                                    <Table>
                                                                        <TableBody>
                                                                            <TableRow style={{ color: 'white' }}>
                                                                                <TableCell>Mentor</TableCell>
                                                                                <TableCell>
                                                                                    <NativeSelect
                                                                                        name="mentor"
                                                                                        onChange={onChange}
                                                                                        defaultValue={
                                                                                            constraints.mentor ? constraints.mentor : 1
                                                                                        }
                                                                                    >
                                                                                        <option value="1">At least one mentor per group</option>
                                                                                        <option value="0">Doesn't matter</option>
                                                                                    </NativeSelect>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow style={{ color: 'white' }} key={'e4'}>
                                                                                <TableCell>Desired number of tables (*):</TableCell>
                                                                                <TableCell>
                                                                                    <Input
                                                                                        name="table"
                                                                                        type="number"
                                                                                        onChange={onChange}
                                                                                        defaultValue={state['table']}
                                                                                        //
                                                                                    />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Grid>
                                                        </Grid>
                                                        <Button variant="contained" onClick={() => handleReSubmit(i)}>
                                                            Re-build Team
                                                        </Button>
                                                    </Collapse>
                                                )}
                                                <br />
                                                {stakeholder_list[i]?.length > 0 ?
                                                (<Stack direction="row" mt={2} mb={5}>
                                                    Stakeholders: {selectedParticipants.map((value, index) => {
                                                                                                if (value != null && value.role === 'Stakeholder') {
                                                                                                    return (<Chip sx={{ ml:2, mr:2 }} size="small" label={value.id + ' - ' + value.name} />)}})}
                                                </Stack>) : null }
                                                <Container sx={{ bgcolor: '' }}>
                                                    <Grid container spacing={2}>
                                                        <DragDropContext onDragEnd={handleDragEnd}>
                                                            {tables?.map((table, tableIndex) => (
                                                                <Droppable droppableId={`${tableIndex}`} key={tableIndex}>
                                                                    {(provider, snapshot) => (
                                                                        <Grid
                                                                            item
                                                                            xs={3}
                                                                            className="table borderd"
                                                                            ref={provider.innerRef}
                                                                            {...provider.droppableProps}
                                                                            // style={getListStyle(snapshot.isDraggingOver)}
                                                                            /////////////////////////////
                                                                        >
                                                                            <ListItemText
                                                                                primary={`Table ${tableIndex + 1}`}
                                                                                primaryTypographyProps={{
                                                                                    color: 'black',
                                                                                    fontWeight: 600,
                                                                                    variant: 'body1'
                                                                                }}
                                                                            />
                                                                            {table.map((user, index) => (
                                                                                <List
                                                                                    sx={{
                                                                                        width: '100%',
                                                                                        maxWidth: 360,
                                                                                        height: 50,
                                                                                        bgcolor: 'background.paper'
                                                                                    }}
                                                                                >
                                                                                    <Draggable
                                                                                        draggableId={`${tableIndex}-${index}`}
                                                                                        index={index}
                                                                                        key={`${tableIndex}-${index}`}
                                                                                        //////////////////////
                                                                                    >
                                                                                        {(provider, snapshot) => (
                                                                                            <ListItem
                                                                                                {...provider.draggableProps}
                                                                                                {...provider.dragHandleProps}
                                                                                                ref={provider.innerRef}
                                                                                                // style={getItemStyle(
                                                                                                //     snapshot.isDragging,
                                                                                                //     provider.draggableProps.style
                                                                                                //     ///////////////////////
                                                                                                // )}
                                                                                            >
                                                                                                {/* {user} */}
                                                                                                {selectedParticipants?.map(
                                                                                                    (value, index) => {
                                                                                                        if (
                                                                                                            value != null &&
                                                                                                            value.id == user
                                                                                                        ) {
                                                                                                            return (
                                                                                                                <ListItemText
                                                                                                                    primaryTypographyProps={{
                                                                                                                        style:
                                                                                                                            value.role ===
                                                                                                                            'Mentor'
                                                                                                                                ? {
                                                                                                                                      backgroundColor:
                                                                                                                                          'yellow'
                                                                                                                                  }
                                                                                                                                : null
                                                                                                                    }}
                                                                                                                    primary={
                                                                                                                        value.id +
                                                                                                                        ' - ' +
                                                                                                                        value.name
                                                                                                                    }
                                                                                                                />
                                                                                                            );
                                                                                                        }
                                                                                                    }
                                                                                                )}
                                                                                            </ListItem>
                                                                                        )}
                                                                                    </Draggable>
                                                                                    {provider.placeholder}
                                                                                </List>
                                                                            ))}
                                                                        </Grid>
                                                                    )}
                                                                </Droppable>
                                                            ))}
                                                            {/* Temporary box for items not in tables */}
                                                            <Droppable droppableId={`${tables.length}`} key={tables.length}>
                                                                {(provider, snapshot) => (
                                                                    <Grid
                                                                        item
                                                                        xs={3}
                                                                        className="table borderd"
                                                                        ref={provider.innerRef}
                                                                        {...provider.droppableProps}
                                                                    >
                                                                        <ListItemText
                                                                            primary="Temporary Box"
                                                                            primaryTypographyProps={{
                                                                                color: 'black',
                                                                                fontWeight: 600,
                                                                                variant: 'body1'
                                                                            }}
                                                                        />
                                                                        {tempBox_withIds.map((user, index) => (
                                                                            <List
                                                                                sx={{
                                                                                    width: '100%',
                                                                                    maxWidth: 360,
                                                                                    height: 50,
                                                                                    bgcolor: 'red',
                                                                                    pb: 8
                                                                                }}
                                                                            >
                                                                                <Draggable
                                                                                    draggableId={`TempBox-${index}`}
                                                                                    index={index}
                                                                                    key={`TempBox-${index}`}
                                                                                    //////////////////////
                                                                                >
                                                                                    {(provider, snapshot) => (
                                                                                        <ListItem
                                                                                            {...provider.draggableProps}
                                                                                            {...provider.dragHandleProps}
                                                                                            ref={provider.innerRef}
                                                                                        >
                                                                                            {selectedParticipants?.map((value, index) => {
                                                                                                if (value != null && value.id == user) {
                                                                                                    return (
                                                                                                        <ListItemText
                                                                                                            primaryTypographyProps={{
                                                                                                                style:
                                                                                                                    value.role === 'Mentor'
                                                                                                                        ? {
                                                                                                                              backgroundColor:
                                                                                                                                  'yellow'
                                                                                                                          }
                                                                                                                        : null
                                                                                                            }}
                                                                                                            primary={
                                                                                                                value.id +
                                                                                                                ' - ' +
                                                                                                                value.name
                                                                                                            }
                                                                                                        />
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                        </ListItem>
                                                                                    )}
                                                                                </Draggable>
                                                                                {provider.placeholder}
                                                                            </List>
                                                                        ))}
                                                                    </Grid>
                                                                )}
                                                            </Droppable>
                                                        </DragDropContext>
                                                    </Grid>
                                                    <br />
                                                    <Typography variant="h5" onClick={() => setExpanded(!expanded)}>
                                                        <Tooltip title="Click/Unclick to Show/Hide tables in details.">
                                                            <Button>Show/Hide Table Details</Button>
                                                        </Tooltip>
                                                    </Typography>
                                                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                                                        <Grid>
                                                            {tables.map((table, index) => (
                                                                <TableContainer
                                                                    sx={{
                                                                        maxHeight: 440,
                                                                        border: '2px dashed grey',
                                                                        borderColor: 'primary.main'
                                                                    }}
                                                                >
                                                                    <Typography variant="h5" component="h6">
                                                                        Table {index + 1}:
                                                                    </Typography>
                                                                    <Table key={index} className="table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                {tableRows.map((rows, index) => {
                                                                                    return <TableCell key={index}>{rows}</TableCell>;
                                                                                })}
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        {table.map((user) => (
                                                                            <TableBody>
                                                                                {selectedParticipantsArray[i]?.map((row, index) => {
                                                                                    if (row != null && row.id == user) {
                                                                                        return (
                                                                                            <TableRow
                                                                                                key={index}
                                                                                                style={{ color: 'red' }}
                                                                                                sx={{
                                                                                                    color: 'red'
                                                                                                }}
                                                                                            >
                                                                                                <TableCell
                                                                                                    style={{
                                                                                                        backgroundColor:
                                                                                                            row.role == 'Mentor'
                                                                                                                ? 'yellow'
                                                                                                                : null
                                                                                                    }}
                                                                                                >
                                                                                                    {row.id}
                                                                                                </TableCell>
                                                                                                <TableCell>{row.email}</TableCell>
                                                                                                <TableCell>{row.name}</TableCell>
                                                                                                <TableCell>{row.gender}</TableCell>
                                                                                                <TableCell>
                                                                                                    {row['current position']}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {row['department or class']}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {row['company or school']}
                                                                                                </TableCell>
                                                                                                <TableCell>{row.role}</TableCell>
                                                                                                <TableCell>
                                                                                                    {row.skills.join(', ')}
                                                                                                </TableCell>
                                                                                                <TableCell>{row.dateJoined}</TableCell>
                                                                                            </TableRow>
                                                                                        );
                                                                                    }
                                                                                })}
                                                                            </TableBody>
                                                                        ))}
                                                                    </Table>
                                                                </TableContainer>
                                                            ))}
                                                        </Grid>
                                                    </Collapse>
                                                </Container>
                                            </Box>
                                            <br />
                                            <br />
                                            {selectedParticipants.length > 0 && (
                                                <ButtonGroup
                                                    fullWidth
                                                    size="large"
                                                    variant="outlined"
                                                    aria-label="large outlined primary button group"
                                                >
                                                    <Button variant="contained" onClick={handleSaveResult}>
                                                        <SaveIcon />
                                                        Save &
                                                        <ExitToAppIcon />
                                                        Exit
                                                    </Button>
                                                    <Button color="warning" onClick={handleResetParticipantData}>
                                                        <RestartAltIcon />
                                                        Undo All Changes
                                                    </Button>
                                                    <br />
                                                    <Button color="error" onClick={handleDiscard}>
                                                        <ClearIcon />
                                                        Cancel Edit
                                                    </Button>
                                                </ButtonGroup>
                                            )}
                                            <br />
                                            <br />
                                            <br />
                                            {selectedParticipants.length > 0 && role == '1' && (
                                                <Button variant="outlined" onClick={handleSaveAndAddNewRound}>
                                                    <SaveIcon />
                                                    Save &
                                                    <AddIcon />
                                                    Add New Round
                                                    <ArrowRightIcon />
                                                </Button>
                                            )}
                                            {role == '1' && (
                                                <Collapse in={expandedNewRound} timeout="auto" unmountOnExit>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={6}>
                                                            <InputLabel mt={10}>List of Selected Participants:</InputLabel>
                                                            <TableContainer
                                                                sx={{ maxHeight: 650, border: '2px dashed grey', borderColor: 'primary.main' }}
                                                            >
                                                                <MyTable data={selectedParticipants.length > 0 ? selectedParticipants : []} />
                                                            </TableContainer>
                                                            <br />
                                                            <Button>
                                                                <Link
                                                                    onClick={() => handleClickOpenConfirmDialog()}
                                                                    variant="body1"
                                                                    sx={{ textDecoration: 'underline' }}
                                                                    color="primary"
                                                                    // target="_blank"
                                                                    // rel="noopener"
                                                                >
                                                                    <IsoIcon />
                                                                    Re-select participants?
                                                                </Link>
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <InputLabel mt={10}>Constraints' Details:</InputLabel>
                                                            <TableContainer sx={{ border: '2px dashed grey', borderColor: 'primary.main' }}>
                                                                <DragDropContext onDragEnd={onDragEnd}>
                                                                    <Table className="table" sx={{ margin: 'auto' }}>
                                                                        <TableHead>
                                                                            <TableRow key={'c0'}>
                                                                                <TableCell>Constraint Name</TableCell>
                                                                                <TableCell>Value</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <Droppable droppableId="droppable-1" key="droppable-1">
                                                                            {(provider, snapshot) => (
                                                                                <TableBody
                                                                                    className="text-capitalize"
                                                                                    ref={provider.innerRef}
                                                                                    {...provider.droppableProps}
                                                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                                                    /////////////////////////////
                                                                                >
                                                                                    {users?.map((user, index) => (
                                                                                        <Draggable
                                                                                            draggableId={'' + user.id}
                                                                                            index={index}
                                                                                            key={'' + user.id}
                                                                                            //////////////////////
                                                                                        >
                                                                                            {(provider, snapshot) => (
                                                                                                <TableRow
                                                                                                    {...provider.draggableProps}
                                                                                                    {...provider.dragHandleProps}
                                                                                                    ref={provider.innerRef}
                                                                                                    style={getItemStyle(
                                                                                                        snapshot.isDragging,
                                                                                                        provider.draggableProps.style
                                                                                                        ///////////////////////
                                                                                                    )}
                                                                                                >
                                                                                                    <TableCell>{user.name}</TableCell>
                                                                                                    <TableCell>
                                                                                                        {user.id == 'activity-history' ? (
                                                                                                            <Select
                                                                                                                name={user.id}
                                                                                                                onChange={onChange}
                                                                                                                defaultValue={user.value}
                                                                                                            >
                                                                                                                <MenuItem value="0">
                                                                                                                    Ignore
                                                                                                                </MenuItem>
                                                                                                                <MenuItem value="3">
                                                                                                                    Don't overlap at all
                                                                                                                </MenuItem>
                                                                                                            </Select>
                                                                                                        ) : (
                                                                                                            <Select
                                                                                                                name={user.id}
                                                                                                                onChange={onChange}
                                                                                                                defaultValue={user.value}
                                                                                                            >
                                                                                                                <MenuItem value="0">
                                                                                                                    Ignore
                                                                                                                </MenuItem>
                                                                                                                <MenuItem value="1">
                                                                                                                    Common Only
                                                                                                                </MenuItem>
                                                                                                                <MenuItem value="2">
                                                                                                                    Diversity Only
                                                                                                                </MenuItem>
                                                                                                            </Select>
                                                                                                        )}
                                                                                                    </TableCell>
                                                                                                </TableRow>
                                                                                            )}
                                                                                        </Draggable>
                                                                                    ))}
                                                                                    {provider.placeholder}
                                                                                </TableBody>
                                                                            )}
                                                                        </Droppable>
                                                                    </Table>
                                                                </DragDropContext>
                                                                <Table>
                                                                    <TableBody>
                                                                        <TableRow style={{ color: 'white' }}>
                                                                            <TableCell>Mentor</TableCell>
                                                                            <TableCell>
                                                                                <NativeSelect
                                                                                    name="mentor"
                                                                                    onChange={onChange}
                                                                                    defaultValue={constraints.mentor ? constraints.mentor : 1}
                                                                                >
                                                                                    <option value="1">At least one mentor per group</option>
                                                                                    <option value="0">Doesn't matter</option>
                                                                                </NativeSelect>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow style={{ color: 'white' }} key={'e4'}>
                                                                            <TableCell>Desired number of tables (*):</TableCell>
                                                                            <TableCell>
                                                                                <Input
                                                                                    name="table"
                                                                                    type="number"
                                                                                    onChange={onChange}
                                                                                    defaultValue={state['table']}
                                                                                    //
                                                                                />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    </Grid>
                                                    <br />
                                                    <ButtonGroup
                                                        fullWidth
                                                        size="large"
                                                        variant="outlined"
                                                        aria-label="large outlined primary button group"
                                                    >
                                                        <AnimateButton>
                                                            <Button
                                                                disableElevation
                                                                fullWidth
                                                                size="large"
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={handleSubmit}
                                                                ////
                                                            >
                                                                <Diversity2Icon />
                                                                Build Team
                                                            </Button>
                                                        </AnimateButton>
                                                        <AnimateButton>
                                                            <Button
                                                                disableElevation
                                                                fullWidth
                                                                size="large"
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={handleDuplicatePreviousRound}
                                                                ////
                                                            >
                                                                <ContentCopyIcon />
                                                                Duplicate Previous Round
                                                            </Button>
                                                        </AnimateButton>
                                                        <AnimateButton>
                                                            <Button
                                                                disableElevation
                                                                fullWidth
                                                                size="large"
                                                                variant="outlined"
                                                                color="error"
                                                                onClick={handleCancelNewRound}
                                                                ////
                                                            >
                                                                <ClearIcon />
                                                                Cancel
                                                            </Button>
                                                        </AnimateButton>
                                                    </ButtonGroup>
                                                </Collapse>
                                            )}
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div>
                                            <Box
                                                sx={{ border: '1px solid grey', borderColor: 'primary.main' }}
                                                alignItems="center"
                                                margin="auto"
                                                padding="10px"
                                                //
                                            >
                                                <h5>
                                                    Round {i + 1}:
                                                    {role == '1' && (
                                                        <Button
                                                            variant="contained"
                                                            startIcon={<DeleteIcon />}
                                                            color="error"
                                                            sx={{ textAlign: 'center', display: 'block', marginLeft: 'auto' }}
                                                            onClick={() => handleDeleteRound(i)}
                                                        >
                                                            Delete Round
                                                        </Button>
                                                    )}
                                                </h5>
                                                {stakeholder_list[i].length > 0 ?
                                                    (<Stack direction="row" mt={2} mb={5}>
                                                        Stakeholders: {selectedParticipantsArray[i]?.map((value, index) => {
                                                                                                    if (value != null && value.role === 'Stakeholder') {
                                                                                                        return (<Chip sx={{ ml:2, mr:2 }} size="small" label={value.id + ' - ' + value.name} />)}})}
                                                    </Stack>) : null }
                                                <Container sx={{ bgcolor: '' }}>
                                                    <Grid container spacing={2}>
                                                        {tables?.map((table, tableIndex) => (
                                                            <Grid
                                                                item
                                                                xs={3}
                                                                className="table borderd"
                                                                // style={getListStyle(snapshot.isDraggingOver)}
                                                                /////////////////////////////
                                                            >
                                                                <ListItemText
                                                                    primary={`Table ${tableIndex + 1}`}
                                                                    primaryTypographyProps={{
                                                                        color: 'black',
                                                                        fontWeight: 600,
                                                                        variant: 'body1'
                                                                    }}
                                                                />
                                                                {table.map((user, index) => (
                                                                    <List
                                                                        sx={{
                                                                            width: '100%',
                                                                            maxWidth: 360,
                                                                            height: 50,
                                                                            bgcolor: 'background.paper'
                                                                        }}
                                                                    >
                                                                        <ListItem>
                                                                            {selectedParticipantsArray[i]?.map((value, index) => {
                                                                                if (value != null && value.id == user) {
                                                                                    // return <ListItemText primary={value.id + ' - ' + value.name} />;
                                                                                    return (
                                                                                        <ListItemText
                                                                                            primaryTypographyProps={{
                                                                                                style:
                                                                                                    value.role === 'Mentor'
                                                                                                        ? { backgroundColor: 'yellow' }
                                                                                                        : null
                                                                                            }}
                                                                                            primary={value.id + ' - ' + value.name}
                                                                                        />
                                                                                    );
                                                                                }
                                                                            })}
                                                                        </ListItem>
                                                                    </List>
                                                                ))}
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                    <br />
                                                    <Typography variant="h5" onClick={() => setExpanded(!expanded)}>
                                                        <Tooltip title="Click/Unclick to Show/Hide tables in details.">
                                                            <Button>Show/Hide Table Details</Button>
                                                        </Tooltip>
                                                    </Typography>
                                                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                                                        <Grid>
                                                            {tables.map((table, index) => (
                                                                <TableContainer
                                                                    sx={{
                                                                        maxHeight: 440,
                                                                        border: '2px dashed grey',
                                                                        borderColor: 'primary.main'
                                                                    }}
                                                                >
                                                                    <Typography variant="h5" component="h6">
                                                                        Table {index + 1}:
                                                                    </Typography>
                                                                    <Table key={index} className="table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                {tableRows.map((rows, index) => {
                                                                                    return <TableCell key={index}>{rows}</TableCell>;
                                                                                })}
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        {table.map((user) => (
                                                                            <TableBody>
                                                                                {selectedParticipantsArray[i]?.map((row, index) => {
                                                                                    if (row != null && row.id == user) {
                                                                                        return (
                                                                                            <TableRow key={index}>
                                                                                                <TableCell>{row.id}</TableCell>
                                                                                                <TableCell>{row.email}</TableCell>
                                                                                                <TableCell>{row.name}</TableCell>
                                                                                                <TableCell>{row.gender}</TableCell>
                                                                                                <TableCell>
                                                                                                    {row['current position']}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {row['department or class']}
                                                                                                </TableCell>
                                                                                                <TableCell>
                                                                                                    {row['company or school']}
                                                                                                </TableCell>
                                                                                                <TableCell>{row.role}</TableCell>
                                                                                                <TableCell>
                                                                                                    {row.skills.join(', ')}
                                                                                                </TableCell>
                                                                                                <TableCell>{row.dateJoined}</TableCell>
                                                                                            </TableRow>
                                                                                        );
                                                                                    }
                                                                                })}
                                                                            </TableBody>
                                                                        ))}
                                                                    </Table>
                                                                </TableContainer>
                                                            ))}
                                                        </Grid>
                                                    </Collapse>
                                                </Container>
                                                <br />
                                                <br />
                                            </Box>
                                            <br />
                                            <br />
                                            <br />
                                            {role == '1' && (
                                                <Button disabled={expandedNewRound === true} variant="contained" onClick={handleAddNewRound}>
                                                    Add New Round
                                                </Button>
                                            )}
                                            {role == '1' && (
                                                <Collapse in={expandedNewRound} timeout="auto" unmountOnExit>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={6}>
                                                            <InputLabel mt={10}>List of Selected Participants:</InputLabel>
                                                            <TableContainer
                                                                sx={{ maxHeight: 650, border: '2px dashed grey', borderColor: 'primary.main' }}
                                                            >
                                                                <MyTable data={selectedParticipants.length > 0 ? selectedParticipants : []} />
                                                            </TableContainer>
                                                            <br />
                                                            <Button>
                                                                <Link
                                                                    onClick={() => handleClickOpenConfirmDialog()}
                                                                    variant="body1"
                                                                    sx={{ textDecoration: 'underline' }}
                                                                    color="primary"
                                                                    // target="_blank"
                                                                    // rel="noopener"
                                                                >
                                                                    <IsoIcon />
                                                                    Re-select participants?
                                                                </Link>
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <InputLabel mt={10}>Constraints' Details:</InputLabel>
                                                            <TableContainer sx={{ border: '2px dashed grey', borderColor: 'primary.main' }}>
                                                                <DragDropContext onDragEnd={onDragEnd}>
                                                                    <Table className="table" sx={{ margin: 'auto' }}>
                                                                        <TableHead>
                                                                            <TableRow key={'c0'}>
                                                                                <TableCell>Constraint Name</TableCell>
                                                                                <TableCell>Value</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <Droppable droppableId="droppable-1" key="droppable-1">
                                                                            {(provider, snapshot) => (
                                                                                <TableBody
                                                                                    className="text-capitalize"
                                                                                    ref={provider.innerRef}
                                                                                    {...provider.droppableProps}
                                                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                                                    /////////////////////////////
                                                                                >
                                                                                    {users?.map((user, index) => (
                                                                                        <Draggable
                                                                                            draggableId={'' + user.id}
                                                                                            index={index}
                                                                                            key={'' + user.id}
                                                                                            //////////////////////
                                                                                        >
                                                                                            {(provider, snapshot) => (
                                                                                                <TableRow
                                                                                                    {...provider.draggableProps}
                                                                                                    {...provider.dragHandleProps}
                                                                                                    ref={provider.innerRef}
                                                                                                    style={getItemStyle(
                                                                                                        snapshot.isDragging,
                                                                                                        provider.draggableProps.style
                                                                                                        ///////////////////////
                                                                                                    )}
                                                                                                >
                                                                                                    <TableCell>{user.name}</TableCell>
                                                                                                    <TableCell>
                                                                                                        {user.id == 'activity-history' ? (
                                                                                                            <Select
                                                                                                                name={user.id}
                                                                                                                onChange={onChange}
                                                                                                                defaultValue={user.value}
                                                                                                            >
                                                                                                                <MenuItem value="0">
                                                                                                                    Ignore
                                                                                                                </MenuItem>
                                                                                                                <MenuItem value="3">
                                                                                                                    Don't overlap at all
                                                                                                                </MenuItem>
                                                                                                            </Select>
                                                                                                        ) : (
                                                                                                            <Select
                                                                                                                name={user.id}
                                                                                                                onChange={onChange}
                                                                                                                defaultValue={user.value}
                                                                                                            >
                                                                                                                <MenuItem value="0">
                                                                                                                    Ignore
                                                                                                                </MenuItem>
                                                                                                                <MenuItem value="1">
                                                                                                                    Common Only
                                                                                                                </MenuItem>
                                                                                                                <MenuItem value="2">
                                                                                                                    Diversity Only
                                                                                                                </MenuItem>
                                                                                                            </Select>
                                                                                                        )}
                                                                                                    </TableCell>
                                                                                                </TableRow>
                                                                                            )}
                                                                                        </Draggable>
                                                                                    ))}
                                                                                    {provider.placeholder}
                                                                                </TableBody>
                                                                            )}
                                                                        </Droppable>
                                                                    </Table>
                                                                </DragDropContext>
                                                                <Table>
                                                                    <TableBody>
                                                                        <TableRow style={{ color: 'white' }}>
                                                                            <TableCell>Mentor</TableCell>
                                                                            <TableCell>
                                                                                <NativeSelect
                                                                                    name="mentor"
                                                                                    onChange={onChange}
                                                                                    defaultValue={constraints.mentor ? constraints.mentor : 1}
                                                                                >
                                                                                    <option value="1">At least one mentor per group</option>
                                                                                    <option value="0">Doesn't matter</option>
                                                                                </NativeSelect>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow style={{ color: 'white' }} key={'e4'}>
                                                                            <TableCell>Desired number of tables (*):</TableCell>
                                                                            <TableCell>
                                                                                <Input
                                                                                    name="table"
                                                                                    type="number"
                                                                                    onChange={onChange}
                                                                                    defaultValue={state['table']}
                                                                                    //
                                                                                />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    </Grid>
                                                    <br />
                                                    <ButtonGroup
                                                        fullWidth
                                                        size="large"
                                                        variant="outlined"
                                                        aria-label="large outlined primary button group"
                                                    >
                                                        <AnimateButton>
                                                            <Button
                                                                disableElevation
                                                                fullWidth
                                                                size="large"
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={handleSubmit}
                                                                ////
                                                            >
                                                                <Diversity2Icon />
                                                                Build Team
                                                            </Button>
                                                        </AnimateButton>
                                                        |
                                                        <AnimateButton>
                                                            <Button
                                                                disableElevation
                                                                fullWidth
                                                                size="large"
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={handleDuplicatePreviousRound}
                                                                ////
                                                            >
                                                                <ContentCopyIcon />
                                                                Duplicate Previous Round
                                                            </Button>
                                                        </AnimateButton>
                                                        |
                                                        <AnimateButton>
                                                            <Button
                                                                disableElevation
                                                                fullWidth
                                                                size="large"
                                                                variant="outlined"
                                                                color="error"
                                                                onClick={handleCancelNewRound}
                                                                // onClick={() => setExpandedNewRound(!expandedNewRound)}
                                                                ////
                                                            >
                                                                <ClearIcon />
                                                                Cancel
                                                            </Button>
                                                        </AnimateButton>
                                                    </ButtonGroup>
                                                </Collapse>
                                            )}
                                        </div>
                                    );
                                }
                            } else {
                                return (
                                    <Box
                                        sx={{ border: '1px solid grey', borderColor: 'primary.main' }}
                                        alignItems="center"
                                        margin="auto"
                                        padding="10px"
                                        //
                                    >
                                        <h5>
                                            Round {i + 1}:
                                            {role == '1' && (
                                                <Button
                                                    variant="contained"
                                                    startIcon={<DeleteIcon />}
                                                    color="error"
                                                    sx={{ textAlign: 'center', display: 'block', marginLeft: 'auto' }}
                                                    onClick={() => handleDeleteRound(i)}
                                                >
                                                    Delete Round
                                                </Button>
                                            )}
                                        </h5>
                                        {stakeholder_list[i].length > 0 ?
                                            (<Stack direction="row" mt={2} mb={5}>
                                                Stakeholders: {selectedParticipantsArray[i]?.map((value, index) => {
                                                                                            if (value != null && value.role === 'Stakeholder') {
                                                                                                return (<Chip sx={{ ml:2, mr:2 }} size="small" label={value.id + ' - ' + value.name} />)}})}
                                            </Stack>) : null }
                                        <Container sx={{ bgcolor: '' }}>
                                            <Grid container spacing={2}>
                                                {tables?.map((table, tableIndex) => (
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        className="table borderd"
                                                        // style={getListStyle(snapshot.isDraggingOver)}
                                                        /////////////////////////////
                                                    >
                                                        <ListItemText
                                                            primary={`Table ${tableIndex + 1}`}
                                                            primaryTypographyProps={{
                                                                color: 'black',
                                                                fontWeight: 600,
                                                                variant: 'body1'
                                                            }}
                                                        />
                                                        {table.map((user, index) => (
                                                            <List
                                                                sx={{
                                                                    width: '100%',
                                                                    maxWidth: 360,
                                                                    height: 50,
                                                                    bgcolor: 'background.paper'
                                                                }}
                                                            >
                                                                <ListItem>
                                                                    {selectedParticipantsArray[i]?.map((value, index) => {
                                                                        if (value != null && value.id == user) {
                                                                            // return <ListItemText primary={value.id + ' - ' + value.name} />;
                                                                            return (
                                                                                <ListItemText
                                                                                    primaryTypographyProps={{
                                                                                        style:
                                                                                            value.role === 'Mentor'
                                                                                                ? { backgroundColor: 'yellow' }
                                                                                                : null
                                                                                    }}
                                                                                    primary={value.id + ' - ' + value.name}
                                                                                />
                                                                            );
                                                                        }
                                                                    })}
                                                                </ListItem>
                                                            </List>
                                                        ))}
                                                    </Grid>
                                                ))}
                                            </Grid>
                                            <br />
                                            <Typography variant="h5" onClick={() => setExpanded(!expanded)}>
                                                <Tooltip title="Click/Unclick to Show/Hide tables in details.">
                                                    <Button>Show/Hide Table Details</Button>
                                                </Tooltip>
                                            </Typography>
                                            <Collapse in={expanded} timeout="auto" unmountOnExit>
                                                <Grid>
                                                    {tables.map((table, index) => (
                                                        <TableContainer
                                                            sx={{
                                                                maxHeight: 440,
                                                                border: '2px dashed grey',
                                                                borderColor: 'primary.main'
                                                            }}
                                                        >
                                                            <Typography variant="h5" component="h6">
                                                                Table {index + 1}:
                                                            </Typography>
                                                            <Table key={index} className="table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        {tableRows.map((rows, index) => {
                                                                            return <TableCell key={index}>{rows}</TableCell>;
                                                                        })}
                                                                    </TableRow>
                                                                </TableHead>
                                                                {table.map((user) => (
                                                                    <TableBody>
                                                                        {selectedParticipantsArray[i]?.map((row, index) => {
                                                                            if (row != null && row.id == user) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell>{row.id}</TableCell>
                                                                                        <TableCell>{row.email}</TableCell>
                                                                                        <TableCell>{row.name}</TableCell>
                                                                                        <TableCell>{row.gender}</TableCell>
                                                                                        <TableCell>{row['current position']}</TableCell>
                                                                                        <TableCell>{row['department or class']}</TableCell>
                                                                                        <TableCell>{row['company or school']}</TableCell>
                                                                                        <TableCell>{row.role}</TableCell>
                                                                                        <TableCell>{row.skills.join(', ')}</TableCell>
                                                                                        <TableCell>{row.dateJoined}</TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            }
                                                                        })}
                                                                    </TableBody>
                                                                ))}
                                                            </Table>
                                                        </TableContainer>
                                                    ))}
                                                </Grid>
                                            </Collapse>
                                        </Container>
                                        <br />
                                        <br />
                                    </Box>
                                );
                            }
                        })}
                    </div>
                )}
            </Container>
        );
    }
}

export default TeamBuildingPage_Edit;
