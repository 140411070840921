/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    DataGrid,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    GridToolbarDensitySelector
} from '@mui/x-data-grid';
import UserService from 'services/user.service';
import {
    Box,
    Button,
    CircularProgress,
    Input,
    InputLabel,
    Stack,
    Typography,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    Radio,
    RadioGroup,
    FormGroup,
    Checkbox,
    FormLabel,
    FormControl,
    Autocomplete,
    TextField
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Drawer from '@mui/material/Drawer';
import HideSourceIcon from '@mui/icons-material/HideSource';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowModes,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons
    ///
} from '@mui/x-data-grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import DownloadIcon from '@mui/icons-material/Download';
import CheckIcon from '@mui/icons-material/Check';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import Axios from 'axios';
import AnimateButton from 'components/@extended/AnimateButton';
if (window.location.href.includes('pumas.activepeers.ai/')) {
    var backend_base_url = 'https://pumas.activepeers.ai/api';
} else {
    var backend_base_url = window.location.href.split(':')[0] + ':' + window.location.href.split(':')[1] + ':8001/api';
}

import { styled } from '@mui/material/styles';
const role = (localStorage.getItem('profile') && JSON.parse(localStorage.getItem('profile'))['role']) || 'guest';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    position: 'relative',
    right: 0
}));

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarQuickFilter />
            {/* <GridToolbarExport /> */}
        </GridToolbarContainer>
    );
}
const BoardAdmin = () => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    if (!currentUser) {
        navigate('/login');
    }

    const [errors, setErrorss] = useState('');

    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isFiltering, setIsFiltering] = useState(false); // State to track filtering
    const handleDownloadClick = () => {
        if (!selectedUserIds || selectedUserIds.length === 0) {
            alert('No user has been selected, please select user(s) you would like to export.');
            return;
        }

        Axios.post(
            `${backend_base_url}/export-query-to-csv/`,
            {
                selectedUserIds: selectedUserIds
            },
            {
                headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
                responseType: 'blob' // Specify the response type as blob
            }
        )
            .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    // Create a temporary URL for the blob
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    // Create a hidden <a> element to trigger the download
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'pumasUsers.csv'; // Specify the filename
                    document.body.appendChild(a);
                    a.click();
                    // Clean up the temporary URL and <a> element
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                } else {
                    // Handle non-2xx HTTP response status
                    console.error('Error downloading CSV:', response.statusText);
                }
            })
            .catch((error) => {
                console.error('Error downloading CSV:', error);
            });
    };

    const [rows, setRows] = React.useState([]);
    const [datagridRows, setDatagridRows] = useState([]);
    useEffect(() => {
        UserService.getAllUsers().then(
            (response) => {
                if (response.data['error']) {
                    setErrorss('An error occurred: ' + response.data['error'] + ' Please contact the administrator!');
                } else if (response.data['user_data']) {
                    const userData = response.data['user_data'];
                    //console.log('userData', userData);
                    setDatagridRows(userData);
                }
            },
            (error) => {
                const _content =
                    (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log(_content);
                // navigate('/login');
            }
        );
    }, []);
    const [rowModesModel, setRowModesModel] = React.useState({});

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };
    const handleEditClick = (id, role) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.Edit }
        });
    };
    const handleEditRowsModelChange = React.useCallback((model) => {
        setRowModesModel(model);
        setRows(model);
    }, []);
    const handleSaveClick = (id, role) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View }
        });
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true }
        });
    };
    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow };
        setRows(filteredData.map((row) => (row.id === newRow.id ? updatedRow : row)));
        let role = 0;
        if (newRow.role == 'Participant') {
            role = 3;
        } else if (newRow.role == 'Mentor') {
            role = 4;
        } else if (newRow.role == 'Stakeholder') {
            role = 2;
        } else if (newRow.role == 'Admin') {
            role = 1;
        }
        const payload = { userId: newRow.id, roleId: role };
        Axios.post(`${backend_base_url}/update_role/`, payload, {
            headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
        })
            .then((response) => {
                if (response) {
                    if (response['data']) {
                        if (response['data']['response']) {
                            if (response['data']['response'] == 'error') {
                                return setErrors([
                                    'An error occurred (' + response['data']['message'] + ') and we could not save the data.'
                                ]);
                            } else {
                                alert('User Role has been updated successfully.');
                                try {
                                    UserService.get_airtable_recordId(newRow.id).then((id) => {
                                        UserService.airtableBase(process.env.REACT_APP_AIRTABLE_TABLE_ID).update(
                                            id,
                                            {
                                                ID: newRow.id,
                                                Role: newRow.role
                                            },
                                            { typecast: true },
                                            function (err, record) {
                                                if (err) {
                                                    console.error(err);
                                                    return;
                                                }
                                                console.log(record.get('ID'));
                                                window.location.reload();
                                            }
                                        );
                                    });
                                } catch (error) {
                                    console.log('update error in Airtable: ', error);
                                    window.location.reload();
                                }
                            }
                        } else {
                            return setErrors(['An error occurred (' + response['data'] + ').']);
                        }
                    } else {
                        return setErrors(['An error occurred (' + response + ').']);
                    }
                } else {
                    return setErrors(['An error occurred (no response).']);
                }
            })
            .catch((error) => {
                // Handle error response
                console.error(error);
                return setErrors(['An error occurred during the email update. Please try again later.']);
            });
        return updatedRow;
    };
    //for delete confirm dialog
    const [openMap, setOpenMap] = React.useState(false);
    const [openMap_mul, setOpenMap_mul] = React.useState(false);
    const [openMap_mul_anonymise, setOpenMap_mul_anonymise] = React.useState(false);
    const handleClickOpenConfirmDialog = (id) => {
        // Open the dialog for the specific row (identified by id)
        setOpenMap((prevState) => ({ ...prevState, [id]: true }));
    };
    const handleClickOpenConfirmDialog_mul = () => {
        // Open the dialog for the specific row (identified by id)
        setOpenMap_mul(true);
    };
    const handleClickOpenConfirmDialog_mul_anonymise = () => {
        setOpenMap_mul_anonymise(true);
    };
    const handleClose = (id) => {
        // Close the dialog for the specific row (identified by id)
        setOpenMap((prevState) => ({ ...prevState, [id]: false }));
    };
    const handleClose_mul = () => {
        // Close the dialog for the specific row (identified by id)
        setOpenMap_mul(false);
    };
    const handleClose_mul_anonymise = () => {
        // Close the dialog for the specific row (identified by id)
        setOpenMap_mul_anonymise(false);
    };
    const handleDeleteConfirmed = (id) => {
        Axios.post(
            `${backend_base_url}/delete_user/${id}/`,
            {},
            {
                headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
            }
        )
            .then((response) => {
                if (response) {
                    if (response['data']) {
                        if (response['data']['response']) {
                            if (response['data']['response'] == 'error') {
                                alert('An error occurred (' + response['data']['message'] + ') and we could not delete the User.');
                            } else {
                                alert(`The user (id = ${id}) has been deleted successfully.`);
                                window.location.reload();
                                // try {
                                //     UserService.get_airtable_recordId(id).then((userid) => {
                                //         UserService.airtableBase(process.env.REACT_APP_AIRTABLE_TABLE_ID).destroy([userid], function (err, deletedRecords) {
                                //             if (err) {
                                //                 console.error(err);
                                //                 return;
                                //             }
                                //             console.log('Deleted', deletedRecords.length, 'records');
                                //             window.location.reload();
                                //         });
                                //     });
                                // } catch (error) {
                                //     console.log('delete error in Airtable: ', error);
                                //     window.location.reload();
                                // }
                            }
                        } else {
                            alert('An error occurred (' + response['data'] + ').');
                        }
                    } else {
                        alert('An error occurred (' + response + ').');
                    }
                } else {
                    alert('An error occurred (no response).');
                }
            })
            .catch((error) => {
                // Handle error response
                console.error(error);
                alert('An error occurred while deleting the User. Please try again later.');
            });
    };
    const handleDeleteConfirmed_mul = () => {
        Axios.post(
            `${backend_base_url}/delete_multiple_users/`,
            { selectedUserIds },
            {
                headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
            }
        )
            .then((response) => {
                if (response) {
                    if (response['data']) {
                        if (response['data']['response']) {
                            if (response['data']['response'] == 'error') {
                                alert('An error occurred (' + response['data']['message'] + ') and we could not delete selected users.');
                            } else {
                                alert(`The user (id: ${selectedUserIds}) has been deleted successfully.`);
                                window.location.reload();
                                // const deletePromises = selectedUserIds.map(async (id) => {
                                //     const userid = await UserService.get_airtable_recordId(id);
                                //     return await new Promise((resolve, reject) => {
                                //         UserService.airtableBase(process.env.REACT_APP_AIRTABLE_TABLE_ID).destroy([userid], function (err, deletedRecords) {
                                //             if (err) {
                                //                 console.error(err);
                                //                 reject(err);
                                //             } else {
                                //                 console.log('Deleted', deletedRecords.length, 'records');
                                //                 resolve();
                                //             }
                                //         });
                                //     });
                                // });
                                // Promise.all(deletePromises)
                                //     .then(() => {
                                //         console.log('All records deleted successfully');
                                //         window.location.reload();
                                //     })
                                //     .catch((error) => {
                                //         console.log('Error deleting records: ', error);
                                //         window.location.reload();
                                //         // Handle errors if needed
                                //     });
                            }
                        } else {
                            alert('An error occurred (' + response['data'] + ').');
                        }
                    } else {
                        alert('An error occurred (' + response + ').');
                    }
                } else {
                    alert('An error occurred (no response).');
                }
            })
            .catch((error) => {
                // Handle error response
                console.error(error);
                alert('An error occurred while deleting selected users. Please try again later.');
            });
    };
    const handleAnonymiseConfirmed_mul = () => {
        Axios.post(
            `${backend_base_url}/anonymise_multiple_users/`,
            { selectedUserIds },
            {
                headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
            }
        )
            .then((response) => {
                // console.log('anonymised_response: ' + JSON.stringify(response));
                //console.log(response['data']['response']);
                if (response) {
                    if (response['data']) {
                        if (response['data']['response']) {
                            if (response['data']['response'] == 'error') {
                                alert('An error occurred (' + response['data']['message'] + ') and we could not anonymise selected users.');
                            } else {
                                //window.location.reload();
                                alert(`The user (id: ${selectedUserIds}) has been anonymised successfully.`);
                                const anonymisePromises = selectedUserIds.map(async (id) => {
                                    try {
                                        const userid = await UserService.get_airtable_recordId(id);
                                        return await new Promise((resolve, reject) => {
                                            UserService.airtableBase(process.env.REACT_APP_AIRTABLE_TABLE_ID).update(
                                                userid,
                                                {
                                                    ID: id,
                                                    'Email': 'Anonymous' + id,
                                                    'First Name': 'Anonymous',
                                                    'Last Name': 'Anonymous',
                                                    'Current Position': 'Anonymous',
                                                    'Department or Group or Class': 'Anonymous',
                                                    'Is Active': false
                                                },
                                                { typecast: true },
                                                function (err, record) {
                                                    if (err) {
                                                        console.error(err);
                                                        reject(err);
                                                        //return;
                                                    }
                                                    //console.log(record.get('ID'));
                                                    console.log(`User ID ${id} anonymized successfully`);
                                                    resolve(record);
                                                    //window.location.reload();
                                                }
                                            );
                                        });
                                    } catch {
                                        console.error(`Error anonymizing user ID ${id}:`, error);
                                        //throw error; // Rethrow the error to be caught later
                                    }
                                });
                                Promise.all(anonymisePromises)
                                    .then(() => {
                                        console.log('All records anonymised successfully');
                                        window.location.reload();
                                    })
                                    .catch((error) => {
                                        console.log('Error anonymising records: ', error);
                                        window.location.reload();
                                    });
                            }
                        } else {
                            alert('An error occurred (' + response['data'] + ').');
                        }
                    } else {
                        alert('An error occurred (' + response + ').');
                    }
                } else {
                    alert('An error occurred (no response).');
                }
            })
            .catch((error) => {
                // Handle error response
                console.error(error);
                alert('An error occurred while anonymising selected user(s). Please try again later.');
            });
    };
    const columns = [
        { 
            field: 'id',
            headerName: 'ID',
            width: 70,
            type: 'number',
            valueGetter: ({ value }) => value.toLocaleString('en-US', { useGrouping: false })
        },
        {
            field: 'action',
            type: 'actions',
            headerName: 'Action',
            width: 100,
            cellClassName: 'actions',
            getActions: (params) => {
                const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main'
                            }}
                            onClick={handleSaveClick(params.id, params)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(params.id)}
                            color="inherit"
                        />
                    ];
                }
                return [
                    <>
                        <GridActionsCellItem
                            icon={<VisibilityIcon />}
                            label="View"
                            className="textPrimary"
                            href={`/view-smart-profile?id=${params.id}`}
                            color="primary"
                        />
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={() => handleClickOpenConfirmDialog(params.id)}
                            color="error"
                            ///
                        />
                        <Dialog
                            open={openMap[params.id] || false}
                            onClose={handleClose}
                            aria-labelledby={`alert-dialog-title-${params.id}`}
                            aria-describedby={`alert-dialog-description-${params.id}`}
                            BackdropProps={{
                                style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' }
                            }}
                        >
                            <DialogTitle id={`alert-dialog-title-${params.id}`}>
                                {'Are you sure you want to delete this account?'}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id={`alert-dialog-description-${params.id}`}>
                                    {params.id} - <b>{params.row.name}</b> - {params.row.email}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleClose(params.id)}>No</Button>
                                <Button
                                    color="error"
                                    onClick={() => {
                                        console.log('Delete button clicked for ID:', params.id);
                                        handleDeleteConfirmed(params.id);
                                    }}
                                >
                                    Yes, I'm sure.
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label="Edit Role"
                            className="textPrimary"
                            onClick={handleEditClick(params.id, params)}
                            color="warning"
                        />
                    </>
                ];
            }
        },
        {
            field: 'role',
            headerName: 'Role',
            width: 90,
            editable: true,
            type: 'singleSelect',
            valueOptions: ['Mentor', 'Participant', 'Stakeholder', 'Admin']
        },
        { field: 'email', headerName: 'Email', width: 230 },
        {
            field: 'name',
            headerName: 'Name',
            width: 130
        },
        {
            field: 'dateJoined',
            headerName: 'Date Joined',
            type: 'dateTime',
            valueGetter: ({ value }) => value && new Date(value),
            width: 190
        },
        {
            field: 'gender',
            headerName: 'Gender',
            width: 90,
            type: 'singleSelect',
            valueOptions: ['Male', 'Female', 'Non-binary', 'Other', 'Prefer not to say']
        },
        {
            field: 'current position',
            headerName: 'Current Position',
            width: 190
        },
        { field: 'department or class', headerName: 'Department or Class', width: 90 },
        {
            field: 'company or school',
            headerName: 'Company or School',
            width: 190
        },
        {
            field: 'skills',
            headerName: 'Skills',
            width: 290
        },
        {
            field: 'lastLogin',
            headerName: 'Last Login',
            type: 'dateTime',
            width: 190,
            valueGetter: ({ value }) => value && new Date(value)
        },
        { field: 'is_active', headerName: 'is Active', width: 70, type: 'boolen' },
    ];
    const columns_2 = [
        { 
            field: 'id',
            headerName: 'ID',
            width: 70,
            type: 'number',
            valueGetter: ({ value }) => value.toLocaleString('en-US', { useGrouping: false })
        },
        {
            field: 'action',
            type: 'actions',
            headerName: 'Action',
            width: 100,
            cellClassName: 'actions',
            getActions: (params) => {
                return [
                    <>
                        <GridActionsCellItem
                            icon={<VisibilityIcon />}
                            label="View"
                            className="textPrimary"
                            href={`/view-smart-profile?id=${params.id}`}
                            color="primary"
                        />
                    </>
                ];
            }
        },
        {
            field: 'role',
            headerName: 'Role',
            width: 90,
            editable: true,
            type: 'singleSelect',
            valueOptions: ['Mentor', 'Participant', 'Stakeholder', 'Admin']
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 130
        },
        {
            field: 'dateJoined',
            headerName: 'Date Joined',
            type: 'dateTime',
            valueGetter: ({ value }) => value && new Date(value),
            width: 190
        },
        {
            field: 'gender',
            headerName: 'Gender',
            width: 90,
            type: 'singleSelect',
            valueOptions: ['Male', 'Female', 'Non-binary', 'Other', 'Prefer not to say']
        },
        {
            field: 'current position',
            headerName: 'Current Position',
            width: 190
        },
        { field: 'department or class', headerName: 'Department or Class', width: 90 },
        {
            field: 'company or school',
            headerName: 'Company or School',
            width: 190
        },
        {
            field: 'skills',
            headerName: 'Skills',
            width: 290
        },
        {
            field: 'lastLogin',
            headerName: 'Last Login',
            type: 'dateTime',
            width: 190,
            valueGetter: ({ value }) => value && new Date(value)
        },
        { field: 'is_active', headerName: 'is Active', width: 70, type: 'boolen' },
    ];

    localStorage.getItem('selectedData', []);

    const handleSubmit = (event) => {
        event.preventDefault();
        navigate('/team-building');
    };
    //Search bar
    const initialDate = new Date('2023-04-01'); // Replace with your desired date
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    // Set end date to the end of the current date
    const endOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59);

    const [startDate, setStartDate] = useState(
        localStorage.getItem('startDate') ? new Date(localStorage.getItem('startDate')) : initialDate
    );
    const [endDate, setEndDate] = useState(localStorage.getItem('endDate') ? new Date(localStorage.getItem('endDate')) : endOfToday);
    const [state, setState] = useState({ userStatus:'active', roles: [], currentPositions: [], genders: [], companyOrSchool: [], departmentOrClass: [], skills: [] });
    const onChange = (e) => {
        const { name, value, checked, type } = e.target;
        // const newVal = type === 'checkbox1' ? checked : value;
        let newVal;
        if (type === 'checkbox') {
            if (value === 'any' && checked) {
                // If "Any Role" is checked, uncheck all other roles
                newVal = ['any'];
            } else if (checked) {
                // Add the checked role to the array
                newVal = [...state[name], value];
                // If "Any Role" was checked before, uncheck it
                newVal = newVal.filter(val => val !== 'any');
            } else {
                // Remove the unchecked role from the array
                newVal = state[name].filter(val => val !== value);
            }
        } else {
            newVal = value;
        }
        setState({
            ...state,
            [name]: newVal
        });
    };

    useEffect(() => {
        // Apply filters whenever data or filters change
        applyFilters();
    }, [datagridRows, startDate, endDate, state]);

    const applyFilters = () => {
        const filtered = datagridRows.filter((row) => {
            const isActive = row.is_active;
            const isWithinDateRange = (!startDate || new Date(row.dateJoined) >= startDate) && (!endDate || new Date(row.dateJoined) <= endDate);
            const isMatchingStatus = (state.userStatus === 'active' && isActive) || (state.userStatus === 'not active' && !isActive) || (state.userStatus === 'all');
            const isMatchingRole = state.roles.length === 0 || state.roles.includes('any') || state.roles.includes(row.role);
            const isMatchingGender = state.genders.length === 0 || state.genders.includes('any') || state.genders.includes(row.gender);
            const isMatchingCurrentPosition = state.currentPositions.length === 0 || state.currentPositions.includes(row.current_position);
            // const isMatchingGroup = state.departmentOrClass.length === 0 || state.departmentOrClass.includes(row.group);
            const isMatchingGroup = state.departmentOrClass.length === 0 || row.group.toLowerCase().includes(state.departmentOrClass);
            const isMatchingCompanyOrSchool = state.companyOrSchool.length === 0 || row.company_or_school.toLowerCase().includes(state.companyOrSchool);
            const isMatchingSkill = state.skills.length === 0 || row.strong_skills.some(skill => state.skills.includes(skill.skill));
            return isWithinDateRange && isMatchingStatus && isMatchingRole && isMatchingGender && isMatchingCurrentPosition && isMatchingGroup && isMatchingCompanyOrSchool && isMatchingSkill;
        });
        setFilteredData(filtered);
        setIsFiltering(true);
    };
    useEffect(() => {
        // Apply filters whenever data or filters change
        if (isFiltering) {
            setIsFiltering(false);
        }
    }, [filteredData]);

    // const filteredData = datagridRows.filter((row) => {
    //     // Filter by date range (if provided)
    //     let userStatus = state['userStatus'];
    //     const isActive = row.is_active;
    //     let roles = state['roles'];
    //     const isWithinDateRange =
    //         (!startDate || new Date(row.dateJoined) >= startDate) && (!endDate || new Date(row.dateJoined) <= endDate);
    //     const isMatchingStatus =
    //     (userStatus === 'active' && isActive) || (userStatus === 'not active' && !isActive) || (userStatus === 'all' && (!isActive || isActive));
    //     const isMatchingRole =
    //         roles.length === 0 || roles.includes('any') || roles.includes(row.role);
    //     const isMatchingGender =
    //         state['genders'].length === 0 || state['genders'].includes('any') || state['genders'].includes(row.gender);
    //     const isMatchingCurrentPosition = 
    //         state['currentPositions'].length === 0 || state['currentPositions'].includes(row.current_position);
    //     const isMatchingGroup = 
    //         state['departmentOrClass'].length === 0 || state['departmentOrClass'].includes(row.group);
    //     const isMatchingCompanyOrSchool = 
    //         state['companyOrSchool'].length === 0 || state['companyOrSchool'].includes(row.company_or_school);
    //     const isMatchingSkill = 
    //         state['skills'].length === 0 || row.strong_skills.some(skill => state['skills'].includes(skill.skill));
    //     return isWithinDateRange && isMatchingStatus && isMatchingRole && isMatchingGender && isMatchingCurrentPosition && isMatchingGroup && isMatchingCompanyOrSchool && isMatchingSkill;
    // });
    const onRowsSelectionHandler = (ids) => {
        // Determine the IDs to add and remove
        const newIds = ids.filter(id => !selectedUserIds.includes(id));
        let removedIds = [];
        if (!isFiltering) {
            removedIds = selectedUserIds.filter(id => !ids.includes(id));
        }
        // Update selectedUserIds
        const updatedSelectedUserIds = [
            ...selectedUserIds.filter(id => !removedIds.includes(id)),
            ...newIds
        ];

        // Update selectedUsers 
        const newSelectedRowsData = newIds.map(id => datagridRows.find(row => row.id === id));
        const updatedSelectedUsers = [
            ...selectedUsers.filter(user => !removedIds.map(id => id.toString()).includes(user.id)),
            ...newSelectedRowsData.map(participant => ({
                ...participant,
                id: participant.id.toString() // Convert id to string
            }))
        ];
        // Update the state with the new values
        setSelectedUserIds(updatedSelectedUserIds);
        setSelectedUsers(updatedSelectedUsers);

        // Store the updated lists in localStorage
        localStorage.setItem('selectedUserIds', JSON.stringify(updatedSelectedUserIds));
        localStorage.setItem('selectedData', JSON.stringify(updatedSelectedUsers));
    };
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
      setOpen(newOpen);
    };
    const onResetFilter = (e) => {
        setState({ userStatus:'active', roles: [], currentPositions: [], genders: [], companyOrSchool: [], departmentOrClass: [], skills: [] });
        setOpen(false);
    }
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const drawerWidth = isMobile ? '100%' : 500;
    if (errors) {
        return (
                <Container>
                    <br />
                    <Box sx={{ height: 640, width: '100%' }}>
                        <h5>Admin Board</h5>
                        <div style={{color: 'red'}}>{errors}</div>
                    </Box>
                </Container>
            )
    }
    if (datagridRows.length > 0 && filteredData && columns) {
        return (
            <Container>
                <br />
                <Box
                    sx={{ 
                        height: 640,
                        width: '100%',
                        mx: 'auto' // Center horizontally 
                    }}
                >
                    <h5>User List</h5>
                    <br></br>
                    <Dialog
                        open={openMap_mul || false}
                        onClose={handleClose_mul}
                        aria-labelledby={`alert-dialog-title`}
                        aria-describedby={`alert-dialog-description`}
                        BackdropProps={{
                            style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' }
                        }}
                    >
                        <DialogTitle id={`alert-dialog-title`}>{'Are you sure you want to delete these account below?'}</DialogTitle>
                        <DialogContent>
                            {selectedUsers.length > 0 ? (
                                selectedUsers.map((user) => (
                                    <DialogContentText id={`alert-dialog-description`}>
                                        {user.id} - <b>{user.name}</b> - {user.email}
                                    </DialogContentText>
                                ))
                            ) : (
                                <DialogContentText>No user selected for deletion.</DialogContentText>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleClose_mul()}>No</Button>
                            <Button
                                color="error"
                                onClick={() => handleDeleteConfirmed_mul()}
                                ///
                            >
                                Yes, I'm sure.
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={openMap_mul_anonymise || false}
                        onClose={handleClose_mul_anonymise}
                        aria-labelledby={`alert-dialog-title-anonymise`}
                        aria-describedby={`alert-dialog-description-anonymise`}
                        BackdropProps={{
                            style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' }
                        }}
                    >
                        <DialogTitle id={`alert-dialog-title-anonymise`}>
                            {'Are you sure you want to anonymise name and email addresses of these account below?'}
                        </DialogTitle>
                        <DialogContent>
                            {selectedUsers.length > 0 ? (
                                selectedUsers.map((user) => (
                                    <DialogContentText id={`alert-dialog-description-anonymise`}>
                                        {user.id} - <b>{user.name}</b> - {user.email}
                                    </DialogContentText>
                                ))
                            ) : (
                                <DialogContentText>No user selected for anonymization.</DialogContentText>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleClose_mul_anonymise()}>No</Button>
                            <Button
                                color="error"
                                onClick={() => handleAnonymiseConfirmed_mul()}
                                ///
                            >
                                Yes, I'm sure.
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={2}
                        justifyContent="flex-start" // Align items to the right
                        alignItems="flex-start" // Align items to the top
                        sx={{ width: '100%', maxWidth: '100%' }}
                    >
                        <Item>
                            <InputLabel htmlFor="startDate">From Date Joined:</InputLabel>
                            <Input
                                type="datetime-local"
                                id="startDate" // Associate this with the label
                                value={startDate?.toISOString()?.slice(0, -8)} // Format the date as "yyyy-MM-dd"
                                onChange={(e) => {
                                    setStartDate(new Date(e.target.value));
                                    localStorage.setItem('startDate', new Date(e.target.value).toISOString());
                                }}
                                //
                            />
                        </Item>
                        <Item>
                            <InputLabel htmlFor="endDate">To Date Joined:</InputLabel>
                            <Input
                                type="datetime-local"
                                id="endDate" // Associate this with the label
                                value={endDate?.toISOString()?.slice(0, -8)} // Format the date as "yyyy-MM-dd"
                                onChange={(e) => {
                                    setEndDate(new Date(e.target.value));
                                    localStorage.setItem('endDate', new Date(e.target.value).toISOString());
                                }}
                                ///
                            />
                        </Item>
                        <div>
                            <Button onClick={toggleDrawer(true)}>
                                Other Search Filters
                                <KeyboardDoubleArrowRightIcon />
                            </Button>
                            <Drawer
                                anchor="right"
                                open={open}
                                onClose={toggleDrawer(false)}
                                sx={{ pb: 5 }}
                            >
                                <Box sx={{ width: drawerWidth, pl: 3, pt: 10 }}>
                                    <Box
                                        sx={{ 
                                        display: 'flex', 
                                        justifyContent: 'space-between', 
                                        alignItems: 'center' 
                                        }}
                                    >
                                        <Typography variant="h5" gutterBottom>
                                            Other Search Filters
                                        </Typography>
                                        <Button onClick={toggleDrawer(false)}>
                                            <CancelIcon color='error' />
                                        </Button>
                                    </Box>
                                </Box>
                                <Stack
                                    direction={{ xs: 'column', sm: 'row' }}
                                    spacing={2}
                                    justifyContent="flex-end" // Align items to the right
                                    alignItems="flex-start" // Align items to the top
                                    sx={{ p: 3 }}
                                >
                                    <FormControl sx={{ width: 120 }} component="fieldset" variant="standard">
                                        <FormLabel component="legend">Status</FormLabel>
                                        <RadioGroup
                                            // row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="userStatus"
                                            value={state['userStatus']}
                                            onChange={onChange}
                                        >
                                            <FormControlLabel value="active" control={<Radio size="small" />} label="Active" />
                                            <FormControlLabel value="not active" control={<Radio size="small" />} label="Not Active" />
                                            <FormControlLabel value="all" control={<Radio size="small" />} label="All" />
                                        </RadioGroup>
                                    </FormControl>
                                    <FormControl sx={{ width: 150 }} component="fieldset" variant="standard">
                                        <FormLabel component="legend">Role</FormLabel>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox name="roles" size="small" value="any" checked={state.roles.includes('any')} onChange={onChange} />} label="Any Role" />
                                            <FormControlLabel control={<Checkbox name="roles" size="small" value="Mentor" checked={state.roles.includes('Mentor')} onChange={onChange} />} label="Mentor" />
                                            <FormControlLabel control={<Checkbox name="roles" size="small" value="Participant" checked={state.roles.includes('Participant')} onChange={onChange} />} label="Participant" />
                                            <FormControlLabel control={<Checkbox name="roles" size="small" value="Stakeholder" checked={state.roles.includes('Stakeholder')} onChange={onChange} />} label="Stakeholder" />
                                        </FormGroup>
                                    </FormControl>
                                    <FormControl sx={{ width: 160 }} component="fieldset" variant="standard">
                                        <FormLabel component="legend">Gender</FormLabel>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox size="small" value="any" name="genders" checked={state.genders.includes('any')} onChange={onChange} />} label="Any Gender" />
                                            <FormControlLabel control={<Checkbox size="small" value="Male" name="genders" checked={state.genders.includes('Male')} onChange={onChange} />} label="Male" />
                                            <FormControlLabel control={<Checkbox size="small" value="Female" name="genders" checked={state.genders.includes('Female')} onChange={onChange} />} label="Female" />
                                            <FormControlLabel control={<Checkbox size="small" value="Non-binary" name="genders" checked={state.genders.includes('Non-binary')} onChange={onChange} />} label="Non-binary" />
                                            <FormControlLabel control={<Checkbox size="small" value="Other" name="genders" checked={state.genders.includes('Other')} onChange={onChange} />} label="Other" />
                                            <FormControlLabel control={<Checkbox size="small" value="Prefer not to say" name="genders" checked={state.genders.includes('Prefer not to say')} onChange={onChange} />} label="Prefer not to say" />
                                        </FormGroup>
                                    </FormControl>
                                </Stack>
                                <Autocomplete
                                    multiple
                                    name="currentPositions"
                                    sx={{ mx: 3, my: 1, width: drawerWidth }}
                                    options={[...new Set(datagridRows.map(item => item.current_position))].sort()}
                                    onChange={(event, newValue) => {
                                        setState({
                                            ...state,
                                            ['currentPositions']: newValue
                                        })
                                    }}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Current Position"
                                            placeholder="Start typing and choose from the list"
                                        />
                                    )}
                                />
                                {/* <Autocomplete
                                    multiple
                                    id="group"
                                    options={[...new Set(datagridRows.map(item => item.group))].sort()}
                                    sx={{ mx: 3, my: 1, width: drawerWidth }}
                                    onChange={(event, newValue) => {
                                        setState({
                                            ...state,
                                            ['departmentOrClass']: newValue
                                        })
                                    }}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Department or Class"
                                            placeholder="Start typing and choose from the list"
                                        />
                                    )}
                                /> */}
                                <TextField
                                    id="group"
                                    label="Department or Class"
                                    placeholder="Start typing"
                                    sx={{ mx: 3, my: 1, width: drawerWidth }}
                                    value={state['departmentOrClass']}  // Controlled input value
                                    onChange={(event) => {
                                        const searchTerm = event.target.value.toLowerCase();
                                        setState({
                                            ...state,
                                            departmentOrClass: searchTerm
                                        });
                                    }}
                                />
                                {/* <Autocomplete
                                    multiple
                                    id="company_or_school"
                                    options={[...new Set(datagridRows.map(item => item.company_or_school))].sort()}
                                    sx={{ mx: 3, my: 1, width: drawerWidth }}
                                    onChange={(event, newValue) => {
                                        setState({
                                            ...state,
                                            ['companyOrSchool']: newValue
                                        })
                                    }}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Company or School"
                                            placeholder="Start typing and choose from the list"
                                        />
                                    )}
                                /> */}
                                <TextField
                                    id="company_or_school"
                                    label="Company or School"
                                    placeholder="Start typing"
                                    sx={{ mx: 3, my: 1, width: drawerWidth }}
                                    value={state['companyOrSchool']}
                                    onChange={(event) => {
                                        const searchTerm = event.target.value.toLowerCase();
                                        setState({
                                            ...state,
                                            companyOrSchool: searchTerm
                                        });
                                    }}
                                />
                                <Autocomplete
                                    multiple
                                    id="strong_skills"
                                    options={[...new Set(datagridRows.flatMap(item => item.strong_skills.map(skill => skill.skill)))].sort()}
                                    sx={{ mx: 3, my: 1, width: drawerWidth }}
                                    onChange={(event, newValue) => {
                                        setState({
                                            ...state,
                                            ['skills']: newValue
                                        })
                                    }}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Strong Skills"
                                            placeholder="Start typing and choose from the list"
                                        />
                                    )}
                                />
                                <br />
                                <Stack
                                    direction={{ xs: 'column', sm: 'row' }}
                                    spacing={2}
                                    justifyContent="flex-end" // Align items to the right
                                    alignItems="flex-start" // Align items to the top
                                    sx={{ p: 3 }}
                                >
                                    <AnimateButton>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={toggleDrawer(false)}
                                            size="small"
                                            //
                                        >
                                            <CheckIcon />
                                            Confirm
                                        </Button>
                                    </AnimateButton>
                                    <Button
                                        onClick={onResetFilter}
                                        variant="contained"
                                        color="error"
                                        size="small"
                                    >
                                        <CancelIcon />
                                            Discard
                                    </Button>
                                </Stack>
                            </Drawer>
                        </div>
                    </Stack>

                    {role === '1' && (
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={2}
                        justifyContent="flex-end" // Align items to the right
                        alignItems="flex-start" // Align items to the top
                        sx={{ pt: 1 }}
                    >
                        <AnimateButton>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleDownloadClick}
                                size="small"
                                //
                            >
                                <DownloadIcon sx={{ mr: 1 }} />
                                Download CSV
                            </Button>
                        </AnimateButton>

                        <AnimateButton>
                            <Button
                                disableElevation
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                size="small"
                                ////
                            >
                                <Diversity2Icon sx={{ mr: 1 }} />
                                Team Building
                            </Button>
                        </AnimateButton>

                        <AnimateButton>
                            <Button
                                variant="contained"
                                color="warning"
                                onClick={() => handleClickOpenConfirmDialog_mul_anonymise()}
                                size="small"
                                //
                            >
                                <HideSourceIcon sx={{ mr: 1 }} />
                                Anonymise
                            </Button>
                        </AnimateButton>

                        <AnimateButton>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => handleClickOpenConfirmDialog_mul()}
                                size="small"
                                //
                            >
                                <DeleteIcon sx={{ mr: 1 }} />
                                Delete
                            </Button>
                        </AnimateButton>
                    </Stack> )}
                    <br></br>
                    <DataGrid
                        rows={filteredData}
                        columns={role === '1' ? columns : columns_2}
                        onRowSelectionModelChange={onRowsSelectionHandler}
                        rowSelectionModel={selectedUserIds}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 30 }
                            }
                        }}
                        pageSizeOptions={[5, 10, 20, 30, 40, 50]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        editMode="row"
                        rowModesModel={rowModesModel}
                        onEditRowsModelChange={handleEditRowsModelChange}
                        onRowEditStop={handleRowEditStop}
                        onCellEditCommit={handleEditRowsModelChange}
                        processRowUpdate={processRowUpdate}
                        slots={{ toolbar: CustomToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true
                            }
                        }}
                    />
                </Box>
            </Container>
        );
    } else if ((role == 2 || role  == 4) && datagridRows.length == 0) {
        return (
            <Container>
                <br />
                <Box sx={{ height: 640, width: '100%' }}>
                    <h5>User List</h5>
                    <Typography>No data available!</Typography>
                </Box>
            </Container>
        );
    } else {
        return (
            <Container>
                <br />
                <Box sx={{ height: 640, width: '100%' }}>
                    <h5>User List</h5>
                    <CircularProgress color="primary" />
                    <Typography>Loading ...</Typography>
                </Box>
            </Container>
        );
    }
};

export default BoardAdmin;
