// import * as React from 'react';
import { TextareaAutosize } from '@mui/base';
// import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import React, { Component, useState, useRef, useEffect } from 'react';
import { useCallback } from 'react';
import UserService from 'services/user.service';
import {
    Alert,
    Box,
    Button,
    Link,
    Card,
    Fab,
    CardActions,
    CardContent,
    Container,
    Divider,
    FormControl,
    FormGroup,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    MenuItem,
    Select,
    NativeSelect,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TableFooter,
    TableContainer,
    TextField
} from '@mui/material';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios';

if (window.location.href.includes('pumas.activepeers.ai/')) {
    var backend_base_url = 'https://pumas.activepeers.ai/api';
} else {
    var backend_base_url = window.location.href.split(':')[0] + ':' + window.location.href.split(':')[1] + ':8001/api';
}
const steps = [
    {
        label: '',
        description: `Thank you for your participation in our event. We sincerely hope that your experience was enjoyable and valuable, just as it was for us to organize and host.`
    },
    {
        label: 'Experience Evaluation',
        description: 'In this section we will ask you to reflect on your experience.'
    },
    // {
    //     label: "Reviewing Mentees' Skills",
    //     description: `In this section, we prompt you to reflect on your mentees' growth and development.`
    // },
    {
        label: 'Putting Your Learning into Action',
        description: 'In this final section, reflect on how you will turn this learning into action.'
    }
];
const usefulness_questions = [
    'How satisfied were you with your overall experience as a mentor during this initiative?',
    'How effective do you feel your guidance and support were in helping your mentee(s) achieve their goals?',
    "To what extent did you feel equipped and prepared to provide valuable feedback and insights to enhance your mentee(s)' learning and development during the initiative?",
    "How well do you believe you understood your mentee(s)' needs and were able to tailor your guidance to meet their specific requirements?",
    'How likely are you to recommend being a mentor in similar initiatives to other professionals in your field?',
    'How did you feel the training and support offered by this initiative supported your own personal development?'
];
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
function SkillList(strongSkills) {
    if (!strongSkills) {
        return []; // Return an empty array if strongSkills is falsy (e.g., null, undefined)
    }
    return strongSkills.map((item) => ' ' + item.skill);
}

function cleanParticipants(participants, allUsers) {
    const allUserIds = allUsers.map((user) => user.id);
    return participants.filter((participant) => participant != null && allUserIds.includes(participant.id));
}
function cleanParticipants_withIds(participants, allUsers) {
    const allUserIds = allUsers.map((user) => user.id);
    return participants.filter((participant) => participant != null && allUserIds.includes(participant));
}

function updateTables(tables, indexMapping) {
    const mappedTables = tables.map((table) => table.map((row) => row.map((originalIndex) => indexMapping[originalIndex])));
    const filteredTables = mappedTables.map((round) => round.map((table) => table.filter((participant) => participant != null)));
    return filteredTables;
}
export const Feedback_mentor = () => {
    const navigate = useNavigate();
    const { user: currentUser } = useSelector((state) => state.auth);
    const location = useLocation();
    const prevLocation = encodeURIComponent(window.location.origin + location.pathname + location.search);
    const role = (localStorage.getItem('profile') && JSON.parse(localStorage.getItem('profile'))['role']) || 'guest';
    // alert(prevLocation);
    if (!currentUser) {
        navigate(`/login?redirectTo=${prevLocation}`);
    }
    const query = useQuery();
    // Get query parameters
    const project = parseInt(query.get('project'));
    const round = parseInt(query.get('round'));
    const id = parseInt(query.get('id'));
    if (isNaN(project) || isNaN(round)) {
        navigate('/');
    }
    if (currentUser.id != parseInt(id)) {
        navigate(`/login?redirectTo=${prevLocation}`);
    }
    //Get feedback data
    const [feedback_count, setFeedbackCount] = useState(0);
    useEffect(() => {
        UserService.getFeedbacks().then(
            (response) => {
                const feedback = JSON.parse(response.data.mentor_feedback_data).filter(
                    (feedback) => feedback.fields.project === project && feedback.fields.round === round
                );
                if (feedback.length > 0) {
                    setFeedbackCount(1);
                }
            },
            (error) => {
                const _events = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log('error2: ' + _events);
            }
        );
    }, []);
    console.log(project);
    const [isSubmitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState([]);
    const loading = false;

    const dispatch = useDispatch();
    const skill_list = UserService.skill_list;
    const rating_list = UserService.rating_list;
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = steps.length;

    const handleNext = () => {
        // if (activeStep == 2) {
        //     let dup_or_null = false;
        //     teammates_data.forEach((person, personIndex) => {
        //         const duplicateSkills_s = findDuplicateSkills(person.skills);
        //         const nullobjects_s = findObjectsWithNullSkillAndRate(person.skills);
        //         if (duplicateSkills_s != '') {
        //             alert('There is duplicate skill. Please re-check (teammate No: ' + (personIndex + 1) + ', name: ' + person.name + ').');
        //             dup_or_null = true;
        //         } else if (nullobjects_s != '') {
        //             alert(
        //                 'Skill/Rate has not been selected. Please re-check (teammate No: ' +
        //                     (personIndex + 1) +
        //                     ', name: ' +
        //                     person.name +
        //                     ').'
        //             );
        //             dup_or_null = true;
        //         }
        //     });
        //     if (dup_or_null == false) {
        //         setActiveStep((prevActiveStep) => prevActiveStep + 1);
        //     }
        // } else
        if (activeStep == 1) {
            let rate_null = false;
            // usefulnessRating_data.forEach((question, questionIndex) => {
            //     if (question.rate == '') {
            //         alert('Rate has not been selected. Please re-check (question: ' + question.question + ').');
            //         rate_null = true;
            //         return; // Exit the forEach loop
            //     }
            // });
            for (const question of usefulnessRating_data) {
                if (question.rate === '') {
                    alert(`Rate has not been selected. Please re-check (question: ${question.question}).`);
                    rate_null = true;
                    break; // Exit the loop immediately
                }
            }
            if (rate_null == false) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else {
                // Scroll to the top of the page
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const [strongSkills_data, setStrongSkill] = useState([]);
    const [interestSkills_data, setInterestSkill] = useState([]);
    const [roleId, setRole] = useState(3);
    //Get skill data
    useEffect(() => {
        UserService.getUserBoard().then(
            (response) => {
                setStrongSkill(response.data.profile.strong_skills);
                setInterestSkill(response.data.profile.interest_skills);
                setRole(response.data.profile.role);
            },
            (error) => {
                const _events = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                alert(_events);
            }
        );
    }, []);
    // console.log(strongSkills_data);
    const [content, setContent] = useState([]);

    //Get project data
    useEffect(() => {
        UserService.getProjects().then(
            (response) => {
                setContent(JSON.parse(response.data['project_data']));
                if (!JSON.parse(response.data['project_data']).find((p) => p.pk === project)) {
                    navigate('/');
                }
            },
            (error) => {
                const _content =
                    (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

                setContent(_content);
            }
        );
    }, []);
    //Get allUser
    const [allUsers, setAllUsers] = useState([]);
    useEffect(() => {
        UserService.getAdminBoard().then(
            (response) => {
                const userData = JSON.parse(response.data['user_data']);
                const profileData = JSON.parse(response.data['profiles_data']);
                const usersDictionary = userData.reduce((acc, value) => {
                    if (value.pk != '1') {
                        const profile = profileData.find((p) => p.fields.user === value.pk);
                        acc[value.pk] = {
                            email: value.fields.username,
                            name: value.fields.first_name + ' ' + value.fields.last_name,
                            gender: profile ? profile.fields.gender : '-',
                            'current position': profile ? profile.fields.current_position : '-',
                            'department or class': profile ? profile.fields.group : '-',
                            'company or school': profile ? profile.fields.company_or_school : '-',
                            role: profile ? profile.fields.role : '-',
                            skills: profile ? SkillList(profile.fields.strong_skills) : '-',
                            dateJoined: value.fields.date_joined,
                            lastLogin: value.fields.last_login
                        };
                    }
                    return acc;
                }, {});
                const usersArray = Object.entries(usersDictionary).map(([key, value]) => ({
                    id: key,
                    ...value
                }));
                const updatedUsersArray = usersArray.map((user) => {
                    if (user.role == '4') {
                        return { ...user, role: 'Mentor' };
                    } else if (user.role == '3') {
                        return { ...user, role: 'Participant' };
                    } else if (user.role == '2') {
                        return { ...user, role: 'Stakeholder' };
                    } else if (user.role == '1') {
                        return { ...user, role: 'Admin' };
                    }
                    return user;
                });
                setAllUsers(updatedUsersArray);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

                console.log('error: ', _content);
                // navigate('/login');
            }
        );
    }, []);
    const project_data = content.find((p) => p.pk === project);
    const [teammates_data, setTeamMates] = useState(null);
    //find teammates for current user.
    useEffect(() => {
        if (allUsers.length > 0 && project_data && round) {
            // console.log(project_data.fields.participants);
            //-----clean data ----
            // Tables based on indexes
            const grouping_results_original = project_data.fields.grouping_results;
            const participants_userId_original = project_data.fields.participants_userId;
            const grouping_results_userId_original = project_data.fields.grouping_results_userId;
            // Original list of participants
            const participants_original = project_data.fields.participants;
            // Remove null elements from the participants
            const participants = cleanParticipants(participants_original, allUsers);
            // Create a mapping between original indices and new indices
            const indexMapping = {};
            participants.forEach((participant, newIndex) => {
                indexMapping[participants_original.indexOf(participant)] = newIndex;
            });
            // Update the tables with the new indices
            const grouping_results = updateTables(grouping_results_original, indexMapping);
            // Update participants & results with Ids
            const participants_userId =
                participants_userId_original.length > 0
                    ? participants_userId_original.map((roundParticipants) => cleanParticipants_withIds(roundParticipants, allUsers))
                    : [];
            let updatedParticipants = [];
            if (participants_userId.length > 0) {
                updatedParticipants = participants_userId[round - 1].map((participantId) => {
                    const user = allUsers.find((user) => user.id === participantId);
                    return user ? user : null;
                });
            } else {
                updatedParticipants = participants
                    .map((p) => p.id)
                    .map((participantId) => {
                        const user = allUsers.find((user) => user.id === participantId);
                        return user ? user : null;
                    });
            }
            //find index of current user
            const userIndex = updatedParticipants.findIndex((user) => user.id == currentUser.id);
            // console.log(
            //     'userIndex: ' + userIndex + 'user_data: ' + currentUser.id + '&role: ' + project_data.fields.participants[userIndex].role
            // );
            if (userIndex === -1) {
                // Navigate to the root URL ('/')
                navigate('/');
            } else if (roleId != 4) {
                alert('You should be a mentor to access this form! Please check your role!');
                navigate('/');
            } else {
                const teammatesArr = [];
                let foundUserIndex = false; // Flag to check if userIndex is found
                grouping_results[round - 1].forEach((item) => {
                    // console.log(item);
                    item.forEach((i) => {
                        if (i === userIndex) {
                            // console.log('i: ' + i);
                            // console.log('teammates: ' + item.filter((teammate_i) => teammate_i != i));
                            teammatesArr.push(...item.filter((teammate_i) => teammate_i !== i));
                            const teammatesData = teammatesArr.map((teammate_i) => updatedParticipants[teammate_i]);
                            const teammates_data_transformed = (teammates_data) => {
                                return teammates_data.map((person) => ({
                                    id: person.id,
                                    name: person.name,
                                    email: person.email,
                                    role: person.role,
                                    gender: person.gender,
                                    current_position: person['current position'],
                                    company_or_school: person['company or school'],
                                    department_or_class: person['department_or_class'],
                                    dateJoined: person.dateJoined,
                                    skill_number_init: person.skills.filter((skill) => skill_list.includes(skill.trim())).length,
                                    skills: person.skills
                                        .filter((skill) => skill_list.includes(skill.trim()))
                                        .map((skill) => ({
                                            rate: '', // Set the initial rating to an empty string
                                            skill: skill
                                        })),
                                    comment1: '',
                                    comment2: '',
                                    comment3: ''
                                }));
                            };
                            setTeamMates(teammates_data_transformed(teammatesData));
                            // Set the flag to true when userIndex is found
                            foundUserIndex = true;
                        }
                    });
                });
                // Check if userIndex is not found, and handle the case
                if (!foundUserIndex) {
                    console.log('User not found in all rounds.');
                    navigate('/');
                }
            }
        } else {
            // navigate('/');
            console.log('Project/round not found.');
        }
    }, [project_data, round, currentUser.id, allUsers]);
    // console.log('teammates: ' + JSON.stringify(teammates_data));

    const [usefulnessRating_data, setUsefulnessRating] = useState(
        usefulness_questions.map((question) => ({
            question: question,
            rate: ''
        }))
    );
    // console.log(usefulnessRating_data);
    const handleRateChange_u = (itemIndex, newRate) => {
        setUsefulnessRating((prevData) => {
            const updatedData = prevData.map((item, index) => {
                if (index === itemIndex) {
                    return {
                        ...item,
                        rate: newRate
                    };
                }
                return item;
            });
            return updatedData;
        });
    };

    const handleDelete_t = (personIndex, skillIndex) => {
        setTeamMates((prevData) => {
            const updatedData = [...prevData];
            updatedData[personIndex].skills = updatedData[personIndex].skills.filter((_, index) => index !== skillIndex);
            return updatedData;
        });
    };
    const handleAddRow_t = (personIndex) => {
        setTeamMates((prevData) => {
            const updatedData = [...prevData];
            updatedData[personIndex].skills = [...updatedData[personIndex].skills, { skill: '', rate: '' }];
            return updatedData;
        });
    };
    const handleSkillChange_t = (personIndex, skillIndex, newValue) => {
        setTeamMates((prevData) => {
            const updatedData = [...prevData];
            updatedData[personIndex].skills[skillIndex].skill = newValue;
            return updatedData;
        });
    };
    const handleRateChange_t = (personIndex, skillIndex, newValue) => {
        setTeamMates((prevData) => {
            const updatedData = [...prevData];
            updatedData[personIndex].skills[skillIndex].rate = newValue;
            return updatedData;
        });
    };
    const handleComment1Change_t = (personIndex, newValue) => {
        setTeamMates((prevData) => {
            const updatedData = [...prevData];
            updatedData[personIndex].comment1 = newValue;
            return updatedData;
        });
    };
    const handleComment2Change_t = (personIndex, newValue) => {
        setTeamMates((prevData) => {
            const updatedData = [...prevData];
            updatedData[personIndex].comment2 = newValue;
            return updatedData;
        });
    };
    const handleComment3Change_t = (personIndex, newValue) => {
        setTeamMates((prevData) => {
            const updatedData = [...prevData];
            updatedData[personIndex].comment3 = newValue;
            return updatedData;
        });
    };
    const findDuplicateSkills = (data) => {
        const skillIndices = data.reduce((acc, item, index) => {
            const { skill } = item;
            if (!acc[skill]) {
                acc[skill] = [index];
            } else {
                acc[skill].push(index);
            }
            return acc;
        }, {});
        const duplicateSkills = Object.keys(skillIndices).filter((skill) => skillIndices[skill].length > 1);
        return duplicateSkills;
    };
    const findObjectsWithNullSkillAndRate = (data) => {
        const objectsWithNullSkillAndRate = data.filter(
            (item) => item.skill === '' || item.skill === null || item.rate === '' || item.rate === null
        );
        return objectsWithNullSkillAndRate;
    };
    const findObjectsWithNullQuestionOrRate = (data) => {
        const objectsWithNullQuestionOrRate = data.filter(
            (item) => item.question === '' || item.question === null || item.rate === '' || item.rate === null
        );
        return objectsWithNullQuestionOrRate;
    };

    const [feedback, setFeedback] = useState({
        assumptions: '',
        supports: '',
        actions: ''
    });
    // Update specific input field
    const handleChange = (e) => setFeedback((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));

    async function handleSubmit(event) {
        event.preventDefault();
        if (isSubmitting) {
            return;
        }
        setSubmitting(true);
        setErrors([]);
        if (errors.length > 0) {
            setSubmitting(false);
            return errors;
        }
        if (feedback['actions'] == '') {
            alert('Please give us your answer!');
            setSubmitting(false);
            return;
        }
        const teammates_data_transformed2 = (teammates_data) => {
            return teammates_data.map((person) => ({
                id: person.id,
                skills: person.skills,
                comment1: person.comment1,
                comment2: person.comment2,
                comment3: person.comment3
            }));
        };
        const payload = {
            project: project,
            round: round,
            usefulness_ratings: usefulnessRating_data,
            guidance_examples: feedback['guidance_examples'] ? feedback['guidance_examples'] : '',
            mentorship_strengths: feedback['mentorship_strengths'] ? feedback['mentorship_strengths'] : '',
            jobs_done: feedback['jobs_done'] ? feedback['jobs_done'] : '',
            challenges: feedback['challenges'] ? feedback['challenges'] : '',
            suggestions: feedback['suggestions'] ? feedback['suggestions'] : '',
            // mentees: teammates_data_transformed2(teammates_data), commented due to the related question removed
            mentees: [],
            actions: feedback['actions']
        };
        // console.log('user: ' + JSON.stringify(payload));
        Axios.post(`${backend_base_url}/mentor_feedbacks/create`, payload, {
            headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
        })
            .then((response) => {
                // console.log('response1: ' + response);
                if (response) {
                    if (response['data']) {
                        if (response['data']['response']) {
                            if (response['data']['response'] == 'error') {
                                // console.log('response1: ' + response['data']);
                                setSubmitting(false);
                                return setErrors([
                                    'An error occurred (' + response['data']['message'] + ') and we could not save the data.'
                                ]);
                            } else {
                                alert(
                                    'Your feedback has been submitted successfully. Now, check the feedback you have received from others in the "Event History" section of your Smart Profile.'
                                );
                                navigate('/event-history');
                            }
                        } else {
                            setSubmitting(false);
                            return setErrors(['An error occurred (' + response['data'] + ').']);
                        }
                    } else {
                        setSubmitting(false);
                        return setErrors(['An error occurred (' + response + ').']);
                    }
                } else {
                    setSubmitting(false);
                    return setErrors(['An error occurred (no response).']);
                }
            })
            .catch((error) => {
                // Handle error response
                console.error('Error while submitting feedback:', error);
                setSubmitting(false);
                return setErrors(['An error occurred while submitting the feedback. Please try again later.']);
            });
    }
    /// Render page
    // if (project_data && teammates_data && teammates_data != null) {
    if (feedback_count === 0 && project_data) {
        return (
            <Box sx={{ maxWidth: '100%', flexGrow: 1 }}>
                <h2>Time To Reflect, Review And Move Forward</h2>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{ bgcolor: 'primary.main', display: 'flex', justifyContent: 'center', padding: 0 }}>
                                <h4 style={{ color: 'white' }}>{steps[activeStep].label}</h4>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                sx={{
                                    backgroundColor: 'white',
                                    borderBottom: activeStep != 2 ? '2px solid primary.main' : null,
                                    borderColor: activeStep != 2 ? 'primary.main' : null
                                    //
                                }}
                            >
                                {steps[activeStep].description}
                            </TableCell>
                        </TableRow>
                        {/* {activeStep == 2 ? (
                            <TableRow>
                                <TableCell
                                    sx={{
                                        backgroundColor: 'white',
                                        borderBottom: '2px solid primary.main',
                                        borderColor: 'primary.main'
                                        //
                                    }}
                                >
                                    <b>Please note! Your mentees will see your comments below reflected on their SMART profiles.</b>
                                </TableCell>
                            </TableRow>
                        ) : null} */}
                    </TableBody>
                </Table>
                {/* {activeStep != 0 ? <hr style={{ color: '#14a085' }} /> : null} */}
                <form onSubmit={handleSubmit}>
                    <Box sx={{ height: '100%', maxWidth: '100%', width: '100%', p: 2 }}>
                        {activeStep == 0 && (
                            <div>
                                <br></br>
                                <b>Reflection offers a range of benefits:</b>
                                <ol>
                                    <li>Gain a profound understanding of your personal growth</li>
                                    <li>Improve interview readiness</li>
                                    <li>Encourage honest peer feedback</li>
                                    <li>Foster personal growth and development in others</li>
                                    <li>Identify areas for improvement</li>
                                    <li>Cultivate better team communication and collaboration</li>
                                </ol>
                            </div>
                        )}
                        {activeStep == 1 && (
                            <div>
                                <b>Q1. Rate the usefulness of this event in helping you reaching your goal. </b>
                                <span style={{ color: 'red' }}> (*)</span>
                                <br></br>
                                <div>
                                    <Table style={{ width: '80%', margin: 'auto' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Questions</TableCell>
                                                <TableCell>
                                                    Rate/5
                                                    <span style={{ color: 'red' }}> (*)</span>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {usefulnessRating_data.map((item, itemIndex) => (
                                                <TableRow key={itemIndex}>
                                                    <TableCell>{item.question}</TableCell>
                                                    <TableCell>
                                                        <NativeSelect
                                                            defaultValue={item.rate}
                                                            onChange={(e) => handleRateChange_u(itemIndex, e.target.value)}
                                                            // inputProps={{
                                                            //     name: 'rate_skill_s'
                                                            // }}
                                                        >
                                                            <option value="">Select</option>
                                                            <option value={1}>1 (Lowest)</option>
                                                            <option value={2}>2</option>
                                                            <option value={3}>3</option>
                                                            <option value={4}>4</option>
                                                            <option value={5}>5 (Highest)</option>
                                                        </NativeSelect>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                                <br></br>
                                <br></br>
                                <b>
                                    Q2. Can you provide specific examples where your guidance had a significant impact on your mentee(s)
                                    during the initiative?
                                </b>
                                <br></br>
                                <TextField
                                    sx={{ width: '100%' }}
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    name="guidance_examples"
                                    id="q2"
                                    type="text"
                                    placeholder="Your answer"
                                    inputProps={{ maxLength: 500 }}
                                    // inputRef={assumptionsRef}
                                    onChange={handleChange}
                                    //defaultValue={ }
                                />
                                <br></br>
                                <br></br>
                                <b>Q3. What did you consider to be the strengths of the mentorship program in this initiative?</b>
                                <br></br>
                                <TextField
                                    sx={{ width: '100%' }}
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    name="mentorship_strengths"
                                    id="q3"
                                    type="text"
                                    placeholder="Your answer"
                                    inputProps={{ maxLength: 500 }}
                                    // inputRef={assumptionsRef}
                                    onChange={handleChange}
                                    //defaultValue={feedback['assumptions']}
                                />
                                <br></br>
                                <br></br>
                                <b>Q4. How did you establish rapport and effective communication with your mentee(s)?</b>
                                <br></br>
                                <TextField
                                    sx={{ width: '100%' }}
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    name="jobs_done"
                                    id="q4"
                                    type="text"
                                    placeholder="Your answer"
                                    inputProps={{ maxLength: 500 }}
                                    // inputRef={assumptionsRef}
                                    onChange={handleChange}
                                    //defaultValue={feedback['assumptions']}
                                />
                                <br></br>
                                <br></br>
                                <b>Q5. Did you face any challenges as a mentor? If yes, how did you overcome them?</b>
                                <br></br>
                                <TextField
                                    sx={{ width: '100%' }}
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    name="challenges"
                                    id="q5"
                                    type="text"
                                    placeholder="Your answer"
                                    inputProps={{ maxLength: 500 }}
                                    // inputRef={assumptionsRef}
                                    onChange={handleChange}
                                    //defaultValue={feedback['assumptions']}
                                />
                                <br></br>
                                <br></br>
                                <b>Q6. What suggestions do you have to improve the mentorship experience in future initiatives?</b>
                                <br></br>
                                <TextField
                                    sx={{ width: '100%' }}
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    name="suggestions"
                                    id="q6"
                                    type="text"
                                    placeholder="Your answer"
                                    inputProps={{ maxLength: 500 }}
                                    // inputRef={assumptionsRef}
                                    onChange={handleChange}
                                    //defaultValue={feedback['assumptions']}
                                />
                            </div>
                        )}
                        {/* {activeStep == 2 && (
                            <div>
                                <br></br>
                                <b>Q7. How would you rate your mentees' proficiency in each of the skills listed on their Smart Profile?</b>
                                {teammates_data.map((teammate, teammateIndex) => (
                                    <div>
                                        <br></br>
                                        <b>
                                            {teammate.name} - {teammate.company_or_school != '' ? teammate.company_or_school : ''}
                                        </b>
                                        <TableContainer style={{ width: '80%', margin: 'auto' }}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>No</TableCell>
                                                        <TableCell>Skill</TableCell>
                                                        <TableCell>Rate/5</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {teammate.skills.map((skill, skillIndex) => (
                                                        <TableRow>
                                                            <TableCell>{skillIndex + 1}. </TableCell>
                                                            <TableCell>
                                                                <NativeSelect
                                                                    defaultValue={skill.skill.trim()}
                                                                    onChange={(e) =>
                                                                        handleSkillChange_t(teammateIndex, skillIndex, e.target.value)
                                                                    }
                                                                    disabled={skillIndex <= teammate.skill_number_init - 1} // Corrected disabled attribute
                                                                    // inputProps={{
                                                                    //     name: 'skill_i'
                                                                    // }}
                                                                >
                                                                    <option value="">Select</option>
                                                                    {skill_list.map((item, index) => (
                                                                        <option key={skillIndex + '-' + index} value={item}>
                                                                            {item}
                                                                        </option>
                                                                    ))}
                                                                </NativeSelect>
                                                            </TableCell>
                                                            <TableCell>
                                                                <NativeSelect
                                                                    defaultValue={skill.rate !== undefined ? skill.rate : ''}
                                                                    onChange={(e) =>
                                                                        handleRateChange_t(teammateIndex, skillIndex, e.target.value)
                                                                    }
                                                                    // inputProps={{
                                                                    //     name: 'rate_skill_i'
                                                                    // }}
                                                                >
                                                                    <option value="">Select</option>
                                                                    {rating_list.map((item, index) => (
                                                                        <option key={skillIndex + '-' + index} value={item}>
                                                                            {item}
                                                                        </option>
                                                                    ))}
                                                                </NativeSelect>
                                                            </TableCell>
                                                            <TableCell>
                                                                {skillIndex <= teammate.skill_number_init - 1 ? null : (
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="error"
                                                                        onClick={() => handleDelete_t(teammateIndex, skillIndex)}
                                                                        //
                                                                    >
                                                                        Delete
                                                                    </Button>
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                            <FormControl>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleAddRow_t(teammateIndex)}
                                                    sx={{ margin: '1em' }}
                                                    //
                                                >
                                                    Add row
                                                </Button>
                                            </FormControl>
                                            <br></br>
                                            <br></br>
                                            <b>Where have you seen evidence of their personal growth and development?</b>
                                            <TextField
                                                sx={{ width: '100%' }}
                                                multiline
                                                rows={4}
                                                variant="outlined"
                                                name={`comment1_${teammateIndex}`}
                                                id={`comment1_${teammateIndex}`}
                                                type="text"
                                                placeholder="Your answer"
                                                inputProps={{ maxLength: 500 }}
                                                onChange={(e) => handleComment1Change_t(teammateIndex, e.target.value)}
                                                //inputRef={actionRef}
                                                defaultValue={teammate.comment1}
                                            />
                                            <br></br>
                                            <br></br>
                                            <b>How can they develop these skills further?</b>
                                            <TextField
                                                sx={{ width: '100%' }}
                                                multiline
                                                rows={4}
                                                variant="outlined"
                                                name={`comment2_${teammateIndex}`}
                                                id={`comment2_${teammateIndex}`}
                                                type="text"
                                                placeholder="Your answer"
                                                inputProps={{ maxLength: 500 }}
                                                onChange={(e) => handleComment2Change_t(teammateIndex, e.target.value)}
                                                //inputRef={actionRef}
                                                defaultValue={teammate.comment2}
                                            />
                                            <br></br>
                                            <br></br>
                                            <b>
                                                What other skills have you seen them display, that they didn't put on their Smart Profile?
                                            </b>
                                            <TextField
                                                sx={{ width: '100%' }}
                                                multiline
                                                rows={4}
                                                variant="outlined"
                                                name={`comment3_${teammateIndex}`}
                                                id={`comment3_${teammateIndex}`}
                                                type="text"
                                                placeholder="Your answer"
                                                inputProps={{ maxLength: 500 }}
                                                onChange={(e) => handleComment3Change_t(teammateIndex, e.target.value)}
                                                //inputRef={actionRef}
                                                defaultValue={teammate.comment3}
                                            />
                                        </TableContainer>
                                    </div>
                                ))}
                            </div>
                        )} */}
                        {activeStep == 2 && (
                            <div>
                                <b>
                                    Q7. How will you apply the learning that you've taken from this experience? How can you build on this
                                    for your own and others' benefit?
                                </b>
                                <span style={{ color: 'red' }}> (*)</span>
                                <br></br>
                                <i style={{ color: '#14a085', margin: '1em' }}>
                                    Example: "Reflecting on this experience, I intend to further refine my guidance and transfer what I've
                                    learned from this into my day-to-day job. I'm also happy to share what I've learned with other mentors
                                    and to help foster a more collaborative culture."
                                </i>
                                <TextField
                                    sx={{ width: '100%' }}
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    name="actions"
                                    id="actions"
                                    type="text"
                                    placeholder="Your answer"
                                    inputProps={{ maxLength: 500 }}
                                    onChange={handleChange}
                                    //inputRef={actionRef}
                                    defaultValue={feedback['actions']}
                                />
                                <i>"The most effective way to do it, is to do it"</i>
                                <br></br>
                                <b>— Amelia Earhart</b>
                            </div>
                        )}
                    </Box>
                    <MobileStepper
                        variant="text"
                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}
                        nextButton={
                            <Button
                                size="small"
                                onClick={handleNext}
                                disabled={activeStep === maxSteps - 1}
                                variant="contained"
                                color="primary"
                                ///
                            >
                                Next
                                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                            </Button>
                        }
                        backButton={
                            <Button
                                size="small"
                                onClick={handleBack}
                                disabled={activeStep === 0}
                                variant="outlined"
                                //color="primary"
                            >
                                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                Back
                            </Button>
                        }
                    />
                    {errors.length > 0 ? (
                        <Alert severity="error" aria-live="assertive">
                            {errors.map((error, index) => {
                                return <p key={`alert-${index}`}>{error}</p>;
                            })}
                        </Alert>
                    ) : (
                        <></>
                    )}
                    {activeStep != maxSteps - 1 ? null : (
                        <FormControl style={{ display: 'flex', justifyContent: 'flex-end', mt: '4em', mb: '4em' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                // onClick={() => handleAddRow_t(teammateIndex)}
                                sx={{ margin: 'auto' }}
                                type="submit"
                                disabled={isSubmitting}
                                //
                            >
                                Submit
                            </Button>
                        </FormControl>
                    )}
                </form>
            </Box>
        );
    } else if (feedback_count > 0) {
        return (
            <Box>
                <h6>You've already submitted the feedback.</h6>
                <Link href={'/smart-profile'}>Back to Smart Profile</Link>
            </Box>
        );
    } else {
        return (
            <Box>
                <h6>Loading...</h6>
            </Box>
        );
    }
};
