// material-ui
import { Box, IconButton, Link, useMediaQuery, Typography, Tooltip } from '@mui/material';
import { GithubOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
// project import
import Search from './Search';
import Profile from './Profile';
import Notification from './Notification';
import MobileSection from './MobileSection';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const iconBackColorOpen = 'grey.300';
    const iconBackColor = 'grey.100';
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    return (
        <>
            {/* {!matchesXs && <Search />} */}
            {/* {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />} */}
            {<Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }} />}
            <Tooltip title="Contact Us Form" arrow>
                <Typography
                    variant="subtitle2"
                    component={Link}
                    href="https://airtable.com/appNm0943dsfrMAX9/shrwj4RjVSnpVvpin"
                    target="_blank"
                    underline="hover"
                    rel="noopener"
                >
                    <IconButton
                        disableRipple
                        color="secondary"
                        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
                        aria-label="open profile"
                        ref={anchorRef}
                        aria-controls={open ? 'profile-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                    >
                        <QuestionMarkIcon />
                    </IconButton>
                </Typography>
            </Tooltip>
            {/* <Tooltip title="Notification" arrow>
                <Typography
                    variant="subtitle2"
                    component={Link}
                    href="/notification"
                    //
                >
                    <NotificationsNoneIcon />
                </Typography>
            </Tooltip> */}
            <Notification />
            {!matchesXs && <Profile sx={{ justifyContent: 'flex-end' }} />}
            {matchesXs && <MobileSection />}
        </>
    );
};

export default HeaderContent;
