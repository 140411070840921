// material-ui
import { Box, IconButton, Link, useMediaQuery, Typography, Tooltip, Badge } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
// project import
import Profile from './Profile';
import Notification from './Notification';
import MobileSection from './MobileSection';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import MessageIcon from '@mui/icons-material/Message';
import { useNotifications } from 'pages/chat/NotificationContext';

// ==============================|| HEADER - CONTENT ||============================== //
const role = (localStorage.getItem('profile') && JSON.parse(localStorage.getItem('profile'))['role']) || 'guest';

const HeaderContent = () => {
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const iconBackColorOpen = 'grey.300';
    const iconBackColor = 'grey.100';
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const { unreadMessageCount } = useNotifications();
    useEffect(() => {
        console.log('Unread messages updated:', unreadMessageCount);
    }, [unreadMessageCount]);
    const handleRedirect_message = () => {
        const url = role === '1' || role === '2' ? '/team-building-history' : '/event-history';
        window.open(url, '_blank', 'noopener');
    };
    return (
        <>
            {<Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }} />}
            <Tooltip title="Contact Us Form" arrow>
                <Typography
                    variant="subtitle2"
                    component={Link}
                    href="https://airtable.com/appNm0943dsfrMAX9/shrwj4RjVSnpVvpin"
                    target="_blank"
                    underline="hover"
                    rel="noopener"
                >
                    <IconButton
                        disableRipple
                        color="secondary"
                        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
                        aria-label="open profile"
                        ref={anchorRef}
                        aria-controls={open ? 'profile-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                    >
                        <QuestionMarkIcon />
                    </IconButton>
                </Typography>
            </Tooltip>
            <Tooltip title="Message" arrow>
                <Box
                    sx={{ flexShrink: 0, ml: 0.75 }}
                    onClick={handleRedirect_message}
                    style={{ cursor: 'pointer' }} // Make the entire box clickable
                >
                    <IconButton
                        disableRipple
                        color="secondary"
                        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
                        aria-label="open message"
                        ref={anchorRef}
                        aria-controls={open ? 'profile-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                    >
                        <Badge
                            badgeContent={unreadMessageCount}
                            color="warning"
                            //
                        >
                            <MessageIcon />
                        </Badge>
                    </IconButton>
                </Box>
            </Tooltip>
            <Notification />
            {!matchesXs && <Profile sx={{ justifyContent: 'flex-end' }} />}
            {matchesXs && <MobileSection />}
        </>
    );
};

export default HeaderContent;
