import { lazy } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import GroupMatchingPage from 'pages/group-matching/GroupMatching';
import BoardAdmin from 'pages/admin/UserView';
import ReSelectUsers from 'pages/admin/ReSelectUsers';
import TeamBuildingPage from 'pages/group-matching/TeamBuilding';
import TeamBuildingPage_Edit from 'pages/group-matching/EditTeamBuildingProject';
import TeamBuildingPage_View from 'pages/group-matching/ViewTeamBuildingProject';
import BoardAdmin_TeamBuilding from 'pages/admin/TeamBuildingHistory';
import Email from 'email/meetingDetails';
import { Feedback } from 'pages/feedback/feedback';
import { Feedback_success } from 'pages/feedback/feedback_success';
import { Feedback_mentor } from 'pages/feedback/feedback_mentor';
// import LoginRoutes from './LoginRoutes';
import NotFoundPage from 'pages/NotFoundPage';
import ProfilePageView from 'pages/admin/SmartProfileView';
import Email_Template from 'pages/admin/EmailTemplate';
// import EmailLogTable from 'pages/admin/EmailLog';
// import EmailLogTable_AWS from 'pages/admin/EmailLogAWS';
// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));
// render - sample page
import GrapeJsEmailEdiorPage from 'pages/grapejs-newsletter-editor/EditorView';

// render - profile pages
const ProfilePage = Loadable(lazy(() => import('pages/smart-profile/SmartProfile')));
const EventHistoryPage = Loadable(lazy(() => import('pages/smart-profile/EventHistory')));

// render - feedback View
import { ViewFeedback } from 'pages/smart-profile/feedbackView';
import Notification from 'pages/smart-profile/Notification';
import { ViewFeedback_from_peers } from 'pages/smart-profile/feedbackView_from_peers';
import { ViewFeedback_mentor } from 'pages/smart-profile/mentorFeedbackView';
import { ParticipantList } from 'pages/smart-profile/ParticipantList';
const ChatRoom = Loadable(lazy(() => import('pages/chat/Chat')));
const Conversations = Loadable(lazy(() => import('pages/chat/ActiveConversations')));
const ChatArea = Loadable(lazy(() => import('pages/chat/ChatArea')));
const CompanyList = Loadable(lazy(() => import('pages/admin/CompanyList')));
const SchoolList = Loadable(lazy(() => import('pages/admin/SchoolList')));
//import { DisplayGraphs } from 'pages/visualisation/DisplayGraphs';
// const ViewFeedback = Loadable(lazy(() => import('pages/smart-profile/feedbackView')));
const VisualisationPage = Loadable(lazy(() => import('pages/visualisation/DisplayGraphs')));
const DataVisualisationPage = Loadable(lazy(() => import('pages/data-visualisation/DisplayGraphs')));
const DataVisualisationPage_PDF = Loadable(lazy(() => import('pages/data-visualisation/DisplayGraphs_PDFpreview')));
const NetworkGraph = Loadable(lazy(() => import('pages/data-graph/graph')));
const UpdateProfilePage = Loadable(lazy(() => import('pages/smart-profile/UpdateProfile')));
const UpdateProfilePage_details = Loadable(lazy(() => import('pages/smart-profile/UpdateProfile_details')));
const AccountSettings = Loadable(lazy(() => import('pages/smart-profile/AccountSettings')));
// render - utilities
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

// ==============================|| MAIN ROUTING ||============================== //
// Get the role from local storage or set a default value of "guest"
const RedirectToLogin = () => {
    const location = useLocation();
    const prevLocation = encodeURIComponent(window.location.origin + location.pathname + location.search);
    console.log(prevLocation);
    return <Navigate to={`/login?redirectTo=${prevLocation}`} />;
};
const role = (localStorage.getItem('profile') && JSON.parse(localStorage.getItem('profile'))['role']) || 'guest';
const MainRoutes =
    role === '1'
        ? {
              path: '/',
              element: <MainLayout />,
              children: [
                  {
                      path: '/',
                      // element: <DashboardDefault />
                      element: <BoardAdmin />
                  },
                  {
                      path: 'color',
                      element: <Color />
                  },
                  {
                      path: 'dashboard',
                      children: [
                          {
                              path: 'default',
                              element: <DashboardDefault />
                          }
                      ]
                  },
                  {
                      path: 'smart-profile',
                      element: <ProfilePage />
                  },
                  {
                      path: 'view-smart-profile',
                      element: <ProfilePageView />
                  },
                  {
                      path: 'review-feedback-mentor',
                      element: <ViewFeedback_mentor />
                  },
                  {
                      path: 'review-feedback',
                      element: <ViewFeedback />
                  },
                  {
                      path: 'feedback-from-peers',
                      element: <ViewFeedback_from_peers />
                  },
                  {
                      path: 'update-smart-profile',
                      element: <UpdateProfilePage />
                  },
                  {
                      path: 'update-account',
                      element: <AccountSettings />
                  },
                  {
                      path: 'update-profile-details',
                      element: <UpdateProfilePage_details />
                  },
                  {
                      path: 'group-matching',
                      element: <GroupMatchingPage />
                  },
                  {
                      path: 'all-users',
                      element: <BoardAdmin />
                  },
                  {
                      path: 'reselect-users',
                      element: <ReSelectUsers />
                  },
                  {
                      path: 'team-building',
                      element: <TeamBuildingPage />
                  },
                  {
                      path: 'editProject',
                      element: <TeamBuildingPage_Edit />
                  },
                  {
                      path: 'viewProject',
                      element: <TeamBuildingPage_View />
                  },
                  {
                      path: 'team-building-history',
                      element: <BoardAdmin_TeamBuilding />
                  },
                  {
                      path: 'email_template',
                      element: <Email_Template />
                  },
                  {
                      path: 'chat',
                      element: <ChatArea />
                  },
                  {
                      path: 'chatroom',
                      element: <ChatRoom />
                  },
                  {
                      path: 'conversations',
                      element: <Conversations />
                  },
                  {
                      path: 'companies',
                      element: <CompanyList />
                  },
                  {
                      path: 'schools',
                      element: <SchoolList />
                  },
                  //   {
                  //       path: 'email-logs',
                  //       element: <EmailLogTable />
                  //   },
                  //   {
                  //       path: 'ses/event-webhook/',
                  //       element: <EmailLogTable_AWS />
                  //   },
                  {
                      path: 'email-example',
                      element: <Email />
                  },
                  {
                      path: 'sample-page',
                      element: <SamplePage />
                  },
                  {
                      path: 'email-editor',
                      element: <GrapeJsEmailEdiorPage />
                  },
                  {
                      path: 'shadow',
                      element: <Shadow />
                  },
                  {
                      path: 'typography',
                      element: <Typography />
                  },
                  {
                      path: 'icons/ant',
                      element: <AntIcons />
                  },
                  {
                      path: 'feedback',
                      element: <Feedback />
                  },
                  {
                      path: 'feedback_submitted',
                      element: <Feedback_success />
                  },
                  {
                      path: 'feedback_mentor',
                      element: <Feedback_mentor />
                  },
                  {
                      path: 'participant_list',
                      element: <ParticipantList />
                  },
                  {
                      path: 'notification',
                      element: <Notification />
                  },
                  {
                      path: 'visualisation',
                      element: <VisualisationPage />
                  },
                  {
                      path: 'data-visualisation',
                      element: <DataVisualisationPage />
                  },
                  {
                      path: 'data-visualisation-pdf',
                      element: <DataVisualisationPage_PDF />
                  },
                  {
                      path: 'graph',
                      element: <NetworkGraph />
                  },
                  {
                      path: '*',
                      element: <NotFoundPage />
                  }
              ]
          }
        : role === '2'
        ? {
              path: '/',
              element: <MainLayout />,
              children: [
                  {
                      path: '/',
                      element: <ProfilePage />
                  },
                  {
                      path: 'smart-profile',
                      element: <ProfilePage />
                  },
                  {
                      path: 'event-history',
                      element: <EventHistoryPage />
                  },
                  {
                      path: 'review-feedback',
                      element: <ViewFeedback />
                  },
                  {
                      path: 'review-feedback-mentor',
                      element: <ViewFeedback_mentor />
                  },
                  {
                      path: 'feedback-from-peers',
                      element: <ViewFeedback_from_peers />
                  },
                  {
                      path: 'update-smart-profile',
                      element: <UpdateProfilePage />
                  },
                  {
                      path: 'update-account',
                      element: <AccountSettings />
                  },
                  {
                      path: 'update-profile-details',
                      element: <UpdateProfilePage_details />
                  },
                  {
                      path: 'feedback',
                      element: <Feedback />
                  },
                  {
                      path: 'feedback_submitted',
                      element: <Feedback_success />
                  },
                  {
                      path: 'feedback_mentor',
                      element: <Feedback_mentor />
                  },
                  {
                      path: 'participant_list',
                      element: <ParticipantList />
                  },
                  {
                      path: 'notification',
                      element: <Notification />
                  },
                  {
                      path: 'view-smart-profile',
                      element: <ProfilePageView />
                  },
                  {
                      path: 'review-feedback-mentor',
                      element: <ViewFeedback_mentor />
                  },
                  {
                      path: 'review-feedback',
                      element: <ViewFeedback />
                  },
                  {
                      path: 'feedback-from-peers',
                      element: <ViewFeedback_from_peers />
                  },
                  {
                      path: 'all-users',
                      element: <BoardAdmin />
                  },
                  {
                      path: 'editProject',
                      element: <TeamBuildingPage_Edit />
                  },
                  {
                      path: 'viewProject',
                      element: <TeamBuildingPage_View />
                  },
                  {
                      path: 'team-building-history',
                      element: <BoardAdmin_TeamBuilding />
                  },
                  {
                      path: 'participant_list',
                      element: <ParticipantList />
                  },
                  {
                      path: 'data-visualisation',
                      element: <DataVisualisationPage />
                  },
                  {
                      path: 'data-visualisation-pdf',
                      element: <DataVisualisationPage_PDF />
                  },
                  {
                      path: 'graph',
                      element: <NetworkGraph />
                  },
                  {
                      path: 'chatroom',
                      element: <ChatRoom />
                  },
                  {
                      path: 'conversations',
                      element: <Conversations />
                  },
                  {
                      path: '*',
                      element: <NotFoundPage />
                  }
              ]
          }
        : role === '3'
        ? {
              path: '/',
              element: <MainLayout />,
              children: [
                  {
                      path: '/',
                      // element: <DashboardDefault />
                      element: <ProfilePage />
                  },
                  {
                      path: 'smart-profile',
                      element: <ProfilePage />
                  },
                  {
                      path: 'event-history',
                      element: <EventHistoryPage />
                  },
                  {
                      path: 'review-feedback',
                      element: <ViewFeedback />
                  },
                  {
                      path: 'review-feedback-mentor',
                      element: <ViewFeedback_mentor />
                  },
                  {
                      path: 'feedback-from-peers',
                      element: <ViewFeedback_from_peers />
                  },
                  {
                      path: 'update-smart-profile',
                      element: <UpdateProfilePage />
                  },
                  {
                      path: 'update-account',
                      element: <AccountSettings />
                  },
                  {
                      path: 'update-profile-details',
                      element: <UpdateProfilePage_details />
                  },
                  {
                      path: 'feedback',
                      element: <Feedback />
                  },
                  {
                      path: 'feedback_submitted',
                      element: <Feedback_success />
                  },
                  {
                      path: 'feedback_mentor',
                      element: <Feedback_mentor />
                  },
                  {
                      path: 'participant_list',
                      element: <ParticipantList />
                  },
                  {
                      path: 'notification',
                      element: <Notification />
                  },
                  {
                      path: 'chatroom',
                      element: <ChatRoom />
                  },
                  {
                      path: 'conversations',
                      element: <Conversations />
                  },
                  {
                      path: '*',
                      element: <NotFoundPage />
                  }
              ]
          }
        : role === '4'
        ? {
              path: '/',
              element: <MainLayout />,
              children: [
                  {
                      path: '/',
                      // element: <DashboardDefault />
                      element: <ProfilePage />
                  },
                  {
                      path: 'smart-profile',
                      element: <ProfilePage />
                  },
                  //   {
                  //       path: 'all-users',
                  //       element: <BoardAdmin />
                  //   },
                  {
                      path: 'event-history',
                      element: <EventHistoryPage />
                  },
                  {
                      path: 'review-feedback',
                      element: <ViewFeedback />
                  },
                  {
                      path: 'review-feedback-mentor',
                      element: <ViewFeedback_mentor />
                  },
                  {
                      path: 'feedback-from-peers',
                      element: <ViewFeedback_from_peers />
                  },
                  {
                      path: 'update-smart-profile',
                      element: <UpdateProfilePage />
                  },
                  {
                      path: 'update-account',
                      element: <AccountSettings />
                  },
                  {
                      path: 'update-profile-details',
                      element: <UpdateProfilePage_details />
                  },
                  {
                      path: 'feedback',
                      element: <Feedback />
                  },
                  {
                      path: 'feedback_submitted',
                      element: <Feedback_success />
                  },
                  {
                      path: 'feedback_mentor',
                      element: <Feedback_mentor />
                  },
                  {
                      path: 'participant_list',
                      element: <ParticipantList />
                  },
                  {
                      path: 'notification',
                      element: <Notification />
                  },
                  {
                      path: 'view-smart-profile',
                      element: <ProfilePageView />
                  },
                  {
                      path: 'chatroom',
                      element: <ChatRoom />
                  },
                  {
                      path: 'conversations',
                      element: <Conversations />
                  },
                  {
                      path: '*',
                      element: <NotFoundPage />
                  }
              ]
          }
        : {
              path: '/',
              element: <RedirectToLogin />
              // default routes or routes for unknown roles
          };

export default MainRoutes;
