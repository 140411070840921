/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import UserService from 'services/user.service';
import { Email } from 'email/meetingDetails';
import { StakeholderNoti_MD } from 'email/stakeholderNotification_MD';
import { StakeholderNoti_FB } from 'email/stakeholderNotification_FB';
import { ReSelectRecipients } from './ReSelectRecipients';
import { FeedbackEmail } from 'email/feedbackDetails';
import { render } from '@react-email/render';
import {
    Box,
    Badge,
    Collapse,
    CircularProgress,
    Button,
    Stack,
    Tab,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    ButtonGroup,
    Chip,
    Grid,
    List,
    Divider,
    Link,
    ListItem,
    ListItemText,
    ListItemIcon,
    Typography,
    Tooltip,
    Container
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import DialogContentText from '@mui/material/DialogContentText';


import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import CircleIcon from '@mui/icons-material/Circle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AnimateButton from 'components/@extended/AnimateButton';
import DownloadIcon from '@mui/icons-material/Download';
import ForumIcon from '@mui/icons-material/Forum';

import { useNotifications } from 'pages/chat/NotificationContext';

import Axios from 'axios';

import { Email_mentor } from 'email/meetingDetails_mentor';
import { Email_Request } from 'email/emailRequestSubmitted';
import GrapeJsEmailEdiorPage from 'pages/grapejs-newsletter-editor/EditorView';

if (window.location.href.includes('pumas.activepeers.ai/')) {
    var backend_base_url = 'https://pumas.activepeers.ai/api';
} else {
    var backend_base_url = window.location.href.split(':')[0] + ':' + window.location.href.split(':')[1] + ':8001/api';
}
var base_url = window.location.href.split('/').slice(0, 3).join('/');
const role = (localStorage.getItem('profile') && JSON.parse(localStorage.getItem('profile'))['role']) || 'guest';
function cleanParticipants(participants) {
    return participants.filter((participant) => participant != null);
}
function updateTables(tables, indexMapping) {
    const mappedTables = tables.map((table) => table.map((row) => row.map((originalIndex) => indexMapping[originalIndex])));
    const filteredTables = mappedTables.map((round) => round.map((table) => table.filter((participant) => participant != null)));
    return filteredTables;
}
function FeedbackModal_selectedRound({ selectedRound, participants_list, message }) {
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = useState(null);
    const [success, setSuccess] = React.useState(false);
    const timer = React.useRef();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setLoading(false);
    };
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
    const [templateFetched, setTemplateFetched] = useState(false);
    const [template, setTemplate] = React.useState('');
    useEffect(() => {
        UserService.get_email_template(20)
            .then((response) => {
                setTemplate(response.data['template']);
                setTemplateFetched(true); // Mark the template as fetched
            })
            .catch((error) => {
                const _error = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            });
    }, []);
    const [templateFetched3, setTemplateFetched3] = useState(false);
    const [template3, setTemplate3] = React.useState('');
    useEffect(() => {
        UserService.get_email_template(23)
            .then((response) => {
                setTemplate3(response.data['template']);
                setTemplateFetched3(true); // Mark the template as fetched
            })
            .catch((error) => {
                const _error = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            });
    }, []);
    //render html
    const [emailHtml, setEmailHtml] = React.useState(render(<FeedbackEmail />));
    useEffect(() => {
        if (templateFetched) {
            if (template != '') {
                setEmailHtml(template);
            }
        }
    }, [templateFetched, template]);
    const [emailHtml_stakeholder, setEmailHtml_stakeholder] = React.useState(render(<StakeholderNoti_FB />));
    useEffect(() => {
        if (templateFetched3) {
            if (template != '') {
                setEmailHtml_stakeholder(template3);
            }
        }
    }, [templateFetched3, template3]);
    // Remove null elements from the participants
    const participants = cleanParticipants(participants_list);

    // Create a mapping between original indices and new indices
    const indexMapping = {};
    participants.forEach((participant, newIndex) => {
        indexMapping[participants_list.indexOf(participant)] = newIndex;
    });
    // Update the tables with the new indices
    const grouping_results = updateTables(selectedProject.fields.grouping_results, indexMapping);

    const [updatedParticipants, setUpdatedParticipants] = useState(participants);
    useEffect(() => {
        // Check if participants have actually changed before updating state
        if (JSON.stringify(updatedParticipants) !== JSON.stringify(participants)) {
            setUpdatedParticipants(participants);
        }
    }, [participants]);
    useEffect(() => {
        const mergeGroupNumbers = (participants, groupingResults) => {
            // Create a deep copy of the participants array to avoid mutating the original state
            let updatedParticipants = participants.map(participant => ({ ...participant, groupNumber: null }));
                if (Array.isArray(groupingResults[selectedRound - 1])) {
                    groupingResults[selectedRound - 1].forEach((group, groupNumber) => {
                        if (Array.isArray(group)) {
                            group.forEach(participantIndex => {
                                if (updatedParticipants[participantIndex]) {
                                    updatedParticipants[participantIndex].groupNumber = groupNumber + 1; // Adding 1 to make it 1-based index
                                }
                            });
                        }
                    });
            }
    
            return updatedParticipants;
        };
        setUpdatedParticipants(mergeGroupNumbers(participants, grouping_results));
    }, []);

    const [selectedRecipientIds, setSelectedRecipientIds] = useState(participants.map((row) => row.id));
    useEffect(() => {
        if (JSON.stringify(updatedParticipants) !== JSON.stringify(participants)) {
            // Using setTimeout to defer the state update until after the current rendering process
            setTimeout(() => {
                setSelectedRecipientIds(participants.map((row) => row.id));
            }, 0);
        }
    }, [participants]);
    //handleSubmit - Send Emails to participants
    const handleSubmit = (event) => {
        if (selectedRecipientIds.length === 0) {
            alert('Recipients not selected yet!');
        } else if (errors) {
            alert(errors);
        } else {
            setLoading(true); // Start the spinner
            Axios.post(
                `${backend_base_url}/send_email/`,
                {
                    data: {
                        subject: 'PUMAS Feedback',
                        txt_: 'PUMAS Feedback',
                        mentor_html_: savedFullHtml ? savedFullHtml :  emailHtml,
                        html_: savedFullHtml ? savedFullHtml :  emailHtml,
                        stakeholder_html_: savedFullHtml_stakeHolders ? savedFullHtml_stakeHolders :  emailHtml_stakeholder,
                        project: selectedProject.pk, //add project id
                        base_url: base_url, //add base url
                        round: [selectedRound],
                        grouping_results: grouping_results,
                        participants: updatedParticipants,
                        event_id: selectedProject.fields.title,
                        message_type: 2,
                        recipients: selectedRecipientIds
                    }
                },
                { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }
            )
                .then((response) => {
                    if (!loading) {
                        setSuccess(false);
                        setLoading(true);
                        timer.current = window.setTimeout(() => {
                            setSuccess(true);
                            setLoading(false);
                        }, 2000);
                    }
                    if (response && response.data && response.data.msg) {
                        alert(response.data.msg);
                    } else {
                        alert(response);
                    }
                })
                .catch((error) => {
                    // Handle specific error cases
                    if (error.response) {
                        // The request was made, but the server responded with a status code other than 2xx
                        setErrors('Error response: ' + JSON.stringify(error.response));
                        alert(
                            'An error occurred while sending emails to participants (' + error.response.data + '). Please try again later.'
                        );
                    } else if (error.request) {
                        setErrors('No response: ' + JSON.stringify(error.request));
                        alert('No response from the server. Please try again later.');
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.error('Request setup error:', + JSON.stringify(error));
                        setErrors('Request setup error: ' + JSON.stringify(error.message));
                        alert('An error occurred while setting up the request. Please try again later.');
                    }
                })
                .finally(() => {
                    setLoading(false); // Stop the spinner when the request is completed
                    setOpen(false);
                });
        }
    };
    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);
    const [savedFullHtml, setSavedFullHtml] = useState('');

    // Callback function to handle save template
    const handleSaveTemplate = (savedHtml) => {
        setSavedFullHtml(savedHtml);
    };
    const [savedFullHtml_stakeHolders, setSavedFullHtml_stakeHolders] = useState('');
    const handleSaveTemplate_stakeHolders = (savedHtml) => {
        setSavedFullHtml_stakeHolders(savedHtml);
    };
    if (participants.length > 0) {
        return (
            <React.Fragment>
                <AnimateButton>
                    <Button
                        disableElevation
                        size="large"
                        type="submit"
                        variant="contained"
                        color={message > 0 ? 'secondary' : 'primary'}
                        onClick={handleOpen}
                        ///
                    >
                        Complete (Feedback Email)
                    </Button>
                </AnimateButton>
                {loading && (
                    <CircularProgress
                        size={68}
                        sx={{
                            position: 'absolute',
                            top: -6,
                            left: -6,
                            zIndex: 1
                        }}
                    />
                )}
                <Dialog
                    open={open}
                    onClose={handleClose}
                    scroll={'paper'}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    fullScreen
                    // maxWidth="sm" // Adjust the maxWidth as needed
                >
                    <DialogTitle id="scroll-dialog-title">Complete (Feedback Email)</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500]
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Divider />
                    <DialogContent dividers={scroll === 'paper'}>
                        {!loading ? (
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                                            <Tab label="Email Template" value="1" />
                                            <Tab label="Recipients" value="2" />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1">
                                        *** FOR PARTICIPANTS and MENTORS
                                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                            <EmailEditor_at_one_time defaultTemplateId={20} templateName='Feedback Template for Participants and Mentors' onSave={handleSaveTemplate} />
                                        </Box>
                                        {savedFullHtml ? (
                                            <div dangerouslySetInnerHTML={{ __html: savedFullHtml }} />
                                        ) : (
                                            <div dangerouslySetInnerHTML={{ __html: emailHtml }} />
                                        )}
                                        *** FOR STAKEHOLDER
                                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                            <EmailEditor_at_one_time defaultTemplateId={23} templateName='Feedback Template for Stakeholders' onSave={handleSaveTemplate_stakeHolders} />
                                        </Box>
                                        {savedFullHtml_stakeHolders ? (
                                            <div dangerouslySetInnerHTML={{ __html: savedFullHtml_stakeHolders }} />
                                        ) : (
                                            <div dangerouslySetInnerHTML={{ __html: emailHtml_stakeholder }} />
                                        )}
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <ReSelectRecipients
                                            recipients={updatedParticipants}
                                            selectedRecipientIds={selectedRecipientIds}
                                            setSelectedRecipientIds={setSelectedRecipientIds}
                                        />
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        ) : (
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <CircularProgress />
                                <Typography>Please wait, this may take a few seconds...</Typography>
                            </Box>
                        )}
                    </DialogContent>
                    <DialogActions>
                        {loading ? (
                            'Sending'
                        ) : (
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    fullWidth
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                    ///
                                >
                                    Send Email
                                </Button>
                            </AnimateButton>
                        )}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

function EmailModal_selectedRound({ selectedRound, participants_list, message }) {
    const [open, setOpen] = React.useState(false);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = React.useState(false); // Add loading state
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setLoading(false);
    };
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
    const [templateFetched1, setTemplateFetched1] = useState(false);
    const [template1, setTemplate1] = React.useState(null);
    useEffect(() => {
        UserService.get_email_template(11)
            .then((response) => {
                setTemplate1(response.data['template']);
                setTemplateFetched1(true); // Mark the template as fetched
            })
            .catch((error) => {
                const _error = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            });
    }, []);
    const [templateFetched3, setTemplateFetched3] = useState(false);
    const [template3, setTemplate3] = React.useState(null);
    useEffect(() => {
        UserService.get_email_template(13)
            .then((response) => {
                // console.log('response', response.data['template']);
                setTemplate3(response.data['template']);
                setTemplateFetched3(true); // Mark the template as fetched
            })
            .catch((error) => {
                const _error = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                // console.log('_error', _error);
            });
    }, []);
    const [templateFetched, setTemplateFetched] = useState(false);
    const [template, setTemplate] = React.useState('');
    useEffect(() => {
        UserService.get_email_template(12)
            .then((response) => {
                // console.log('response', response.data['template']);
                setTemplate(response.data['template']);
                setTemplateFetched(true); // Mark the template as fetched
            })
            .catch((error) => {
                const _error = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                // console.log('_error', _error);
            });
    }, []);
    //render html
    const [emailHtml, setEmailHtml] = React.useState(render(<Email />));
    useEffect(() => {
        if (templateFetched1) {
            if (template1 != '') {
                setEmailHtml(template1);
            }
        }
    }, [templateFetched1, template1]);
    const [emailHtml_mentor, setEmailHtml_mentor] = React.useState(render(<Email_mentor />));
    useEffect(() => {
        if (templateFetched) {
            if (template != '') {
                setEmailHtml_mentor(template);
            }
        }
    }, [templateFetched, template]);
    const [emailHtml_stakeholder, setEmailHtml_stakeholder] = React.useState(render(<StakeholderNoti_MD />));
    useEffect(() => {
        if (templateFetched3) {
            if (template != '') {
                setEmailHtml_stakeholder(template3);
            }
        }
    }, [templateFetched3, template3]);
    // Remove null elements from the participants
    const participants = cleanParticipants(participants_list);
    // Create a mapping between original indices and new indices
    const indexMapping = {};
    participants.forEach((participant, newIndex) => {
        indexMapping[participants_list.indexOf(participant)] = newIndex;
    });
    // Update the tables with the new indices
    const grouping_results = updateTables(selectedProject.fields.grouping_results, indexMapping);
    const [updatedParticipants, setUpdatedParticipants] = useState(participants);
    useEffect(() => {
        // Check if participants have actually changed before updating state
        if (JSON.stringify(updatedParticipants) !== JSON.stringify(participants)) {
            setUpdatedParticipants(participants);
        }
    }, [participants]);
    useEffect(() => {
        const mergeGroupNumbers = (participants, groupingResults) => {
            // Create a deep copy of the participants array to avoid mutating the original state
            let updatedParticipants = participants.map(participant => ({ ...participant, groupNumber: null }));
                if (Array.isArray(groupingResults[selectedRound - 1])) {
                    groupingResults[selectedRound - 1].forEach((group, groupNumber) => {
                        if (Array.isArray(group)) {
                            group.forEach(participantIndex => {
                                if (updatedParticipants[participantIndex]) {
                                    updatedParticipants[participantIndex].groupNumber = groupNumber + 1; // Adding 1 to make it 1-based index
                                }
                            });
                        }
                    });
            }
    
            return updatedParticipants;
        };
        setUpdatedParticipants(mergeGroupNumbers(participants, grouping_results));
    }, []);
    const [selectedRecipientIds, setSelectedRecipientIds] = useState(participants.map((row) => row.id));
    useEffect(() => {
        if (JSON.stringify(updatedParticipants) !== JSON.stringify(participants)) {
            // Using setTimeout to defer the state update until after the current rendering process
            setTimeout(() => {
                setSelectedRecipientIds(participants.map((row) => row.id));
            }, 0);
        }
    }, [participants]);
    //handleSubmit - Send Emails to participants
    const handleSubmit = (event) => {
        if (selectedRecipientIds.length === 0) {
            alert('Recipients not selected yet!');
        } else if (errors) {
            alert(errors);
        } else {
            setLoading(true); // Start the spinner
            Axios.post(
                `${backend_base_url}/send_email/`,
                {
                    data: {
                        subject: 'PUMAS Team Building',
                        txt_: 'PUMAS Team Building',
                        html_: savedFullHtml ? savedFullHtml : emailHtml,
                        mentor_html_: savedFullHtml_mentors ? savedFullHtml_mentors : emailHtml_mentor,
                        stakeholder_html_: savedFullHtml_stakeHolders ? savedFullHtml_stakeHolders : emailHtml_stakeholder,
                        round: [selectedRound],
                        project: selectedProject.pk, //add project id
                        base_url: base_url, //add base url
                        grouping_results: grouping_results,
                        participants: updatedParticipants,
                        event_id: selectedProject.fields.title, // 0 - undefined
                            // event_data.length != 0
                            //     ? event_data['id'][0] +
                            //       ' - ' +
                            //       event_data['event_name'][0] +
                            //       ' - ' +
                            //       MillisecondsToDate(event_data['event_date'][0])
                            //     : 'Undefined Event',
                        message_type: 1,
                        recipients: selectedRecipientIds
                    }
                },
                { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }
            )
                .then((response) => {
                    // console.log(response.data);
                    alert(response?.data !== undefined ? response.data['msg'] : response);
                })
                .catch((error) => {
                    // Handle error response
                    setErrors('Error while sending email: ' + error);
                    alert('An error occurred while sending emails to participants (' + error + '). Please try again later.');
                })
                .finally(() => {
                    setLoading(false); // Stop the spinner when the request is completed
                    setOpen(false);
                });
        }
    };
    // Callback function to handle save template
    const [savedFullHtml, setSavedFullHtml] = useState('');
    const handleSaveTemplate = (savedHtml) => {
        setSavedFullHtml(savedHtml);
    };
    const [savedFullHtml_mentors, setSavedFullHtml_mentors] = useState('');
    const handleSaveTemplate_mentors = (savedHtml) => {
        setSavedFullHtml_mentors(savedHtml);
    };
    const [savedFullHtml_stakeHolders, setSavedFullHtml_stakeHolders] = useState('');
    const handleSaveTemplate_stakeHolders = (savedHtml) => {
        setSavedFullHtml_stakeHolders(savedHtml);
    };
    if (participants.length > 0) {
        return (
            <React.Fragment>
                <AnimateButton>
                    <Button
                        disableElevation
                        size="large"
                        type="submit"
                        variant="contained"
                        color={message > 0 ? 'secondary' : 'primary'}
                        onClick={handleOpen}
                        ///
                    >
                        Send Email (Meeting Details)
                    </Button>
                </AnimateButton>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    scroll={'paper'}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    // fullWidth
                    fullScreen
                    // minWidth="sm" // Adjust the maxWidth as needed
                >
                    <DialogTitle id="scroll-dialog-title">Send Email (Meeting Details)</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500]
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Divider />
                    <DialogContent dividers={scroll === 'paper'}>
                        {!loading ? (
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                                            <Tab label="Email Template" value="1" />
                                            <Tab label="Recipients" value="2" />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1">
                                        *** FOR PARTICIPANTS
                                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                            <EmailEditor_at_one_time defaultTemplateId={11} templateName='Meeting Detail Template for Participants' onSave={handleSaveTemplate} onClose={handleClose} />
                                        </Box>
                                        {/* {emailHtml ? <div dangerouslySetInnerHTML={{ __html: emailHtml }} /> : <Email />} */}
                                        {savedFullHtml ? <div dangerouslySetInnerHTML={{ __html: savedFullHtml }} /> : <div dangerouslySetInnerHTML={{ __html: emailHtml }} /> }
                                        *** FOR MENTORS
                                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                            <EmailEditor_at_one_time defaultTemplateId={12} templateName='Meeting Detail Template for Mentors' onSave={handleSaveTemplate_mentors} onClose={handleClose} />
                                        </Box>
                                        {/* {emailHtml_mentor ? <div dangerouslySetInnerHTML={{ __html: emailHtml_mentor }} /> : <Email_mentor />} */}
                                        {savedFullHtml_mentors ? <div dangerouslySetInnerHTML={{ __html: savedFullHtml_mentors }} /> : <div dangerouslySetInnerHTML={{ __html: emailHtml_mentor }} /> }
                                        *** FOR STAKEHOLDERS
                                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                            <EmailEditor_at_one_time defaultTemplateId={13} templateName='Meeting Detail Template for Stakeholders' onSave={handleSaveTemplate_stakeHolders} onClose={handleClose} />
                                        </Box>
                                        {/* {emailHtml_stakeholder ? <div dangerouslySetInnerHTML={{ __html: emailHtml_stakeholder }} /> : <StakeholderNoti_MD />} */}
                                        {savedFullHtml_stakeHolders ? <div dangerouslySetInnerHTML={{ __html: savedFullHtml_stakeHolders }} /> : <div dangerouslySetInnerHTML={{ __html: emailHtml_stakeholder }} /> }
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <ReSelectRecipients
                                            recipients={updatedParticipants}
                                            selectedRecipientIds={selectedRecipientIds}
                                            setSelectedRecipientIds={setSelectedRecipientIds}
                                        />
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        ) : (
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <CircularProgress />
                                <Typography>Please wait, this may take a few seconds...</Typography>
                            </Box>
                        )}
                    </DialogContent>
                    <DialogActions>
                        {loading ? (
                            'Sending'
                        ) : (
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    fullWidth
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                    ///
                                >
                                    Send Email
                                </Button>
                            </AnimateButton>
                        )}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}
function EmailEditor_at_one_time({ defaultTemplateId, templateName, onSave }) {
    const [loading, setLoading] = React.useState(false);
    const timer = React.useRef();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setLoading(false);
    };

    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);
    return (
        <React.Fragment>
            <AnimateButton>
                <Button
                    disableElevation
                    size="small"
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={handleOpen}
                    startIcon={<EditIcon />}
                    ///
                >
                    Edit
                </Button>
            </AnimateButton>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullScreen
                // maxWidth="sm" // Adjust the maxWidth as needed
            >
                <DialogTitle id="scroll-dialog-title">Email Editor - {templateName}</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Divider />
                <DialogContent dividers={scroll === 'paper'}>
                    {!loading ? (
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <GrapeJsEmailEdiorPage defaultTemplateId={defaultTemplateId} onSave={onSave} onClose={handleClose}/>
                        </Box>
                    ) : (
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <CircularProgress />
                            <Typography>Please wait, this may take a few seconds...</Typography>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
const ProjectList = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const projectId = searchParams.get('projectId'); // Get projectId from URL
    const [content, setContent] = useState([]);
    const [participantIdsArray, setParticipantIdsArray] = useState([]);
    const [participantsArray, setParticipantsArray] = useState([]);
    const { user: currentUser } = useSelector((state) => state.auth);
    useEffect(() => {
        UserService.getProjects().then(
            (response) => {
                setContent(JSON.parse(response.data['project_data']));
            },
            (error) => {
                const _content =
                    (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log('error1: ', _content);
            }
        );
    }, []);
    // useEffect(() => {
    //     if (projectId) {
    //         // Simulate the row click based on projectId
    //         const project = content.find((p) => p.pk === parseInt(projectId, 10));
    //         if (project) {
    //             handleRowClick({ row: { id: project.pk } }); // Trigger handleRowClick
    //         }
    //     }
    // }, [projectId, content]);
    const projectsDictionary = content.reduce((acc, value) => {
        acc[value.pk] = {
            id: value.pk,
            title: value.fields.title,
            created_date: value.fields.created_date,
            mentee: value.fields.participants.filter(
                (item) => (item != null && item.role == 'Mentee') || (item != null && item.role === 'Participant')
            ).length,
            mentor: value.fields.participants.filter((item) => item != null && item.role == 'Mentor').length,
            stakeholder_2: value.fields.participants.filter((item) => item != null && item.id == currentUser.id).length,
            stakeholder: value.fields.participants.filter((item) => item != null && item.role == 'Stakeholder').length,
            round: value.fields.grouping_results.length
        };

        return acc;
    }, {});
    const projectsArray = Object.entries(projectsDictionary).map(([key, value]) => ({
        id: key,
        ...value
    }));
    // Sort the array by id
    projectsArray.sort((a, b) => b.id - a.id);
    const projectsArray_2 = projectsArray.filter(item => item.stakeholder_2 > 0);
    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'title', headerName: 'Project Title', width: 200 },
        { 
            field: 'created_date',
            headerName: 'Created Date',
            type: 'dateTime',
            valueGetter: ({ value }) => value && new Date(value),
            width: 150 
        },
        { field: 'mentee', headerName: 'Participants', width: 80 },
        { field: 'mentor', headerName: 'Mentors', width: 80 },
        { field: 'stakeholder', headerName: 'Stakeholder', width: 100 },
        { field: 'round', headerName: 'Rounds', width: 80 },
        {
            field: 'edit',
            headerName: 'Edit',
            width: 50,
            renderCell: (params) => (
                <Link href={`/editProject?id=${params.id}`}>
                    <EditIcon />
                </Link>
            )
        },
        {
            field: 'delete',
            headerName: 'Delete',
            width: 50,
            renderCell: (params) => (
                <DeleteIcon 
                    color="error" 
                    onClick={() => {
                        const confirmed = window.confirm("Are you sure you want to delete this project?");
                        if (confirmed) {
                            handleDeleteConfirmed(params.id);
                        }
                    }} 
                    style={{ cursor: 'pointer' }}
                />
            )
            // renderCell: (params) => (
            //     <Link href={`/deleteProject?id=${params.id}`}>
            //         <DeleteIcon color="error" />
            //     </Link>
            // )
        },
        {
            field: 'visualisation',
            headerName: 'Visualisation',
            width: 100,
            renderCell: (params) => (
                <Link href={`/viewProject?id=${params.id}`}>
                    <VisibilityIcon />
                </Link>
            )
        },
        {
            field: 'chat',
            headerName: 'Chatroom',
            width: 100,
            renderCell: (params) => (
                <Badge
                    badgeContent={unreadConversationCount ? getUnreadMessageCount(unreadConversationCount, params.id) : 0}
                    color="warning"
                >
                    <ForumIcon color="primary" />
                </Badge>
            )
        }
    ];
    const handleDeleteConfirmed = (id) => {
        Axios.post(
            `${backend_base_url}/delete_project/${id}/`,
            {},
            {
                headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
            }
        )
            .then((response) => {
                if (response) {
                    if (response['data']) {
                        if (response['data']['response']) {
                            if (response['data']['response'] == 'error') {
                                alert('An error occurred (' + response['data']['message'] + ') and we could not delete the project.');
                            } else {
                                alert(`The project (id = ${id}) has been deleted successfully.`);
                                window.location.reload();
                            }
                        } else {
                            alert('An error occurred (' + response['data'] + ').');
                        }
                    } else {
                        alert('An error occurred (' + response + ').');
                    }
                } else {
                    alert('An error occurred (no response).');
                }
            })
            .catch((error) => {
                // Handle error response
                console.error(error);
                alert('An error occurred while deleting the project. Please try again later.');
            });
    };
    const columns_2 = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'title', headerName: 'Project Title', width: 200 },
        // { field: 'created_date', headerName: 'Created Date', width: 200 },
        { 
            field: 'created_date',
            headerName: 'Created Date',
            type: 'dateTime',
            valueGetter: ({ value }) => value && new Date(value),
            width: 150 
        },
        { field: 'mentee', headerName: 'Participants', width: 80 },
        { field: 'mentor', headerName: 'Mentors', width: 80 },
        { field: 'stakeholder', headerName: 'Stakeholder', width: 100 },
        // { field: 'stakeholder_', headerName: 'Stakeholder_', width: 100 },
        { field: 'round', headerName: 'Rounds', width: 80 },
        {
            field: 'edit',
            headerName: 'Edit',
            width: 100,
            renderCell: (params) => (
                <Link href={`/editProject?id=${params.id}`}>
                    <EditIcon />
                </Link>
            )
        },
        {
            field: 'visualisation',
            headerName: 'Visualisation',
            width: 100,
            renderCell: (params) => (
                <Link href={`/viewProject?id=${params.id}`}>
                    <VisibilityIcon />
                </Link>
            )
        },
        {
            field: 'chat',
            headerName: 'Chatroom',
            width: 100,
            renderCell: (params) => (
                <Badge
                    badgeContent={unreadConversationCount ? getUnreadMessageCount(unreadConversationCount, params.id) : 0}
                    color="warning"
                >
                    <ForumIcon color="primary" />
                </Badge>
            )
        }
    ];
    const [selectedProject, setSelectedProject] = useState({ pk: 'undefined' });
    // //Get APAI event list
    const handleRowClick = (params) => {
        const project = content.find((p) => p.pk === params.row.id);
        setSelectedProject(project);
        const participantId_list =
            project.fields.participants_userId.length > 0
                ? project.fields.participants_userId
                : Array.from({ length: project.fields.grouping_results.length }, () => [
                      ...project.fields.participants.map((user) => user != null && user.id)
                  ]);
        setParticipantIdsArray(participantId_list);
        // console.log('participantId_list', participantId_list);
        localStorage.setItem('selectedProject', JSON.stringify(project));
        const message = messageData.filter((p) => p.fields.projectId === params.row.id);
        setMessage_selectedProject(message);
    };

    const tableRows = [
        'ID',
        'Email',
        'Name',
        'Gender',
        'Current Position',
        'Department or Class',
        'Company or School',
        'Role',
        'Skills',
        'Date Joined'
    ];

    useEffect(() => {
        UserService.getAllUsers().then(
            (response) => {
                const userData = response.data['user_data'];

                const filledParticipantArray = participantIdsArray?.map((participants) =>
                    participants.map((participantId) => {
                        const user = userData.find((user) => user.id == participantId);
                        return user ? user : null;
                    })
                );

                setParticipantsArray(filledParticipantArray);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log(_content);
                // navigate('/login');
            }
        );
    }, [participantIdsArray]);
    //Get feedback data
    const [feedbackData, setFeedback] = useState([]);
    const [mentorFeedbackData, setMentorFeedback] = useState({});
    useEffect(() => {
        UserService.getAllFeedbacks().then(
            (response) => {
                setFeedback(JSON.parse(response.data.feedback_data));
            },
            (error) => {
                const _events = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log('error2: ', _events);
            }
        );
    }, []);
    useEffect(() => {
        UserService.getAllMentorFeedbacks().then(
            (response) => {
                setMentorFeedback(JSON.parse(response.data.feedback_data));
            },
            (error) => {
                const _events = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log('error4: ', _events);
            }
        );
    }, []);
    const handleDownloadFeedback = (i) => {
        Axios.post(
            `${backend_base_url}/export-feedbackData-to-csv/`,
            {
                project: selectedProject.pk,
                round: i,
                mentor: 0
            },
            {
                headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
                responseType: 'blob' // Specify the response type as blob
            }
        )
            .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    // Create a temporary URL for the blob
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    // Create a hidden <a> element to trigger the download
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'feedbackData_' + selectedProject.pk + '_' + i + '.csv'; // Specify the filename
                    document.body.appendChild(a);
                    a.click();
                    // Clean up the temporary URL and <a> element
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                } else {
                    // Handle non-2xx HTTP response status
                    console.error('Error downloading CSV:', response.statusText);
                }
            })
            .catch((error) => {
                console.error('Error downloading CSV:', error);
            });
    };
    const handleDownloadMentorFeedback = (i) => {
        Axios.post(
            `${backend_base_url}/export-feedbackData-to-csv/`,
            {
                project: selectedProject.pk,
                round: i,
                mentor: 1
            },
            {
                headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
                responseType: 'blob' // Specify the response type as blob
            }
        )
            .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    // Create a temporary URL for the blob
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    // Create a hidden <a> element to trigger the download
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'feedbackData_mentor_' + selectedProject.pk + '_' + i + '.csv'; // Specify the filename
                    document.body.appendChild(a);
                    a.click();
                    // Clean up the temporary URL and <a> element
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                } else {
                    // Handle non-2xx HTTP response status
                    console.error('Error downloading CSV:', response.statusText);
                }
            })
            .catch((error) => {
                console.error('Error downloading CSV:', error);
            });
    };
    const [messageData, setMessage] = useState(null);
    const [message_selectedProject, setMessage_selectedProject] = useState(null);
    useEffect(() => {
        UserService.getAllMessages().then(
            (response) => {
                setMessage(JSON.parse(response.data.message_data));
            },
            (error) => {
                const _events = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log('error5: ', _events);
            }
        );
    }, []);

    const [expandedGroups, setExpandedGroups] = useState([]);
    const handleToggle = (index) => {
        setExpandedGroups(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };
    const { unreadConversationCount } = useNotifications();
    // Function to get unread message count based on project_id and round
    const getUnreadMessageCount_withRound = (unreadConversationCount, projectId, round, group) => {
        // console.log('Keys in unreadConversationCount:', Object.keys(unreadConversationCount)); // Debug log
    
        const totalUnread = Object.keys(unreadConversationCount)
            .filter(key => {
                const keyParts = key.split('-');
                const isMatchingProject = keyParts[1] === String(projectId);
                const isMatchingRound = keyParts[2] === String(round);
                const isMatchingGroup = keyParts[3] === String(group);
                // console.log(`Key: ${key}, Project Match: ${isMatchingProject}, Round Match: ${isMatchingRound}`); // Debug log
                return isMatchingProject && isMatchingRound && isMatchingGroup;
            })
            .reduce((sum, key) => sum + (unreadConversationCount[key] || 0), 0);
    
        // console.log(`Total unread for projectId ${projectId} and round ${round}: ${totalUnread}`); // Debug log
        return totalUnread;
    };
    const getUnreadMessageCount = (unreadConversationCount, projectId) => {
        // console.log('Keys in unreadConversationCount:', Object.keys(unreadConversationCount)); // Debug log
    
        const totalUnread = Object.keys(unreadConversationCount)
            .filter(key => {
                const keyParts = key.split('-');
                const isMatchingProject = keyParts[1] === String(projectId);
                // const isMatchingRound = keyParts[2] === String(round);
                // console.log(`Key: ${key}, Project Match: ${isMatchingProject}, Round Match: ${isMatchingRound}`); // Debug log
                return isMatchingProject;
            })
            .reduce((sum, key) => sum + (unreadConversationCount[key] || 0), 0);
    
        // console.log(`Total unread for projectId ${projectId} and round ${round}: ${totalUnread}`); // Debug log
        return totalUnread;
    };
    const [openDialog, setOpenDialog] = useState(false);
    const [additionalDetails, setAdditionalDetails] = useState('');
    const [roundno, setRoundno] = useState(null);
    const [requestType, setRequestType] = useState(null);
    const handleClickOpenDialog = (round, requestType) => {
        setRoundno(round);
        setRequestType(requestType);
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setRoundno(null);
        setRequestType(null);
    };
    const handleSubmitRequest = async (r) => {
        const requestData = {
            projectId: selectedProject.pk,
            project_title: selectedProject.fields.title,
            round: roundno,
            html_: render(<Email_Request />),
            requester: currentUser.id,
            details: additionalDetails,
            base_url: base_url,
            requestType: requestType
        };

        try {
            Axios.post(`${backend_base_url}/submit-request/`, requestData, {
                headers: {
                    // 'X-CSRFToken': csrfToken,
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`
                },
                // withCredentials: true
            })
                .then((response) => {
                    // console.log('response', response);
                    if (response.data.response =='success') {
                        alert('Request has been sent successfully!')
                    } else {
                        alert(JSON.stringify(response));
                    }
                })
                .catch((error) => {
                    console.error('Error sending request:', error);
                    alert('Error sending request:', error);
                });
            // Show success message or feedback
            setOpenDialog(false);  // Close the dialog after submission
            setAdditionalDetails(""); 
        } catch (error) {
            console.error('Error sending request:', error);
            // Handle error (e.g., show error message)
        }
    };
    if (!currentUser) {
        return <Navigate to="/login" />;
    }
    if (messageData) {
        return (
            <Container>
                <Box sx={{ boxShadow: 1, maxHeight: 640, width: '100%', pt: 2, pl: 2 }}>
                    <h5>Team Building History</h5>
                    {role === '1' &&
                        <DataGrid
                            autoHeight
                            rows={projectsArray}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 }
                                }
                            }}
                            pageSizeOptions={[5, 10]}
                            onRowClick={handleRowClick}
                        />
                    }
                    {role === '2' && 
                        <DataGrid
                            autoHeight
                            rows={projectsArray_2}
                            columns={columns_2}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 }
                                }
                            }}
                            pageSizeOptions={[5, 10]}
                            onRowClick={handleRowClick}
                        />
                    }
                </Box>
                {selectedProject.pk != 'undefined' && (
                    <div>
                        <br></br>
                        <br></br>
                        <h5 style={{ textAlign: 'center' }}>The Project's Details</h5>
                        <p style={{ textAlign: 'center' }}>Title: {selectedProject.fields.title}</p>
                        <p style={{ textAlign: 'center' }}>Description: {selectedProject.fields.description}</p>
                        <br></br>
                        <br></br>
                        {participantsArray.length == selectedProject.fields.grouping_results.length &&
                            message_selectedProject &&
                            selectedProject.fields.grouping_results_userId.length > 0 &&
                            selectedProject.fields.grouping_results_userId.map((tables, i) => {
                                return (
                                    <div>
                                        <h5>
                                            Round {i + 1}
                                            {/* <Link href={`/chatroom/?projectId=${selectedProject.pk}&round=${i+1}`}>
                                                <ForumIcon color="primary"  sx={{ marginLeft: 2 }} />
                                            </Link> */}
                                        </h5>
                                        {participantsArray[i].filter(value => value != null && value.role === 'Stakeholder').length > 0 ?
                                        (<Stack direction="row" mt={2} mb={5}>
                                            Stakeholders: {participantsArray[i].map((value, index) => {
                                                                                        if (value != null && value.role === 'Stakeholder') {
                                                                                            return (<Chip sx={{ ml:2, mr:2 }} size="small" label={value.id + ' - ' + value.name} />)}})}
                                        </Stack>) : null }
                                        {role === '1' && (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                '& > *': {
                                                    m: 1
                                                }
                                            }}
                                        >
                                            <Box
                                                xs={12}
                                                sm={6}
                                                sx={{
                                                    display: 'flex',
                                                    '& > *': {
                                                        m: 1
                                                    }
                                                }}
                                            >
                                                <EmailModal_selectedRound
                                                    selectedRound={i + 1}
                                                    participants_list={participantsArray[i]}
                                                    message={
                                                        message_selectedProject
                                                            ? message_selectedProject.filter(
                                                                (r) => r.fields.round === i + 1 && r.fields.message_type === 1
                                                            )?.length
                                                            : 0
                                                    }
                                                />
                                                <FeedbackModal_selectedRound
                                                    selectedRound={i + 1}
                                                    participants_list={participantsArray[i]}
                                                    message={
                                                        message_selectedProject
                                                            ? message_selectedProject?.filter(
                                                                (r) => r.fields.round === i + 1 && r.fields.message_type === 2
                                                            )?.length
                                                            : 0
                                                    }
                                                />
                                            </Box>
                                            <ButtonGroup
                                                size="small"
                                                variant="text"
                                                //orientation="vertical"
                                                sx={{
                                                    display: 'flex',
                                                    '& > *': {
                                                        m: 1
                                                    }
                                                }}
                                            >
                                                <Button
                                                    onClick={() => handleDownloadFeedback(i + 1)}
                                                    // justifyContent="flex-end" // Align items to the right
                                                >
                                                    <DownloadIcon />
                                                    Download Participants' Feedback
                                                </Button>
                                                <Button
                                                    onClick={() => handleDownloadMentorFeedback(i + 1)}
                                                    // justifyContent="flex-end" // Align items to the right
                                                >
                                                    <DownloadIcon />
                                                    Download Mentors' Feedback
                                                </Button>
                                            </ButtonGroup>
                                        </Box>)}
                                        {role === '2' && (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                '& > *': {
                                                    m: 1
                                                }
                                            }}
                                        >
                                            <ButtonGroup
                                                size="small"
                                                variant="text"
                                                //orientation="vertical"
                                                sx={{
                                                    display: 'flex',
                                                    '& > *': {
                                                        m: 1
                                                    }
                                                }}
                                            >
                                                <Button
                                                    onClick={() => handleClickOpenDialog(i+1, 'Group Details')}
                                                    variant="contained"
                                                    sx={{mr: 2}}
                                                    // justifyContent="flex-end" // Align items to the right
                                                >
                                                    Request to send email (Group Details)
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => handleClickOpenDialog(i+1, 'Feedback Form')}
                                                    sx={{ml: 2}}
                                                    // justifyContent="flex-end" // Align items to the right
                                                >
                                                    Request to send email (Feedback Form)
                                                </Button>
                                            </ButtonGroup>
                                        </Box>)}
                                        <Container sx={{ bgcolor: '' }}>
                                            <Grid container spacing={2}>
                                                {tables.length > 0 &&
                                                    tables.map((table, tableIndex) => (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}
                                                            md={4}
                                                            lg={3}
                                                            //xl={2.4}
                                                            className="table borderd"
                                                            key={`table-${tableIndex}`}
                                                            // style={getListStyle(snapshot.isDraggingOver)}
                                                            /////////////////////////////
                                                        >
                                                            <ListItemText
                                                                primary={`Group ${tableIndex + 1}`}
                                                                primaryTypographyProps={{
                                                                    color: 'black',
                                                                    fontWeight: 600,
                                                                    variant: 'body1'
                                                                }}
                                                            />
                                                            <Link
                                                                href={`/chatroom/?projectId=${selectedProject.pk}&round=${i+1}&group=${tableIndex + 1}`}
                                                                target="_blank" 
                                                                rel="noopener noreferrer"
                                                            >
                                                                <Badge
                                                                    badgeContent={unreadConversationCount ? getUnreadMessageCount_withRound(unreadConversationCount, selectedProject.pk, i+1, tableIndex + 1) : 0}
                                                                    color="warning"
                                                                >
                                                                    <ForumIcon
                                                                        color="primary"
                                                                        sx={{ fontSize: 40 }}
                                                                    />
                                                                </Badge>
                                                            </Link>
                                                            <List
                                                                sx={{
                                                                    width: '100%',
                                                                    minWidth: 220,
                                                                    //minHeight: 50,
                                                                    bgcolor: 'background.paper',
                                                                    //border: '1px solid #ccc',
                                                                    borderRadius: '4px'
                                                                }}
                                                            >
                                                                {table?.map((user, index) => (
                                                                    <ListItem
                                                                        sx={{
                                                                            m: '0px',
                                                                            width: '100%', // Set the ListItem width to fill the available space
                                                                            display: 'flex' // Ensure the ListItem expands to fit the contents
                                                                        }}
                                                                        key={`item-${index}`}
                                                                    >
                                                                        {participantsArray[i].map((value, index) => {
                                                                            if (value != null && value.id == user) {
                                                                                return (
                                                                                    <React.Fragment key={index}>
                                                                                        <ListItemText
                                                                                            primaryTypographyProps={{
                                                                                                style: {
                                                                                                    flex: 1, // Allow the primary text to grow and fill available space
                                                                                                    marginRight: 'auto', // Push the secondary content to the right
                                                                                                    ...(value.role === 'Mentor' && {
                                                                                                        backgroundColor: 'yellow'
                                                                                                    }) // Apply conditional background color
                                                                                                }
                                                                                            }}
                                                                                            primary={value.id + ' - ' + value.name}
                                                                                        />
                                                                                        <ListItemIcon>
                                                                                            {feedbackData?.filter(
                                                                                                (p) =>
                                                                                                    p.fields.project == selectedProject.pk &&
                                                                                                    p.fields.round == i + 1 &&
                                                                                                    p.fields.created_by == value.id
                                                                                            ).length > 0 ||
                                                                                            mentorFeedbackData?.filter(
                                                                                                (p) =>
                                                                                                    p.fields.project == selectedProject.pk &&
                                                                                                    p.fields.round == i + 1 &&
                                                                                                    p.fields.created_by == value.id
                                                                                            ).length > 0 ? (
                                                                                                <CircleIcon style={{ color: 'green' }} />
                                                                                            ) : (
                                                                                                <CircleIcon style={{ color: 'grey' }} />
                                                                                            )}
                                                                                        </ListItemIcon>
                                                                                    </React.Fragment>
                                                                                );
                                                                            }
                                                                        })}
                                                                    </ListItem>
                                                                ))}
                                                            </List>
                                                        </Grid>
                                                    ))}
                                            </Grid>
                                            <br />
                                            <Typography variant="h5" onClick={() => handleToggle(i)}>
                                                <Tooltip title="Click/Unclick to Show/Hide tables in details.">
                                                    <Button>Show/Hide Group Details</Button>
                                                </Tooltip>
                                            </Typography>
                                            <br />
                                            <Collapse in={expandedGroups[i]} timeout="auto" unmountOnExit>
                                                <Grid container spacing={2} sx={{ overflow: 'auto' }}>
                                                    {tables.map((table, index) => (
                                                        <TableContainer
                                                            key={`table-container-${index}`}
                                                            sx={{
                                                                maxHeight: 440,
                                                                border: '2px dashed grey',
                                                                borderColor: 'primary.main'
                                                            }}
                                                        >
                                                            <Typography variant="h5" component="h6">
                                                                Group {index + 1}:
                                                            </Typography>
                                                            <Table key={index} className="table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        {tableRows.map((rows, index) => {
                                                                            return <TableCell key={`header-cell-${index}`}>{rows}</TableCell>;
                                                                        })}
                                                                    </TableRow>
                                                                </TableHead>
                                                                {table.map((user, userIndex) => (
                                                                    <TableBody key={`user-${userIndex}`}>
                                                                        {participantsArray[i]?.map((row, index) => {
                                                                            if (row != null && row.id == user) {
                                                                                return (
                                                                                    <TableRow key={`table-row-${row.id}-${index}`}>
                                                                                        <TableCell>{row.id}</TableCell>
                                                                                        <TableCell>{row.email}</TableCell>
                                                                                        <TableCell>{row.name}</TableCell>
                                                                                        <TableCell>{row.gender}</TableCell>
                                                                                        <TableCell>{row['current position']}</TableCell>
                                                                                        <TableCell>{row['department or class']}</TableCell>
                                                                                        <TableCell>{row['company or school']}</TableCell>
                                                                                        <TableCell>{row.role}</TableCell>
                                                                                        <TableCell>{row.skills.join(', ')}</TableCell>
                                                                                        <TableCell>{row.dateJoined}</TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            }
                                                                        })}
                                                                    </TableBody>
                                                                ))}
                                                            </Table>
                                                        </TableContainer>
                                                    ))}
                                                </Grid>
                                            </Collapse>
                                        </Container>
                                        <br />
                                        <br />
                                    </div>
                                );
                            })}
                        {participantsArray.length == selectedProject.fields.grouping_results.length &&
                            message_selectedProject &&
                            selectedProject.fields.grouping_results_userId.length == 0 &&
                            selectedProject.fields.grouping_results.map((tables, i) => {
                                return (
                                    <div>
                                        <h5>
                                            Round {i + 1}
                                            {/* <Link href={`/chatroom/?projectId=${selectedProject.pk}&round=${i+1}`}>
                                                <ForumIcon color="primary"  sx={{ marginLeft: 2 }} />
                                            </Link> */}
                                        </h5>
                                        {participantsArray[i].filter(value => value != null && value.role === 'Stakeholder').length > 0 ?
                                        (<Stack direction="row" mt={2} mb={5}>
                                            Stakeholders: {participantsArray[i].map((value, index) => {
                                                                                        if (value != null && value.role === 'Stakeholder') {
                                                                                            return (<Chip sx={{ ml:2, mr:2 }} size="small" label={value.id + ' - ' + value.name} />)}})}
                                        </Stack>) : null }
                                        {role === '1' && (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                '& > *': {
                                                    m: 1
                                                }
                                            }}
                                        >
                                            <Box
                                                xs={12}
                                                sm={6}
                                                sx={{
                                                    display: 'flex',
                                                    '& > *': {
                                                        m: 1
                                                    }
                                                }}
                                            >
                                                <EmailModal_selectedRound
                                                    selectedRound={i + 1}
                                                    participants_list={participantsArray[i]}
                                                    message={
                                                        message_selectedProject
                                                            ? message_selectedProject.filter(
                                                                (r) => r.fields.round === i + 1 && r.fields.message_type === 1
                                                            )?.length
                                                            : 0
                                                    }
                                                />
                                                <FeedbackModal_selectedRound
                                                    selectedRound={i + 1}
                                                    participants_list={participantsArray[i]}
                                                    message={
                                                        message_selectedProject
                                                            ? message_selectedProject?.filter(
                                                                (r) => r.fields.round === i + 1 && r.fields.message_type === 2
                                                            )?.length
                                                            : 0
                                                    }
                                                />
                                            </Box>
                                            <ButtonGroup
                                                size="small"
                                                variant="text"
                                                //orientation="vertical"
                                                sx={{
                                                    display: 'flex',
                                                    '& > *': {
                                                        m: 1
                                                    }
                                                }}
                                            >
                                                <Button
                                                    onClick={() => handleDownloadFeedback(i + 1)}
                                                    // justifyContent="flex-end" // Align items to the right
                                                >
                                                    <DownloadIcon />
                                                    Download Participants' Feedback
                                                </Button>
                                                <Button
                                                    onClick={() => handleDownloadMentorFeedback(i + 1)}
                                                    // justifyContent="flex-end" // Align items to the right
                                                >
                                                    <DownloadIcon />
                                                    Download Mentors' Feedback
                                                </Button>
                                            </ButtonGroup>
                                        </Box>)}
                                        {role === '2' && (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                '& > *': {
                                                    m: 1
                                                }
                                            }}
                                        >
                                            <ButtonGroup
                                                size="small"
                                                variant="text"
                                                //orientation="vertical"
                                                sx={{
                                                    display: 'flex',
                                                    '& > *': {
                                                        m: 1
                                                    }
                                                }}
                                            >
                                                <Button
                                                    onClick={() => handleClickOpenDialog(i+1, 'Group Details')}
                                                    variant="contained"
                                                    sx={{mr: 2}}
                                                    // justifyContent="flex-end" // Align items to the right
                                                >
                                                    Request to send email (Group Details)
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => handleClickOpenDialog(i+1, 'Feedback Form')}
                                                    sx={{ml: 2}}
                                                    // justifyContent="flex-end" // Align items to the right
                                                >
                                                    Request to send email (Feedback Form)
                                                </Button>
                                            </ButtonGroup>
                                        </Box>)}
                                        <Container sx={{ bgcolor: '' }}>
                                            <Grid container spacing={2}>
                                                {tables.length > 0 &&
                                                    tables.map((table, tableIndex) => (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}
                                                            md={4}
                                                            lg={3}
                                                            //xl={2.4}
                                                            className="table borderd"
                                                            key={tableIndex}
                                                            // style={getListStyle(snapshot.isDraggingOver)}
                                                            /////////////////////////////
                                                        >
                                                            <ListItemText
                                                                primary={
                                                                    <>
                                                                    {   `Group ${tableIndex + 1} `}
                                                                        <Link href={`/chatroom/?projectId=${selectedProject.pk}&round=${i+1}&group=${tableIndex + 1}`}>
                                                                            <Badge
                                                                                badgeContent={unreadConversationCount ? getUnreadMessageCount_withRound(unreadConversationCount, selectedProject.pk, i+1, tableIndex + 1 ) : 0}
                                                                                color="warning"
                                                                            >
                                                                                <ForumIcon color="primary" />
                                                                            </Badge>
                                                                            {/* <ForumIcon color="primary"  sx={{ marginLeft: 2 }} /> */}
                                                                        </Link>
                                                                    </>
                                                                }
                                                                primaryTypographyProps={{
                                                                    color: 'black',
                                                                    fontWeight: 600,
                                                                    variant: 'body1'
                                                                }}
                                                            />

                                                            <List
                                                                sx={{
                                                                    width: '100%',
                                                                    minWidth: 220,
                                                                    //minHeight: 50,
                                                                    bgcolor: 'background.paper',
                                                                    //border: '1px solid #ccc',
                                                                    borderRadius: '4px'
                                                                }}
                                                            >
                                                                {table?.map((user, index) => (
                                                                    <ListItem
                                                                        sx={{
                                                                            m: '0px',
                                                                            width: '100%', // Set the ListItem width to fill the available space
                                                                            display: 'flex' // Ensure the ListItem expands to fit the contents
                                                                        }}
                                                                    >
                                                                        {participantsArray[i].map((value, index) => {
                                                                            if (index == user && value != null) {
                                                                                return (
                                                                                    <React.Fragment key={index}>
                                                                                        <ListItemText
                                                                                            primaryTypographyProps={{
                                                                                                style: {
                                                                                                    flex: 1, // Allow the primary text to grow and fill available space
                                                                                                    marginRight: 'auto', // Push the secondary content to the right
                                                                                                    ...(value.role === 'Mentor' && {
                                                                                                        backgroundColor: 'yellow'
                                                                                                    }) // Apply conditional background color
                                                                                                }
                                                                                            }}
                                                                                            primary={value.id + ' - ' + value.name}
                                                                                        />
                                                                                        <ListItemIcon>
                                                                                            {feedbackData?.filter(
                                                                                                (p) =>
                                                                                                    p.fields.project == selectedProject.pk &&
                                                                                                    p.fields.round == i + 1 &&
                                                                                                    p.fields.created_by == value.id
                                                                                            ).length > 0 ||
                                                                                            mentorFeedbackData?.filter(
                                                                                                (p) =>
                                                                                                    p.fields.project == selectedProject.pk &&
                                                                                                    p.fields.round == i + 1 &&
                                                                                                    p.fields.created_by == value.id
                                                                                            ).length > 0 ? (
                                                                                                <CircleIcon style={{ color: 'green' }} />
                                                                                            ) : (
                                                                                                <CircleIcon style={{ color: 'grey' }} />
                                                                                            )}
                                                                                        </ListItemIcon>
                                                                                    </React.Fragment>
                                                                                );
                                                                            }
                                                                        })}
                                                                    </ListItem>
                                                                ))}
                                                            </List>
                                                        </Grid>
                                                    ))}
                                            </Grid>
                                            <br />
                                            <Typography variant="h5" onClick={() => handleToggle(i)}>
                                                <Tooltip title="Click/Unclick to Show/Hide tables in details.">
                                                    <Button>Show/Hide Group Details</Button>
                                                </Tooltip>
                                            </Typography>
                                            <br />
                                            <Collapse in={expandedGroups[i]} timeout="auto" unmountOnExit>
                                                <Grid container spacing={2} sx={{ overflow: 'auto' }}>
                                                    {tables.map((table, index) => (
                                                        <TableContainer
                                                            sx={{
                                                                maxHeight: 440,
                                                                border: '2px dashed grey',
                                                                borderColor: 'primary.main'
                                                            }}
                                                        >
                                                            <Typography variant="h5" component="h6">
                                                                Group {index + 1}:
                                                            </Typography>
                                                            <Table key={index} className="table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        {tableRows.map((rows, index) => {
                                                                            return <TableCell key={index}>{rows}</TableCell>;
                                                                        })}
                                                                    </TableRow>
                                                                </TableHead>
                                                                {table.map((user) => (
                                                                    <TableBody>
                                                                        {participantsArray[i]?.map((row, index) => {
                                                                            if (row != null && index == user) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell>{row.id}</TableCell>
                                                                                        <TableCell>{row.email}</TableCell>
                                                                                        <TableCell>{row.name}</TableCell>
                                                                                        <TableCell>{row.gender}</TableCell>
                                                                                        <TableCell>{row['current position']}</TableCell>
                                                                                        <TableCell>{row['department or class']}</TableCell>
                                                                                        <TableCell>{row['company or school']}</TableCell>
                                                                                        <TableCell>{row.role}</TableCell>
                                                                                        <TableCell>{row.skills.join(', ')}</TableCell>
                                                                                        <TableCell>{row.dateJoined}</TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            }
                                                                        })}
                                                                    </TableBody>
                                                                ))}
                                                            </Table>
                                                        </TableContainer>
                                                    ))}
                                                </Grid>
                                            </Collapse>
                                        </Container>
                                        <br />
                                        <br />
                                    </div>
                                );
                            })}
                        <br />

                    </div>
                )}
                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries(formData.entries());
                            const request = formJson.request;
                            // console.log(request);
                            handleSubmitRequest();
                            handleCloseDialog();
                        },
                    }}
                >
                    <DialogTitle>Request to Send Email ({requestType})</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please contact the PUMAS admin if you'd like the {requestType} email to be sent to participants,
                            mentors (if applicable), and other stakeholders (if any).
                            <br />
                            If you have any specific content you'd like included in the email or any special requests, kindly let us know.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="name"
                            name="request"
                            label="Special Request"
                            // type=""
                            fullWidth
                            variant="outlined"
                            multiline
                            minRows={4}
                            value={additionalDetails}
                            onChange={(e) => setAdditionalDetails(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>Cancel</Button>
                        <Button type="submit">Submit a request</Button>
                    </DialogActions>
                </Dialog>
            </Container>
        );
    }
};

export default ProjectList;
