/* eslint-disable */
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import React, { useState, useEffect } from 'react';
import UserService from 'services/user.service';
import {
    Box,
    Button,
    NativeSelect,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    TextField
} from '@mui/material';
import Fab from '@mui/material/Fab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const steps = [
    {
        label: '',
        description: `Thank you for your participation in our event. We sincerely hope that your experience was enjoyable and valuable, just as it was for us to organize and host.`
    },
    {
        label: 'Reviewing Your Strong Skills',
        description: 'In this section, we invite you to review your strong skills listed on your SMART Profile.'
    },
    {
        label: 'Reviewing Your Strong Skills',
        description: 'In this section, we invite you to review your strong skills listed on your SMART Profile.'
    },
    {
        label: 'Improving Your Skills',
        description: 'In this section, we will ask you to evaluate your progress in developing skills you wanted to improve.'
    },
    {
        label: 'Evaluating Your Experience',
        description: 'In this section, we prompt you to reflect on your learning journey.'
    },
    {
        label: 'Putting Your Learning into Action',
        description: 'In this section, reflect on how you will turn this learning into action.'
    },
    {
        label: "Supporting Your Peers' Development",
        description: `In this final section, we prompt you to reflect on your peers' growth and development.`
    }
];
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
export const ViewFeedback = () => {
    const navigate = useNavigate();
    const { user: currentUser } = useSelector((state) => state.auth);
    const location = useLocation();
    const prevLocation = encodeURIComponent(window.location.origin + location.pathname + location.search);
    if (!currentUser) {
        navigate(`/login?redirectTo=${prevLocation}`);
    }
    const query = useQuery();
    // Get query parameters
    const feedbackId = parseInt(query.get('pk'));
    if (isNaN(feedbackId)) {
        navigate('/smart-profile');
    }
    const handleBackPage = () => {
        navigate(-1); // This will navigate back to the previous page
    };
    const [errors, setErrors] = useState(null);
    const rating_list = UserService.rating_list;
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = steps.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    //Get feedback data
    const [feedbackData, setFeedback] = useState(null);
    // useEffect(() => {
    //     UserService.getFeedbacks().then(
    //         (response) => {
    //             setFeedback(JSON.parse(response.data.feedback_data).find((p) => p.pk === feedbackId));
    //         },
    //         (error) => {
    //             const _events = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    //             setUser(_events);
    //         }
    //     );
    // }, []);
    useEffect(() => {
        UserService.getAllFeedbacks().then(
            (response) => {
                setFeedback(JSON.parse(response.data.feedback_data).find((p) => p.pk === feedbackId));
            },
            (error) => {
                const _errors = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                setErrors('FAILED TO GET FEEDBACK DATA: ' + _errors);
            }
        );
    }, [feedbackId]);

    const [content, setContent] = useState([]);
    const [profileContent, setProfileContent] = useState([]);

    useEffect(() => {
        UserService.getAdminBoard().then(
            (response) => {
                setContent(JSON.parse(response.data['user_data']));
                setProfileContent(JSON.parse(response.data['profiles_data']));
            },
            (error) => {
                const _errors =
                    (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                setErrors('FAILED TO GET FEEDBACK DATA: ' + _errors);
            }
        );
    }, []);

    const usersDictionary = content.reduce((acc, value) => {
        if (value.pk != '1') {
            const profile = profileContent.find((p) => p.fields.user === value.pk);
            acc[value.pk] = {
                email: value.fields.username,
                name: value.fields.first_name + ' ' + value.fields.last_name,
                company_or_school: profile ? profile.fields.company_or_school : '-'
            };
        }
        return acc;
    }, {});
    if (errors) {
        return (
                <Grid item xs={8} sm={12} alignitems="center">
                    <br />
                    <Box sx={{ height: 640, width: '100%' }}>
                        <div style={{color: 'red'}}>{errors}</div>
                    </Box>
                </Grid>
            )
    }
    if (feedbackData) {
        return (
            <Box sx={{ maxWidth: '100%', flexGrow: 1 }}>
                <Fab
                    variant="extended"
                    size="small"
                    color="primary"
                    onClick={() => handleBackPage()}
                    sx={{ mb: 2 }}
                >
                    <ArrowBackIcon />
                    Back
                </Fab>
                <h2>Time To Reflect, Review And Move Forward</h2>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{ bgcolor: 'primary.main', display: 'flex', justifyContent: 'center', padding: 0 }}>
                                <h4 style={{ color: 'white' }}>{steps[activeStep].label}</h4>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                sx={{
                                    backgroundColor: 'white',
                                    borderBottom: activeStep != 5 ? '2px solid primary.main' : null,
                                    borderColor: activeStep != 5 ? 'primary.main' : null
                                    //
                                }}
                            >
                                {steps[activeStep].description}
                            </TableCell>
                        </TableRow>
                        {activeStep == 6 ? (
                            <TableRow>
                                <TableCell
                                    sx={{
                                        backgroundColor: 'white',
                                        borderBottom: '2px solid primary.main',
                                        borderColor: 'primary.main'
                                        //
                                    }}
                                >
                                    <b>Please note! Your peers will see your anonymous comments below reflected on their SMART profiles.</b>
                                </TableCell>
                            </TableRow>
                        ) : null}
                    </TableBody>
                </Table>
                <Box sx={{ height: '100%', maxWidth: '100%', width: '100%', p: 2 }}>
                    {activeStep == 0 && (
                        <div>
                            <br></br>
                            <b>Reflection offers a range of benefits:</b>
                            <ol>
                                <li>Gain a profound understanding of your personal growth</li>
                                <li>Improve interview readiness</li>
                                <li>Encourage honest peer feedback</li>
                                <li>Foster personal growth and development in others</li>
                                <li>Identify areas for improvement</li>
                                <li>Cultivate better team communication and collaboration</li>
                            </ol>
                        </div>
                    )}
                    {activeStep == 1 && (
                        <div>
                            <b>Q1. How can you amend your Smart Profile, as a result of this learning experience?</b>
                            <b style={{ display: 'block', textAlign: 'center', textDecoration: 'underline' }}>
                                Strong skills on your Smart Profile
                            </b>
                            <TableContainer style={{ width: '80%', margin: 'auto' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>No</TableCell>
                                            <TableCell>Skill</TableCell>
                                            <TableCell>Rate/5</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {feedbackData?.fields?.strong_skills.map(
                                            (skills, skillIndex) =>
                                                skills?.new !== 1 && (
                                                    <TableRow key={skillIndex}>
                                                        <TableCell>{skillIndex + 1}. </TableCell>
                                                        <TableCell>{skills.skill}</TableCell>
                                                        <TableCell>
                                                            <NativeSelect
                                                                defaultValue={skills.rate}
                                                                disabled
                                                                //
                                                            >
                                                                <option value="">Select</option>
                                                                {rating_list.map((item, index) => (
                                                                    <option key={skillIndex + '-' + index} value={item}>
                                                                        {item}
                                                                    </option>
                                                                ))}
                                                            </NativeSelect>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {/* <b>
                                Q1. What assumptions did you have about your skills before the event? What experience prompted those
                                assumptions?
                            </b>
                            <br></br>
                            <i style={{ color: '#14a085', margin: '1em' }}>
                                i.e. "I felt nervous before this event, because of my limited experience in...."
                            </i>
                            <br></br>
                            <TextField
                                sx={{ width: '100%' }}
                                multiline
                                rows={4}
                                variant="outlined"
                                name="assumptions"
                                id="assumptions"
                                type="text"
                                placeholder="Your answer"
                                inputProps={{ maxLength: 500 }}
                                defaultValue={feedbackData.fields.assumptions}
                                disabled
                            /> */}
                        </div>
                    )}
                    {activeStep == 2 && (
                        <div>
                            <b>
                                Q2. What additional skills, knowledge and perspectives did you take from the event? Use <i>Add Row </i>
                                button below the list to add new skills to your Smart Profile.
                            </b>
                            <b style={{ display: 'block', textAlign: 'center', textDecoration: 'underline' }}>
                                Strong skills on your Smart Profile
                            </b>
                            <TableContainer style={{ width: '80%', margin: 'auto' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>No</TableCell>
                                            <TableCell>Skill</TableCell>
                                            <TableCell>Rate/5</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {feedbackData.fields.strong_skills.map(
                                            (skills, skillIndex) =>
                                                skills?.new !== 1 && (
                                                    <TableRow key={skillIndex}>
                                                        <TableCell>{skillIndex + 1}. </TableCell>
                                                        <TableCell>{skills.skill}</TableCell>
                                                        <TableCell>{skills.rate}</TableCell>
                                                    </TableRow>
                                                )
                                        )}
                                        {feedbackData.fields.strong_skills.map(
                                            (skills, skillIndex) =>
                                                skills?.new === 1 && (
                                                    <TableRow key={skillIndex}>
                                                        <TableCell>{skillIndex + 1}. </TableCell>
                                                        <TableCell>{skills.skill}</TableCell>
                                                        <TableCell>
                                                            <NativeSelect
                                                                defaultValue={skills.rate}
                                                                disabled
                                                                //
                                                            >
                                                                <option value="">Select</option>
                                                                {rating_list.map((item, index) => (
                                                                    <option key={skillIndex + '-' + index} value={item}>
                                                                        {item}
                                                                    </option>
                                                                ))}
                                                            </NativeSelect>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    )}
                    {activeStep == 3 && (
                        <div>
                            <b>Q3. How would you like to amend the skills that you want to learn or improve, as a result of this experience?</b>
                            <br></br>
                            <b style={{ display: 'block', textAlign: 'center', textDecoration: 'underline' }}>
                                Your Skills to Learn or Improve
                            </b>
                            <TableContainer style={{ width: '80%', margin: 'auto' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>No</TableCell>
                                            <TableCell>Skill</TableCell>
                                            <TableCell>Rate/5</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {feedbackData.fields.interest_skills.map((skills, skillIndex) => (
                                            <TableRow key={skillIndex}>
                                                <TableCell>{skillIndex + 1}. </TableCell>
                                                <TableCell>{skills.skill}</TableCell>
                                                <TableCell>
                                                    <NativeSelect
                                                        defaultValue={skills.rate !== undefined ? skills.rate : ''}
                                                        disabled
                                                        //
                                                    >
                                                        <option value="">Select</option>
                                                        {rating_list.map((item, index) => (
                                                            <option key={skillIndex + '-' + index} value={item}>
                                                                {item}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br></br>
                            <i>
                                "Change will not come if we wait for some other person or some other time. We are the ones we have been waiting
                                for. We are the change that we seek."
                            </i>
                            <br></br>
                            <b>- Barack Obama</b>
                        </div>
                    )}
                    {activeStep == 4 && (
                        <div>
                            <b>
                                Q4. What resources and/or support have helped your skill development most? What would help you progress further?
                            </b>
                            <br></br>
                            <i style={{ color: '#14a085', margin: '1em' }}>
                                Example: "I found asking effective questions and learning from getting things wrong the first time to be the
                                most helpful"
                            </i>
                            <TextField
                                sx={{ width: '100%' }}
                                multiline
                                rows={4}
                                variant="outlined"
                                name="supports"
                                id="supports"
                                type="text"
                                placeholder="Your answer"
                                inputProps={{ maxLength: 500 }}
                                defaultValue={feedbackData.fields.supports}
                                disabled
                            />
                        </div>
                    )}
                    {activeStep == 6 && (
                        <div>
                            <br></br>
                            <b>Q6 How would you rate your peer's skills listed on their Smart Profile?</b>
                            {/* <b>Q5. How would you rate your peer's proficiency in each of the skills listed on their Smart Profile?</b> */}
                            {feedbackData.fields.teammates.map((teammate, teammateIndex) => (
                                <div>
                                    <br></br>
                                    <b>
                                        {teammate.id} - {usersDictionary[teammate.id]?.name} -
                                        {usersDictionary[teammate.id]?.company_or_school != ''
                                            ? usersDictionary[teammate.id]?.company_or_school
                                            : ''}
                                    </b>
                                    <TableContainer style={{ width: '80%', margin: 'auto' }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>No</TableCell>
                                                    <TableCell>Skill</TableCell>
                                                    <TableCell>Rate/5</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {teammate.skills.map((skill, skillIndex) => (
                                                    <TableRow key={skillIndex}>
                                                        <TableCell>{skillIndex + 1}. </TableCell>
                                                        <TableCell>
                                                            {skill.skill.trim()}
                                                            {/* <NativeSelect
                                                                defaultValue={skill.skill.trim()}
                                                                disabled
                                                                ///
                                                            >
                                                                <option value="">Select</option>
                                                                {skill_list.map((item, index) => (
                                                                    <option key={skillIndex + '-' + index} value={item}>
                                                                        {item}
                                                                    </option>
                                                                ))}
                                                            </NativeSelect> */}
                                                        </TableCell>
                                                        <TableCell>
                                                            <NativeSelect
                                                                defaultValue={skill.rate !== undefined ? skill.rate : ''}
                                                                disabled
                                                                //
                                                            >
                                                                <option value="">Select</option>
                                                                {rating_list.map((item, index) => (
                                                                    <option key={skillIndex + '-' + index} value={item}>
                                                                        {item}
                                                                    </option>
                                                                ))}
                                                            </NativeSelect>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <br></br>
                                        {/* <i style={{ color: '#14a085', margin: '1em' }}>
                                            Example: "Zoe showed great enthusiasm when leading in discussions. However, it seemed like she often
                                            had difficulty staying on point."
                                        </i> */}
                                        <TextField
                                            sx={{ width: '100%' }}
                                            multiline
                                            rows={3}
                                            variant="outlined"
                                            name={`comments_${teammateIndex}`}
                                            id={`comments_${teammateIndex}`}
                                            type="text"
                                            placeholder="Your answer"
                                            inputProps={{ maxLength: 500 }}
                                            defaultValue={teammate.comments}
                                            disabled
                                        />
                                    </TableContainer>
                                </div>
                            ))}
                        </div>
                    )}
                    {activeStep == 5 && (
                        <div>
                            <b>
                                Q5. How will you turn your learnings into action? How could you practice your strong skills more mindfully to
                                achieve your goals in any area of your life?
                            </b>
                            {/* <b>
                                Q6. How will you turn your learnings into action? How will you help others with your new, improved skillset? How
                                will you build on this and further develop your skills?
                            </b> */}
                            <br></br>
                            <i style={{ color: '#14a085', margin: '1em' }}>
                                Example: "I found leading this task tough as I needed to work in some intense situations where others were turning
                                to me for direction. However, I also loved it! Therefore, I'm going to put my hand up for other leadership
                                opportunities. Now that I know how it feels to be under this type of pressure, I will also work at understanding
                                other people's leadership styles so I can support them more and learn from them too."
                            </i>
                            <TextField
                                sx={{ width: '100%' }}
                                multiline
                                rows={4}
                                variant="outlined"
                                name="actions"
                                id="actions"
                                type="text"
                                placeholder="Your answer"
                                inputProps={{ maxLength: 500 }}
                                defaultValue={feedbackData.fields.actions}
                                disabled
                            />
                            <i>"The most effective way to do it, is to do it"</i>
                            <br></br>
                            <b>— Amelia Earhart</b>
                        </div>
                    )}
                </Box>
                <MobileStepper
                    variant="text"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === maxSteps - 1}
                            variant="contained"
                            color="primary"
                            ///
                        >
                            Next
                            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                        </Button>
                    }
                    backButton={
                        <Button
                            size="small"
                            onClick={handleBack}
                            disabled={activeStep === 0}
                            variant="outlined"
                            //color="primary"
                        >
                            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                            Back
                        </Button>
                    }
                />
            </Box>
        );
    } else {
        return <div>Loading...</div>;
    }
};
